import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseResponsiveSkeleton } from './base-responsive-skeleton';

@Component({
  selector: 'app-course-card-skeleton',
  template: `
    <div class="ph-item">
      <div *ngFor="let num of numArray">
        <div class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-2"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-4"></div>
        </div>
      </div>
    </div>
  `, styles: [`
    .ph-item {
      margin: 0 -16px;
    }
    .ph-item > * {
      padding: 0 16px;
    }
    .ph-picture {
      height: 190px;
      margin-bottom: 0;
    }
    .ph-row {
      padding: 16px 16px 8px 16px;
      border: 1px solid #e6e6e6;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCardSkeletonComponent extends BaseResponsiveSkeleton {

}

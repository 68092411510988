import { Injectable } from '@angular/core';
import { SoftStorageService } from 'soft-ngx';

export enum localeEnum {
  en = 'en',
  th = 'th',
}

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  active: localeEnum;
  isThai: boolean;

  constructor(
    private storage: SoftStorageService,
  ) {
    this.active = this.storage.getItemPersistent('locale') as any || localeEnum.en;
    this.isThai = this.active === localeEnum.th;
  }

  // return isNeedReload flag
  setLocaleByRole(): boolean {
    const lang = localeEnum.en;
    this.storage.setItemPersistent('locale', lang);
    return this.active !== lang;
  }

  switchLang(lang: localeEnum): void {
    this.storage.setItemPersistent('locale', lang);
    window.location.reload();
  }
}

import { Component, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, NgZone, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';
import Pickr from '@simonwep/pickr';

@Component({
  selector: 'lib-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorPickerComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent extends BaseInput implements AfterViewInit, OnDestroy {

  @Input() clearable = true;

  @ViewChild('inputColor') inputColor: ElementRef<any>;

  private pickr: Pickr;

  constructor(
    protected cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    super(cdr);
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.pickr = Pickr.create({
        el: this.inputColor.nativeElement,
        theme: 'monolith',
        useAsButton: true,
        position: 'bottom-end',

        swatches: [
          '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
          '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
          '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107',
        ],

        components: {
          preview: true,
          hue: true,
          interaction: {
            input: true,
            clear: this.clearable,
            save: true,
          },
        },

        strings: {
          save: 'Select',
        },
      } as Pickr.Options);

      this.pickr.on('init', (instance: any) => {
        if (this.value) {
          instance.setColor(this.value);
        }
        this.pickr.on('save', (color: any) => {
          this.pickr.hide();
          this.zone.run(() => {
            if (color) {
              this.onValueChange(color.toHEXA().toString());
            } else {
              this.onValueChange(null);
            }
          });
        });
      });
    });
  }

  setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (this.pickr) {
      if (isDisabled) {
        this.pickr.disable();
      } else {
        this.pickr.enable();
      }
    }
  }

  ngOnDestroy() {
    if (this.pickr) {
      this.pickr.destroy();
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { SoftPopupService } from 'soft-ngx';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BackgroundJobStatus, BackgroundTaskDetailDTO, BackgroundTaskNotificationDTO, NotificationHub } from '../generated-lib/notification-hub';
import { EMPTY, Subject, Subscription, timer } from 'rxjs';
import { dynamicEnvironment } from '@env';
import { DismissibleToastComponent } from '../components/dismissible-toast.component';
import { debounce, takeUntil } from 'rxjs/operators';

@Injectable()
export class SignalrService {

  notificationCount$ = new Subject<number>();
  backgroundTaskCount$ = new Subject<BackgroundTaskNotificationDTO>();
  backgroundTaskComplete$ = new Subject<BackgroundTaskDetailDTO>();

  private backgroundTaskReceived$ = new Subject<BackgroundTaskDetailDTO>();
  private backgroundTaskReceivedSub: Subscription;
  private resetTimer$ = new Subject();

  private connection: HubConnection;

  constructor(
    private popupService: SoftPopupService,
    private zone: NgZone,
  ) {
  }

  start(getAccessToken: () => string | null): void {
    if (this.connection) {
      this.stop().then(() => {
        this.connect(getAccessToken as () => string);
      });
    } else {
      this.connect(getAccessToken as () => string);
    }
  }

  private connect(getAccessToken: () => string): void {
    if (!dynamicEnvironment.config.signalrHubUrl) {
      return;
    }

    let lastJobId: string;
    this.backgroundTaskReceivedSub = this.backgroundTaskReceived$.pipe(
      debounce(val => {
        if (val.jobId !== lastJobId) {
          this.resetTimer$.next();
          lastJobId = val.jobId;
        }
        return timer(400).pipe(
          takeUntil(this.resetTimer$),
        );
      }),
    ).subscribe(data => {
      console.log(data);
      switch (data.status) {
        case BackgroundJobStatus.Waiting:
          this.popupService.toast(data.message, 'background-job-waiting');
          break;

        case BackgroundJobStatus.Running:
          this.popupService.toast(data.message, 'background-job-running');
          break;

        case BackgroundJobStatus.Completed:
          this.backgroundTaskComplete$.next(data);
          this.popupService.toast(data.message, 'background-job-completed');
          break;

        case BackgroundJobStatus.Failed:
          this.popupService.toast(data.message, 'background-job-failed', false, 'bottom-left', DismissibleToastComponent)
            .onAction
            .subscribe(remove => {
              remove();
            });
          break;
      }
    });

    this.zone.runOutsideAngular(() => {
      this.connection = new HubConnectionBuilder()
        .withUrl(`${dynamicEnvironment.config.signalrHubUrl}/notifications`, {
          accessTokenFactory: () => getAccessToken(),
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();

      const hub = new NotificationHub(this.connection);
      hub.registerCallbacks({
        onUnReadCountReceived: (unread => {
          this.zone.run(() => {
            this.notificationCount$.next(unread);
          });
        }),
        onBackgroundTasKReceived: (data => {
          this.zone.run(() => {
            this.backgroundTaskReceived$.next(data);
          });
        }),
        onBackgroundTaskNotificationCount: (data => {
          this.zone.run(() => {
            this.backgroundTaskCount$.next(data);
          });
        }),
      });

      this.connection.start();
    });
  }

  stop(): Promise<void> {
    if (this.backgroundTaskReceivedSub) {
      this.backgroundTaskReceivedSub.unsubscribe();
    }
    if (this.connection) {
      return this.connection.stop();
    }
    return Promise.resolve();
  }

}

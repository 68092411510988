import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, NgZone } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { delay, delayWhen, retryWhen } from 'rxjs/operators';
import { NewVersionNoticeComponent } from '../components/new-version-notice/new-version-notice.component';

declare var window: any;

@Injectable()
export class VersionService {

  private timeToCheckNewVersionAgain = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private router: Router,
    private zone: NgZone,
  ) {
  }

  observe(): void {
    if (window.appVersion === '{BUILD_VERSION}') {
      return;
    }
    this.handleChunkLoadError();
    this.checkNewVersionInterval();
  }

  private handleChunkLoadError() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        if (event.error && event.error.name === 'ChunkLoadError' && event.error.type === 'error') {
          const basePath = document.getElementsByTagName('base')[0].getAttribute('href');
          let currentPath = '';
          if (event.url) {
            currentPath = event.url.substr(1); // remove first slash
          }
          const returnUrl = basePath + currentPath;

          this.checkNewVersion$()
            .subscribe(isNewer => {
              if (isNewer) {
                // chunk error due to new version, reload now
                window.location.href = returnUrl;
              } else {
                // same version, wait to discover missing file
                this.discoverMissingFile$(event.error.request).pipe(
                  retryWhen(err$ => err$.pipe(delay(500))),
                ).subscribe(() => {
                  window.location.href = returnUrl;
                });
              }
            });
        }
      }
    });
  }

  private discoverMissingFile$(url: string) {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();
      const method = 'GET';
      xhr.open(method, url, true);
      xhr.setRequestHeader('cache-control', 'no-cache,no-store,must-revalidate');
      xhr.setRequestHeader('pragma', 'no-cache');
      xhr.setRequestHeader('expires', '0');
      xhr.onload = () => {
        if (xhr.status === 404) {
          observer.error();
        } else {
          observer.next();
          observer.complete();
        }
      };
      xhr.send();
    });
  }

  private checkNewVersionInterval() {
    this.checkNewVersion$().pipe(
      retryWhen(error => {
        // error, request next 10 minutes
        return error.pipe(delay(10 * 60 * 1000));
      }),
      delayWhen(() => timer(this.timeToCheckNewVersionAgain * 60 * 1000)),
    ).subscribe(isNewer => {
      if (isNewer) {
        // show message
        this.zone.run(() => {
          this.createNotice();
        });
      } else {
        // request again
        this.checkNewVersionInterval();
      }
    });
  }

  private createNotice() {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory<any>(NewVersionNoticeComponent)
      .create(this.injector);
    this.applicationRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  private checkNewVersion$(): Observable<boolean> {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();
      const method = 'GET';
      const url = './config/version.json';

      xhr.open(method, url, true);
      xhr.setRequestHeader('cache-control', 'no-cache,no-store,must-revalidate');
      xhr.setRequestHeader('pragma', 'no-cache');
      xhr.setRequestHeader('expires', '0');
      xhr.onload = () => {
        if (xhr.status === 200 || xhr.status === 304) {
          const data = JSON.parse(xhr.responseText);
          // new version found
          if (data.v !== window.appVersion) {
            this.timeToCheckNewVersionAgain = 0;
            observer.next(true);
          } else {
            // same version, give a time to request again
            this.timeToCheckNewVersionAgain = data.t;
            observer.next(false);
          }
        } else {
          observer.error();
        }
        observer.complete();
      };
      xhr.send();
    });
  }
}

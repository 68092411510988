<div
  class="box pad-0 has-shadow-hovered"
  (click)="goTo(course.id)"
>
  <article class="media">
    <span
      class="icon _isRequired-icon"
      *ngIf="course.isRequired"
    >
      <i class="ic ic-tag"></i>
    </span>
    <div class="media-left">
      <figure
        class="image _g-course-cover-image"
        [style.background-image]="course.coverImage && 'url(\'' + course.coverImage.url + '\')'"
      >
      </figure>
    </div>
    <div class="media-content">
      <div class="_content-title is-single-line">
        {{ course.name }}
      </div>

      <div class="_content-time">
        <div *ngIf="course.openTime; else keepSpace">
          <app-datetime-text
            i18n-label="@@datetimeOpen"
            [datetime]="course.openTime"
            label="Open"
          >
          </app-datetime-text>
        </div>
        <div *ngIf="course.closeTime; else keepSpace">
          <app-datetime-text
            i18n-label="@@datetimeClose"
            [datetime]="course.closeTime"
            label="Close"
          >
          </app-datetime-text>
        </div>
      </div>

      <div class="_content-progress">
        <div class="is-flex is-justify-between">
          <div class="_chapter">
            {{ course.numberOfCompletedChapters }} / {{ course.numberOfChapters }}
            <span
              i18n="@@chapterSingle"
              *ngIf="course.numberOfChapters <= 1"
            >Chapter</span>
            <span
              i18n="@@chapterPlural"
              *ngIf="course.numberOfChapters > 1"
            >Chapters</span>
          </div>
          <div
            class="icon-text _reward-point"
            *ngIf="course.rewardPoint"
          >
            <img
              class="icon text-reward-point"
              src="static/image/icon/ic-point.png"
            />
            <span>{{ course.rewardPoint }}</span>
          </div>
        </div>
        <div class="_progress-bar-wrapper">
          <progress
            class="progress is-flex-1 is-progress-course-InProgress _progress-course"
            value="{{ course.numberOfCompletedChapters }}"
            max="{{ course.numberOfChapters }}"
          >
          </progress>
          <div
            i18n="@@currentChapter"
            class="_current-chapter"
            *ngIf="course.numberOfCompletedChapters < course.numberOfChapters && !course.isPassed"
          >
            Chapter {{ course.numberOfCompletedChapters + 1 }}
          </div>
          <div
            i18n="@@completed"
            class="_current-chapter"
            *ngIf="course.isPassed"
          >
            Completed
          </div>
        </div>
      </div>
    </div>
  </article>
</div>

<ng-template #keepSpace>
  <div>
    &nbsp;
  </div>
</ng-template>
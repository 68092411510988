/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MyRewardFilter = 'All' | 'InProcess' | 'Completed' | 'Cancelled';

export const MyRewardFilter = {
  All: 'All' as MyRewardFilter,
  InProcess: 'InProcess' as MyRewardFilter,
  Completed: 'Completed' as MyRewardFilter,
  Cancelled: 'Cancelled' as MyRewardFilter,
};

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type NotificationWebAction = 'OpenCourse' | 'OpenVideo' | 'OpenExam' | 'OpenDiscussion' | 'None';

export const NotificationWebAction = {
  OpenCourse: 'OpenCourse' as NotificationWebAction,
  OpenVideo: 'OpenVideo' as NotificationWebAction,
  OpenExam: 'OpenExam' as NotificationWebAction,
  OpenDiscussion: 'OpenDiscussion' as NotificationWebAction,
  None: 'None' as NotificationWebAction,
};

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LearningProgressActivityStatus = 'Passed' | 'Failed' | 'Missed' | 'Waiting';

export const LearningProgressActivityStatus = {
  Passed: 'Passed' as LearningProgressActivityStatus,
  Failed: 'Failed' as LearningProgressActivityStatus,
  Missed: 'Missed' as LearningProgressActivityStatus,
  Waiting: 'Waiting' as LearningProgressActivityStatus,
};

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { DownloadReportDTO } from '../model/downloadReportDTO';
import { TRN001UserCourseActivityLogDTO } from '../model/tRN001UserCourseActivityLogDTO';
import { TRN002LearningProgressCourse } from '../model/tRN002LearningProgressCourse';


@Injectable({
  providedIn: 'root',
})
export class ReportsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiReportsTRN001UserCourseActivityLogsDownloadExcelGet$(searchKeyword?: string, onlineVideo?: boolean, virtualClassroom?: boolean, examTest?: boolean, qAndA?: boolean, downloadFile?: boolean, headerResponse?: HeaderResponse): Observable<DownloadReportDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Reports/TRN001/UserCourseActivityLogs/DownloadExcel`, { searchKeyword, onlineVideo, virtualClassroom, examTest, qAndA, downloadFile }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiReportsTRN001UserCourseActivityLogsGet$(page?: number, pageSize?: number, searchKeyword?: string, onlineVideo?: boolean, virtualClassroom?: boolean, examTest?: boolean, qAndA?: boolean, downloadFile?: boolean, headerResponse?: HeaderResponse): Observable<Array<TRN001UserCourseActivityLogDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Reports/TRN001/UserCourseActivityLogs`, { page, pageSize, searchKeyword, onlineVideo, virtualClassroom, examTest, qAndA, downloadFile }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiReportsTRN002UserCourseLearningProgressDownloadExcelGet$(headerResponse?: HeaderResponse): Observable<DownloadReportDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Reports/TRN002/UserCourseLearningProgress/DownloadExcel`, undefined, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiReportsTRN002UserCourseLearningProgressGet$(headerResponse?: HeaderResponse): Observable<Array<TRN002LearningProgressCourse>> {
    return this.api.get(`${apiBaseUrl()}/api/Reports/TRN002/UserCourseLearningProgress`, undefined, false, headerResponse);
  }
}

<div
  i18n="@@notifications"
  class="_notification-header"
>
  Notifications
</div>

<div class="_notification-body is-busy-parent">
  <div [softBusy]="loadNotificationsSub"></div>

  <div
    class="_notification-item"
    [class.is-clickable]="notification.action !== Actions.None"
    *ngFor="let notification of notifications"
    (click)="onGoTo(notification)"
  >
    <div class="_notification-message">
      {{ notification.message }}
    </div>
    <div class="_notification-time">
      {{ notification.created | aTimeAgo }}
    </div>
  </div>

  <div *ngIf="showLoadMore">
    <button
      i18n="@@loadMore"
      class="button is-fullwidth"
      [softLoading]="loadNotificationsSub"
      (click)="onLoadMore()"
    >
      Load More
    </button>
  </div>
</div>
<div class="dropdown _notification-dropdown is-active">
  <div class="dropdown-trigger">
    <lib-notification-icon
      [notificationCount]="notificationCount"
      (openNotification)="openNotification()"
    ></lib-notification-icon>
    <div
      class="dropdown-menu _notification-dropdown-menu"
      *ngIf="isOpenNotification"
      (libClickOutside)="closeNotification()"
    >
      <div class="_notification-arrow-up"></div>
      <div class="dropdown-content is-radiusless pad-0">
        <div class="navbar-item pad-0">
          <lib-notification-widget
            class="is-fullwidth"
            [notifications]="notifications"
            [loadNotificationsSub]="loadNotificationsSub"
            [showLoadMore]="showLoadMoreNotifications"
            (goTo)="goTo($event)"
            (loadMore)="loadMoreNotifications()"
          >
          </lib-notification-widget>
        </div>
      </div>
    </div>
  </div>
</div>
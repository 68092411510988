import { DateTime, DurationUnit } from 'luxon';

export class Utils {

  static checkElement(selector: string): Promise<boolean> {
    if (document.querySelector(selector) == null) {
      return this.rafAsync().then(() => this.checkElement(selector));
    } else {
      return Promise.resolve(true);
    }
  }

  static checkElementFunc(querySelectorFunc: () => Element | null | undefined): Promise<boolean> {
    if (querySelectorFunc() === null) {
      return this.rafAsync().then(() => this.checkElementFunc(querySelectorFunc));
    } else {
      return Promise.resolve(true);
    }
  }

  private static rafAsync(): Promise<any> {
    return new Promise(resolve => {
      requestAnimationFrame(resolve); // faster than set time out
    });
  }

  static startOfDay(date = new Date()): Date {
    date.setHours(0, 0, 0);
    return date;
  }

  static endOfDay(date = new Date()): Date {
    date.setHours(23, 59, 59);
    return date;
  }

  static isMobile(): boolean {
    return window.matchMedia('screen and (max-width: 767px)').matches;
  }

  static isTablet(): boolean {
    return window.matchMedia('screen and (min-width: 768px) and (max-width: 1263px)').matches;
  }

  static isDesktop(): boolean {
    return window.matchMedia('screen and (min-width: 1264px)').matches;
  }

  static isDesktopLayout(): boolean {
    return window.matchMedia('screen and (min-width: 768px)').matches;
  }

  static dispatchEvent(eventName: string): void {
    window.dispatchEvent(new Event(eventName));
  }

  static redirectToBasePath(): void {
    const basePath = document.getElementsByTagName('base')[0].getAttribute('href') as string;
    window.location.href = basePath;
  }

  //#region lodash
  static sortBy<T>(arr: T[], key: keyof T, sort: 'asc' | 'desc' = 'asc'): T[] {
    const result = arr.concat().sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
    return sort === 'asc' ? result : result.reverse();
  }

  static range(start: number, end?: number, step?: number): number[] {
    if (step && end) {
      return Array.from({ length: (end - start) / step }, (v, i) => (i * step) + start);
    }
    if (end) {
      return Array.from({ length: end - start }, (v, i) => i + start);
    }
    return Array.from({ length: start }, (v, i) => i);
  }

  static keyBy<T>(array: T[], key: string): { [id: string]: T } {
    return (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
  }

  static sampleSize<T>(array: T[], n: number): T[] {
    const length = array.length;
    if (!length || n < 1) {
      return [];
    }
    n = n > length ? length : n;
    let index = -1;
    const lastIndex = length - 1;
    const result = array.concat();
    while (++index < n) {
      const rand = index + Math.floor(Math.random() * (lastIndex - index + 1));
      const value = result[rand];
      result[rand] = result[index];
      result[index] = value;
    }
    return result.slice(0, n);
  }

  static random(a = 1, b = 0): number {
    const lower = Math.min(a, b);
    const upper = Math.max(a, b);
    return lower + Math.random() * (upper - lower);
  }

  static xor(a: any[], b: any[]): any[] {
    const result: any[] = [];
    a.concat(b).forEach(v => {
      const duplicateIndex = result.findIndex(each => {
        return this.isEqual(v, each);
      });
      if (duplicateIndex > -1) {
        result.splice(duplicateIndex, 1);
      } else {
        result.push(v);
      }
    });
    return result;
  }

  static isEqual(x: any, y: any): boolean {
    if (x === y) {
      return true; // if both x and y are null or undefined and exactly the same
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
      return false; // if they are not strictly equal, they both need to be Objects
    } else if (x.constructor !== y.constructor) {
      // they must have the exact same prototype chain, the closest we can do is
      // test their constructor.
      return false;
    } else {
      for (const p in x) {
        if (!x.hasOwnProperty(p)) {
          continue; // other properties were tested using x.constructor === y.constructor
        }
        if (!y.hasOwnProperty(p)) {
          return false; // allows to compare x[ p ] and y[ p ] when set to undefined
        }
        if (x[p] === y[p]) {
          continue; // if they have the same strict value or identity then they are equal
        }
        if (typeof (x[p]) !== 'object') {
          return false; // Numbers, Strings, Functions, Booleans must be strictly equal
        }
        if (!this.isEqual(x[p], y[p])) {
          return false;
        }
      }
      for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
          return false;
        }
      }
      return true;
    }
  }

  static union(a: any[], b: any[]): any[] {
    const result: any[] = [];
    a.concat(b).forEach(v => {
      const isDuplicate = result.some(each => {
        return this.isEqual(v, each);
      });
      if (!isDuplicate) {
        result.push(v);
      }
    });
    return result;
  }

  static has(obj: any, key: string): boolean {
    const keyParts = key.split('.');

    return !!obj && (
      keyParts.length > 1
        ? this.has(obj[key.split('.')[0]], keyParts.slice(1).join('.'))
        : Object.hasOwnProperty.call(obj, key)
    );
  }

  static merge(target: any, source: any): any {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && key in target) {
        Object.assign(source[key], this.merge(target[key], source[key]));
      }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source);
    return target;
  }
  //#endregion lodash

  //#region luxon
  static diffDate(start: Date, end: Date, unit: DurationUnit, rounding?: 'round' | 'ceil' | 'floor'): number {
    if (rounding) {
      return Math[rounding](DateTime.fromJSDate(start).diff(DateTime.fromJSDate(end), unit).get(unit));
    }
    return Math.floor(DateTime.fromJSDate(start).diff(DateTime.fromJSDate(end), unit).get(unit));
  }
  //#endregion luxon
}

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe as DecimalPipeBase } from '@angular/common';

@Pipe({ name: 'anInteger' })
export class IntegerPipe extends DecimalPipeBase implements PipeTransform {
  constructor() {
    super('en-US');
  }

  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    return super.transform(value, '1.0-0');
  }
}

const pathPrefix = 'static/image/file_';

export const fileIcon = {
  '.doc': `${pathPrefix}doc.png`,
  '.docx': `${pathPrefix}doc.png`,
  '.xls': `${pathPrefix}xls.png`,
  '.xlsx': `${pathPrefix}xls.png`,
  '.ppt': `${pathPrefix}ppt.png`,
  '.pptx': `${pathPrefix}ppt.png`,
  '.pdf': `${pathPrefix}pdf.png`,
  '.png': `${pathPrefix}image.png`,
  '.jpg': `${pathPrefix}image.png`,
  '.jpeg': `${pathPrefix}image.png`,
  '.gif': `${pathPrefix}image.png`,
  '.tif': `${pathPrefix}image.png`,
  '.bmp': `${pathPrefix}image.png`,
  '.txt': `${pathPrefix}txt.png`,
  default: `${pathPrefix}txt.png`,
};

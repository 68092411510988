<div
  class="_sidebar is-flex"
  [class._sidebar-mini-bar]="isMiniBar"
>
  <div class="_menu is-flex-1">
    <div
      class="_menu-item"
      [routerLink]="['/']"
      [routerLinkActive]="'_menu-item-active'"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-home"></i>
        </span>
        <span i18n="@@home">Home</span>
      </div>
    </div>
    <div
      class="_menu-item"
      [routerLink]="['/all-courses']"
      [routerLinkActive]="'_menu-item-active'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-all_courses"></i>
        </span>
        <span i18n="@@allCourses">All Courses</span>
      </div>
    </div>
    <div
      class="_menu-item"
      [routerLink]="['/my-courses']"
      [routerLinkActive]="'_menu-item-active'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-book"></i>
        </span>
        <span i18n="@@myCourses">My Courses</span>
      </div>
    </div>
    <div
      *ngIf="hasRoleOwner"
      class="_menu-item"
      [routerLink]="['/lecturer-courses']"
      [routerLinkActive]="'_menu-item-active'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-grade"></i>
        </span>
        <span i18n="@@LecturerCourses">Lecturer's Courses</span>
      </div>
    </div>
    <!-- <div
      (click)="onCloseSidebar()"
      class="_menu-item"
      [routerLink]="['/profile']"
      [routerLinkActive]="'_menu-item-active'"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-user"></i>
        </span>
        <span>My Profile</span>
      </div>
    </div> -->
    <!-- <div
      (click)="onCloseSidebar()"
      class="_menu-item"
      [routerLink]="['/my-courses']"
      [routerLinkActive]="'_menu-item-active'"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-roadmap"></i>
        </span>
        <span>Road Map</span>
      </div>
    </div> -->

    <!-- 
      TODO: Use this for Angular 12
      [routerLinkActive]="'_menu-item-active'"
      [routerLinkActiveOptions]="{exact:true}"
     -->
    <div
      class="_menu-item"
      *ngIf="feature.reward"
      [routerLink]="['/profile']"
      [fragment]="'rewards'"
      [class._menu-item-active]="activeFragment === 'rewards'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-star-cup-svgrepo-com"></i>
        </span>
        <span i18n="@@rewards">Rewards</span>
      </div>
    </div>
    <!-- <div
      (click)="onCloseSidebar()"
      class="_menu-item"
      [routerLink]="['/my-courses']"
      [routerLinkActive]="'_menu-item-active'"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-ranking"></i>
        </span>
        <span>Ranking</span>
      </div>
    </div> -->
    <!-- <div
      (click)="onCloseSidebar()"
      class="_menu-item"
      [routerLink]="['/profile']"
      [fragment]="'badges'"
      [routerLinkActive]="'_menu-item-active'"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-badges"></i>
        </span>
        <span>Badges</span>
      </div>
    </div> -->
    <div
      class="_menu-item"
      *ngIf="feature.certification"
      [routerLink]="['/profile']"
      [fragment]="'certification'"
      [class._menu-item-active]="activeFragment === 'certification'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-certification"></i>
        </span>
        <span i18n="@@certification">Certification</span>
      </div>
    </div>
    <!-- <div
      class="_menu-item"
      [routerLink]="['/profile']"
      [fragment]="'reports'"
      [class._menu-item-active]="activeFragment === 'reports'"
      (click)="onCloseSidebar()"
    >
      <div class="icon-text _menu-text">
        <span class="icon">
          <i class="ic ic-reports"></i>
        </span>
        <span i18n="@@reports">Reports</span>
      </div>
    </div> -->
  </div>
  <div class="_footer ">
    <div class="is-flex is-justify-between">
      <div class="">
        <a
          i18n="@@logout"
          class="is-uppercase text-size-medium"
          (click)="onCloseSidebar()"
          (click)="logout()"
        >Log out</a>
        <p
          class="text-size-small"
          data-test="displayName"
        >{{ displayName }}</p>
        <span
          *ngIf="ranking"
          i18n="@@levelTag"
          class="_g-user-profile-level"
          [ngStyle]="{ 'background-color': ranking.level.color }"
        > Level {{ ranking.level.level }} </span>
      </div>
      <img
        src="https://bulma.io/images/placeholders/32x32.png"
        class="_image-profile"
      >
    </div>
    <!-- <div class="is-flex is-justify-between"
         *ngIf="!isShowUserProfile">
      <div class="">
        <a class="text-invert is-uppercase text-size-medium"
            (click)="login()">log in</a>
      </div>
    </div> -->
  </div>
</div>
import { EnvironmentConfig } from './environment-config.interface';

declare var window: any;

class DynamicEnvironmentConfig {
  get config(): EnvironmentConfig {
    return window.appConfig;
  }
}

export const dynamicEnvironment = new DynamicEnvironmentConfig();

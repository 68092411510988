import { NgModule } from '@angular/core';
import { MockService } from './mock.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    MockService,
  ],
})
export class MockApiModule { }

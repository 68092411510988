<div
  class="file has-name is-right is-fullwidth"
  [class.is-disabled]="disabled"
>
  <label class="file-label">
    <input
      #fileInput="ngModel"
      class="file-input"
      type="file"
      accept="{{accept}}"
      softFileModel
      [ngModel]="value"
      (ngModelChange)="fileChange($event, fileInput.valid)"
      [maxSize]="maxSize"
      [disabled]="disabled"
    >
    <span class="file-cta">
      <span
        class="file-label"
        [softLoading]="loadingSub"
      >
        Browse
      </span>
    </span>
    <span
      class="file-name"
      [class._placeholder]="!value"
    >
      {{ value ? value.name : (placeholder ? placeholder : '') }}
    </span>
  </label>
</div>
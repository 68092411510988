import { Component, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';
import { SoftTooltipDirective } from 'soft-ngx';
import { DateTime } from 'luxon';
import { DatePickerComponent } from '../date-picker/date-picker.component';

@Component({
  selector: 'lib-date-range-popover',
  styleUrls: ['./date-range-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './date-range-popover.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePopoverComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePopoverComponent extends BaseInput {

  @HostBinding('class') hostClass = 'base-control';

  @ViewChild('tooltip') tooltip: SoftTooltipDirective;
  @ViewChild('datepickerFrom', { static: true }) datepickerFrom: DatePickerComponent;
  @ViewChild('datepickerTo', { static: true }) datepickerTo: DatePickerComponent;

  value: Date[];
  preValue: Date[];
  displayValue: string;

  isDateChange: boolean;
  hideOnClickOutside = true;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  onClear() {
    this.preValue = [];
    this.displayValue = '';
    this.onValueChange([]);
  }

  writeValue(value: Date[] | undefined): void {
    if (!value) {
      value = [];
    }
    this.preValue = [...value];
    super.writeValue(value);
    this.setDisplayValue();
  }

  hide() {
    this.preValue = [...this.value];
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const value: Date[] = [];
    if (this.preValue[0] !== undefined) {
      value[0] = this.preValue[0];
    }
    if (this.preValue[1] !== undefined) {
      value[1] = this.preValue[1];
    }
    this.tooltip.hide();
    this.onValueChange(value);
    this.setDisplayValue();
  }

  dateChange() {
    this.isDateChange = true;
  }

  datepickerOpened() {
    this.hideOnClickOutside = false;
  }

  datepickerClosed() {
    if (!this.isDateChange) {
      this.tooltip.hide();
    }
    this.hideOnClickOutside = true;
    this.isDateChange = false;
  }

  private setDisplayValue() {
    if (!this.value || (!this.value[0] && !this.value[1])) {
      this.displayValue = '';
      return;
    }

    const dateFormat = 'dd/MM/yyyy';

    if (this.value[0]) {
      this.displayValue = DateTime.fromJSDate(this.value[0]).toFormat(dateFormat);
    } else {
      this.displayValue = '...';
    }

    this.displayValue += ' - ';

    if (this.value[1]) {
      this.displayValue += DateTime.fromJSDate(this.value[1]).toFormat(dateFormat);
    } else {
      this.displayValue += '...';
    }
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService, Model } from '@api-main';
import { AuthService } from '@app/core/services/auth.service';
import { dynamicEnvironment } from '@env';
import { SignalrService } from '@lib';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  @Input() isMiniBar = false;
  @Output() closeSidebar = new EventEmitter();

  displayName: string;
  hasRoleOwner: boolean;
  ranking: Model.RankingDTO;
  feature: Model.FeatureDTO;

  // workaround before angular 12
  activeFragment: string;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private signalrService: SignalrService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    // workaround before angular 12
    this.route.fragment.subscribe(fragment => {
      this.activeFragment = fragment;
    });

    this.hasRoleOwner = this.authService.hasRoleOwner;
    this.displayName = this.authService.name;
    this.usersService.apiUsersMyRankingGet$().subscribe(data => {
      this.ranking = data;
    });

    this.feature = this.authService.feature;
  }

  login() {
    this.router.navigate(['/auth/login']);
  }

  logout() {
    this.authService.logout();
    this.signalrService.stop();
    window.location.href = dynamicEnvironment.config.loginScreenUrl;
  }

  onCloseSidebar() {
    this.closeSidebar.emit();
  }
}

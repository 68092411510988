import { Component, Input, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInputComponent } from '../file-input/file-input.component';

@Component({
  selector: 'lib-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadButtonComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadButtonComponent extends FileInputComponent {

  @Input() buttonClass: string;

}

<div
  class="box has-shadow-hovered"
  (click)="goToVideo(course.courseId, course.lectureId, course.videoId)"
  [style.background-image]="'url(\'' + (course.courseCover ? course.courseCover.url : 'static/image/course.png') + '\')'"
>
  <div class="_box-background text-invert">
    <i class="ic ic-tag text-size-medium text-secondary"></i>
    <div class="_header">
      <p class="_title is-single-line">{{ course.courseName }}</p>
      <p class="_lecture is-hidden-touch">{{ course.lectureName }}</p>
    </div>
    <i class="ic ic-vdo _icon-play"></i>
    <div class="_footer">
      <div>
        <span i18n="@@live">LIVE</span>
        <span class="_time-ago">{{ course.startTime | aTimeAgo }}</span>
      </div>
      <shared-datetime-range-text
        i18n-label="@@datetimeTime"
        class="_time is-hidden-touch"
        [startDatetime]="course.startTime"
        [endDatetime]="course.endTime"
        label="Time"
      >
      </shared-datetime-range-text>
    </div>
  </div>
</div>
<div
  class="is-spacing"
  *ngIf="prerequisites"
>
  <div
    class="is-clickable"
    (click)="toggleExpand()"
  >
    <span class="icon-text">
      <span class="icon text-size-medium">
        <i
          class="ic"
          [class.ic-circle-down]="isExpand"
          [class.ic-circle-right]="!isExpand"
        ></i>
      </span>
      <span
        i18n="@@coursePrerequisites"
        *ngIf="isCourse"
      >
        Course Prerequisites ({{ prerequisites.length }})
      </span>
      <span
        i18n="@@lecturePrerequisites"
        *ngIf="!isCourse"
      >
        Prerequisites ({{ prerequisites.length }})
      </span>
    </span>
  </div>

  <ng-container *ngIf="isExpand">
    <div
      class="text-size-normal"
      [class.is-clickable]="!disabledLink"
      (click)="goTo(prerequisite)"
      *ngFor="let prerequisite of prerequisites"
    >
      <span class="icon-text">
        <ng-container>
          <!-- <ng-container *ngIf="role === Roles.Learner; else keepSpace"> -->
          <span
            class="icon _completed"
            *ngIf="prerequisite.isCompleted"
          >
            <i class="ic ic-pass"></i>
          </span>

          <span
            class="icon _in-progress"
            *ngIf="prerequisite.isStarted && !prerequisite.isCompleted"
          >
            <i class="ic ic-status_in-progress"></i>
          </span>

          <span
            class="icon _not-started"
            *ngIf="!prerequisite.isStarted"
          >
            <i class="ic ic-status_not-started"></i>
          </span>
        </ng-container>

        <span class="_name">{{prerequisite.name}}</span>
      </span>
    </div>
  </ng-container>
</div>

<ng-template #keepSpace>
  <span class="icon _blank">
  </span>
</ng-template>
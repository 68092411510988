import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

  @Input() list: { name: string, link?: any[], fragment?: string }[];

}

import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    SidebarComponent,
  ],
})
export class LayoutsModule { }

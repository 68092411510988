import { Injectable } from '@angular/core';

@Injectable()
export class ScrollToService {

  constructor() { }

  scrollTo(scrollingView: HTMLElement, target: HTMLElement, offset = 0, duration = 600): Promise<any> {
    return new Promise(resolve => {
      const start = scrollingView.scrollTop;
      const to = target.offsetTop - scrollingView.offsetTop + offset;
      const scrollDistance = to - start;
      const startDate = +new Date();
      this.animateScroll(scrollingView, startDate, start, to, scrollDistance, duration, resolve);
    });
  }

  animateScroll(scrollingView: any, startDate: number, start: number, to: number, scrollDistance: number, duration: number, resolve: any) {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    scrollingView.scrollTop = this.easeInOutQuad(currentTime, start, scrollDistance, duration);
    if (currentTime < duration) {
      requestAnimationFrame(() => this.animateScroll(scrollingView, startDate, start, to, scrollDistance, duration, resolve));
    } else {
      scrollingView.scrollTop = to;
      resolve();
    }
  }

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  easeInOutQuad(t: number, b: number, c: number, d: number) {
    t /= d / 2;
    if (t < 1) { return c / 2 * t * t + b; }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
}

<app-header
  [isMiniBar]="isMiniBar"
  (toggleSideNavOnTouch)="toggleSidebarOnTouch()"
  (toggleSideNavDesktop)="toggleSidebarDesktop()"
></app-header>
<ng-sidebar-container>
  <ng-sidebar
    class="is-hidden-desktop"
    [(opened)]="isOpenSidebarOnTouch"
    (openedChange)="isOpenSidebarOnTouchChange()"
    [mode]="'push'"
    [showBackdrop]="true"
    [closeOnClickOutside]="true"
  >
    <app-sidebar (closeSidebar)="isOpenSidebarOnTouch = false"></app-sidebar>
  </ng-sidebar>

  <div
    ng-sidebar-content
    [class._sidebar-opened-content]="isOpenSidebarOnTouchAnimate"
  >
    <div id="mainContainer">
      <app-sidebar
        class="is-hidden-touch"
        [isMiniBar]="isMiniBar"
      ></app-sidebar>
      <div id="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-sidebar-container>
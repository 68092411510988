<div
  class="control input-color"
  #inputColor
>
  <input
    class="input"
    [ngModel]="value"
    (blur)="onBlur($event)"
    [disabled]="disabled"
    readonly
  />
  <div
    *ngIf="value"
    class="color-box"
    [style.background-color]="value"
  >
  </div>
</div>
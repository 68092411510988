import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FeaturesService } from '@api-main';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class FeaturePublicResolver implements Resolve<Observable<any>> {

  isPreloaded = false;

  constructor(
    private featuresService: FeaturesService,
    private authService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (this.isPreloaded) {
      return of(true);
    }
    return this.featuresService.apiFeaturesGet$().pipe(
      map(data => {
        this.authService.feature = data;
        this.isPreloaded = true;
      }),
    );
  }
}

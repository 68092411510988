/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type QuestionType = 'MultipleChoice' | 'TrueFalse' | 'ShortAnswer' | 'Matching' | 'Subjective';

export const QuestionType = {
  MultipleChoice: 'MultipleChoice' as QuestionType,
  TrueFalse: 'TrueFalse' as QuestionType,
  ShortAnswer: 'ShortAnswer' as QuestionType,
  Matching: 'Matching' as QuestionType,
  Subjective: 'Subjective' as QuestionType,
};

import { Injectable } from '@angular/core';
import { SoftPopupService } from 'soft-ngx';

export const popupKeyStore = 'popup';

export interface WelcomePopup {
  message: string;
  type: string;
}

@Injectable()
export class WelcomePopupService {

  constructor(
    private popupService: SoftPopupService,
  ) {
  }

  observe(): void {
    const popup = window.localStorage.getItem(popupKeyStore);
    if (popup) {
      const popupObj: WelcomePopup = JSON.parse(popup);
      this.popupService.toast(popupObj.message, popupObj.type);
      window.localStorage.removeItem(popupKeyStore);
    }
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { PdfContentProgressDTO } from '../model/pdfContentProgressDTO';
import { UpdatePdfProgressParam } from '../model/updatePdfProgressParam';


@Injectable({
  providedIn: 'root',
})
export class PdfsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiPdfsByIdUpdateProgressPut$(id: string, body?: UpdatePdfProgressParam, attempt?: number): Observable<PdfContentProgressDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Pdfs/${encodeURIComponent(String(id))}/UpdateProgress`, body, { attempt });
  }
}

import { Component, Input } from '@angular/core';
import { CourseItemComponent } from '../course-item.component';
import { Model } from '@api-main';

@Component({
  selector: 'app-course-box-virtual',
  templateUrl: './course-box-virtual.component.html',
  styleUrls: ['./course-box-virtual.component.scss'],
})
export class CourseBoxVirtualComponent extends CourseItemComponent {

  @Input() course: Model.VideoLiveCardDTO;

}

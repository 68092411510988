import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as DatePipeBase } from '@angular/common';
import { DateTime } from 'luxon';

@Pipe({ name: 'aTimeAgo' })
export class TimeAgoPipe extends DatePipeBase implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    if (value) {
      return DateTime.fromJSDate(value as Date).toRelative();
    }
    return '';
  }
}

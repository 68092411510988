import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule } from '@api-main';
import { LibCoreModule } from '@lib';

import {
  authServiceClassForSoftAuthInterceptorToken,
  userRegisteredSkeletonComponentsToken,
  SoftSkeletonType,
} from 'soft-ngx';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { GuardsModule } from './guards/guards.module';

import { BoxSkeletonComponent } from './skeletons/box-skeleton.component';
import { CourseBoxSkeletonComponent } from './skeletons/course-box-skeleton.component';
import { CourseCardSkeletonComponent } from './skeletons/course-card-skeleton.component';

import { AuthService } from '@app/core/services/auth.service';
import { ScrollToService } from './services/scroll-to.service';
import { WebviewAccessResolver } from './services/webview-access-.resolver';
import { CountDownService } from './services/count-down.service';
import { HeartBeatManagerService } from './services/heart-beat-manager.service';
import { ExportPdfGridService } from './services/export-pdf-grid.service';
import { DownloadService } from './services/download.service';
import { OmiseService } from './services/omise.service';
import { UploadService } from './services/upload.service';
import { LocaleService } from './services/locale.service';

export function initRegisteredSkeletonComponents(): SoftSkeletonType {
  return {
    box: BoxSkeletonComponent,
    'course-box': CourseBoxSkeletonComponent,
    'course-card': CourseCardSkeletonComponent,
  };
}

export const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    // lib
    CommonModule,
    LibCoreModule.forRoot(),

    // others
    NgxMaskModule.forRoot(maskConfig),

    // internal
    ApiModule.forRoot(),
    GuardsModule.forRoot(),
  ],
  declarations: [
    BoxSkeletonComponent,
    CourseBoxSkeletonComponent,
    CourseCardSkeletonComponent,
  ],
  providers: [
    { provide: authServiceClassForSoftAuthInterceptorToken, useClass: AuthService },
    { provide: userRegisteredSkeletonComponentsToken, useFactory: initRegisteredSkeletonComponents },
    AuthService,
    ScrollToService,
    WebviewAccessResolver,
    CountDownService,
    HeartBeatManagerService,
    ExportPdfGridService,
    DownloadService,
    OmiseService,
    UploadService,
    LocaleService,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule) {

    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`,
    );
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VideoType = 'Vimeo' | 'OlivVideo' | 'AzureVideo' | 'WowzaVideo' | 'VimeoLive' | 'WowzaLive' | 'AzureLive';

export const VideoType = {
  Vimeo: 'Vimeo' as VideoType,
  OlivVideo: 'OlivVideo' as VideoType,
  AzureVideo: 'AzureVideo' as VideoType,
  WowzaVideo: 'WowzaVideo' as VideoType,
  VimeoLive: 'VimeoLive' as VideoType,
  WowzaLive: 'WowzaLive' as VideoType,
  AzureLive: 'AzureLive' as VideoType,
};

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { LiveChatDTO } from '../model/liveChatDTO';
import { LiveChatDirection } from '../model/liveChatDirection';
import { UpdateVideoProgressParam } from '../model/updateVideoProgressParam';
import { VideoBookmarkDTO } from '../model/videoBookmarkDTO';
import { VideoContentProgressDTO } from '../model/videoContentProgressDTO';
import { VideoLiveViewDTO } from '../model/videoLiveViewDTO';
import { VideoMultipleChoiceQuestionDTO } from '../model/videoMultipleChoiceQuestionDTO';
import { VideoProgressPeriodDTO } from '../model/videoProgressPeriodDTO';


@Injectable({
  providedIn: 'root',
})
export class VideosService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdBookmarksByBookmarkIdDelete$(id: string, bookmarkId: string): Observable<any> {
    return this.api.delete(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/Bookmarks/${encodeURIComponent(String(bookmarkId))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdBookmarksGet$(id: string): Observable<Array<VideoBookmarkDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/Bookmarks`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdBookmarksPost$(id: string, body?: VideoBookmarkDTO): Observable<VideoBookmarkDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/Bookmarks`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdLiveChatsGet$(id: string, page?: number, pageSize?: number, headerResponse?: HeaderResponse): Observable<Array<LiveChatDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/LiveChats`, { page, pageSize }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdLiveChatsPost$(id: string, body?: LiveChatDTO): Observable<LiveChatDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/LiveChats`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdLiveChatsTestGet$(id: string, videoSeconds?: number, lastId?: string, pageSize?: number, direction?: LiveChatDirection): Observable<Array<LiveChatDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/LiveChatsTest`, { videoSeconds, lastId, pageSize, direction });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdLiveViewsGet$(id: string): Observable<Array<VideoLiveViewDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/LiveViews`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdQuestionsByQuestionIdAnswerMultipleChoicePut$(id: string, questionId: string, body?: VideoMultipleChoiceQuestionDTO): Observable<VideoMultipleChoiceQuestionDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMultipleChoice`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdUpdateProgressPut$(id: string, body?: UpdateVideoProgressParam, attempt?: number): Observable<VideoContentProgressDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/UpdateProgress`, body, { attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosByIdVideoProgressPeriodsGet$(id: string, attempt?: number): Observable<Array<VideoProgressPeriodDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Videos/${encodeURIComponent(String(id))}/VideoProgressPeriods`, { attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiVideosLiveChatsByLivechatIdDelete$(livechatId: string): Observable<any> {
    return this.api.delete(`${apiBaseUrl()}/api/Videos/LiveChats/${encodeURIComponent(String(livechatId))}`, undefined);
  }
}

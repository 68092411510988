import { dynamicEnvironment } from '@env';
import { Settings } from 'luxon';

declare var window: any;

export function LocaleLoader() {
  return new Promise<any>((resolve, reject) => {
    const locale = window.localStorage.getItem(`${dynamicEnvironment.config.storagePrefix}locale`) || 'en';

    if (locale === 'th') {
      Settings.defaultLocale = 'th';
      Settings.defaultOutputCalendar = 'buddhist';
    }

    const xhr = new XMLHttpRequest();
    const method = 'GET';
    let url;
    if (window.appVersion === '{BUILD_VERSION}') {
      url = `./locale/${locale}.json`;
    } else {
      url = `./locale/${locale}.${window.appVersion}.json`;
    }

    xhr.open(method, url, true);
    xhr.setRequestHeader('cache-control', 'no-cache,no-store,must-revalidate');
    xhr.setRequestHeader('pragma', 'no-cache');
    xhr.setRequestHeader('expires', '0');
    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 304) {
        resolve(JSON.parse(xhr.responseText));
      }
    };
    xhr.send();
  });
}

<div
  class="icon-text _attachment"
  *ngFor="let attachment of attachments"
>
  <span class="icon icon-img">
    <img src="{{ fileIcon[attachment.attach.ext] || fileIcon.default }}">
  </span>
  <span>
    <a (click)="onDownload(attachment)">{{ attachment.name }}</a>
  </span>
</div>
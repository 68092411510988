<nav
  class="breadcrumb has-succeeds-separator is-flex is-justify-between is-align-center"
  aria-label="breadcrumbs"
>
  <ul>
    <li
      *ngFor="let item of list; let last = last;"
      [class.is-active]="last"
    >
      <a
        [routerLink]="item.link"
        [fragment]="item.fragment"
      >{{ item.name }}</a>
    </li>
  </ul>
</nav>
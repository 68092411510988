import { OnInit, Directive, Input, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[libDebounce]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DebounceDirective,
    multi: true,
  }],
})
export class DebounceDirective implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() libDebounce: number | undefined = 400;

  private debounceSub = new Subject();
  private destroy$ = new Subject();

  @HostListener('keyup', ['$event.target.value'])
  keyup(value: string) {
    this.debounceSub.next(value);
  }

  ngOnInit(): void {
    this.debounceSub.pipe(
      debounceTime(this.libDebounce!),
      takeUntil(this.destroy$),
    ).subscribe(value => this.onChange(value));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: any) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (_: any) => { };
  private onTouched = (_: any) => { };

}

<div
  class="box pad-0 has-shadow-hovered"
  (click)="goToVideo(course.courseId, course.lectureId, course.videoId)"
>
  <article class="media">
    <span
      class="icon _isRequired-icon"
      *ngIf="course.isRequired"
    >
      <i class="ic ic-tag"></i>
    </span>
    <div class="media-left">
      <figure
        class="image _g-course-cover-image"
        [style.background-image]="course.courseCover && 'url(\'' + course.courseCover.url + '\')'"
      >
      </figure>
    </div>
    <div class="media-content is-flex">
      <div class="content is-flex">
        <div>
          <div class="_content-title is-double-line">
            {{ course.courseName }}
          </div>
          <div class="_content-lecture">{{ course.lectureName }}</div>
        </div>

        <div class="_content-time">
          <div *ngIf="course.startTime; else keepSpace">
            <shared-datetime-range-text
              i18n-label="@@datetimeTime"
              [startDatetime]="course.startTime"
              [endDatetime]="course.endTime"
              label="Time"
            >
            </shared-datetime-range-text>
          </div>
        </div>

      </div>
    </div>
  </article>
</div>

<ng-template #keepSpace>
  <div>
    &nbsp;
  </div>
</ng-template>
import { Component, Input } from '@angular/core';
import { CourseItemComponent } from '../course-item.component';
import { Model } from '@api-main';

@Component({
  selector: 'app-course-box',
  templateUrl: './course-box.component.html',
  styleUrls: ['./course-box.component.scss'],
})
export class CourseBoxComponent extends CourseItemComponent {

  @Input() course: Model.MyCourseCardDTO;

}

import { Component, Input } from '@angular/core';
import { CourseItemComponent } from '../course-item.component';
import { Model } from '@api-main';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent extends CourseItemComponent {

  @Input() course: Model.CourseCardDTO;
  @Input() showCreatedAgo: boolean;

}

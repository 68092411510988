/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { SemesterListDTO } from '../model/semesterListDTO';


@Injectable({
  providedIn: 'root',
})
export class SemestersService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiSemestersDropdownListGet$(): Observable<Array<SemesterListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Semesters/DropdownList`, undefined);
  }
}

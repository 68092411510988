/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { MobileAppVersionDTO } from '../model/mobileAppVersionDTO';


@Injectable({
  providedIn: 'root',
})
export class MobileAppsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   */
  public apiMobileAppsAndroidVersionGet$(): Observable<MobileAppVersionDTO> {
    return this.api.get(`${apiBaseUrl()}/api/MobileApps/AndroidVersion`, undefined, true);
  }
  /**
   */
  public apiMobileAppsIOSVersionGet$(): Observable<MobileAppVersionDTO> {
    return this.api.get(`${apiBaseUrl()}/api/MobileApps/iOSVersion`, undefined, true);
  }
}

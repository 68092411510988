import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { retryWhen, tap, delay, catchError } from 'rxjs/operators';
import { HeartbeatService } from '@api-main';
import { AuthService } from '@app/core/services/auth.service';
import { SoftApiError } from 'soft-ngx';

@Injectable()
export class HeartBeatManagerService {

  session: string;
  courseId: string | undefined;

  private pollingIntervalTime = 10000;

  constructor(
    private authService: AuthService,
    private heartbeatService: HeartbeatService) {

    // generate random session id
    this.session = Math.random().toString(36).substr(2) + new Date().getTime();
  }

  heartbeatInterval() {
    if (this.authService.isLoggedIn && !window.location.href.includes('/auth/')) {
      this.heartbeatService.apiHeartbeatGet$(this.session, this.courseId).pipe(
        catchError((err: SoftApiError) => {
          err.ignoreErrorHandler();
          console.log('*****', err.status);
          return throwError(err);
        }),
        retryWhen(err$ => err$.pipe(delay(this.pollingIntervalTime))),
        // note: heartbeat only once, remove delay
        // delay(this.pollingIntervalTime),
      ).subscribe((data) => {
        // note: heartbeat only once, remove call self
        // this.heartbeatInterval();
      });
    } else {
      setTimeout(() => {
        this.heartbeatInterval();
      }, this.pollingIntervalTime);
    }
  }
}

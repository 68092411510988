import { Component, OnInit, HostListener, Input, Output, EventEmitter, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateTime } from 'luxon';

@Component({
  selector: 'lib-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {

  @HostBinding('class') hostClass = 'base-control';

  @ViewChild('dateInput') dateInput: ElementRef<HTMLInputElement>;

  @Input() set format(format: string) {
    this.bsConfig.rangeInputFormat = format;
  }

  @Input() min: Date;
  @Input() max: Date;
  @Input() autoTime = true; // 00:00:00
  @Input() placeholder = '';
  @Input() monthOnly: boolean;

  dateRange: Date[] | undefined;

  bsConfig: Partial<BsDatepickerConfig> = {};
  disabled: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  onOpenCalendar(container: any) {
    if (this.monthOnly) {
      container.monthSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode('month');
    }
  }

  onValueChange(event: Date[]) {
    if (event && event.length === 2) {
      const dateRange = event as [Date, Date];
      if (this.autoTime) {
        if (dateRange[0]) {
          dateRange[0].setHours(0, 0, 0);
        }
        if (dateRange[1]) {
          dateRange[1].setHours(23, 59, 59);
        }
      }
      this.dateRange = dateRange;
      this.onChange(dateRange);
    }
  }

  onClear() {
    this.dateRange = undefined;
    this.onChange(undefined);
  }

  writeValue(values: Date[]): void {
    this.dateRange = values;
    if (values && (!values[0] !== !values[1])) {
      const dateFormat = this.bsConfig.rangeInputFormat || 'dd/MM/yyyy';
      if (!values[0]) {
        window.setTimeout(() => {
          this.dateInput.nativeElement.value = `... - ${DateTime.fromJSDate(values[1]).toFormat(dateFormat)}`;
        });
      } else {
        const farFuture = new Date();
        farFuture.setFullYear(5000);
        values[1] = farFuture;
        window.setTimeout(() => {
          this.dateInput.nativeElement.value = `${DateTime.fromJSDate(values[0]).toFormat(dateFormat)} - ...`;
        });
      }
    }
    this.cdr.markForCheck();
  }

  onBlur(event: Event) {
    this.onTouched(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange = (_: any) => { };
  private onTouched = (_: any) => { };

}

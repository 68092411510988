import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class WebviewAccessResolver implements Resolve<Observable<boolean>> {

  resolve(route: ActivatedRouteSnapshot) {
    const accessToken = route.queryParams.accessToken;
    if (accessToken) {
      return of(true);
    }
    return of(false);
  }
}

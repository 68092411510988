import { Injectable } from '@angular/core';
import {
  Router, CanActivate, CanActivateChild,
  ActivatedRouteSnapshot, RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Observable, of, Subject } from 'rxjs';

@Injectable()
export class AuthBypassGuard implements CanActivate, CanActivateChild {

  loading$ = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const currentUrlPath = state.url;
    // this.loading$.next(true);

    if (this.authService.isLoggedIn) {
      return true;
    } else {
      const accessToken = route.queryParams.accessToken;
      if (accessToken) {
        let exp = route.queryParams.exp;
        if (exp) {
          exp = +exp;
        }
        const profilePicture = route.queryParams.profilePicture;
        const email = route.queryParams.email;

        // bypass login
        this.authService.bypassLogin(accessToken, exp, email, profilePicture);
        return of(true);
      }

      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: currentUrlPath } });
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canActivate(route, state);
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'lib-dismissible-toast-component',
  template: `
    <div>
      <div>{{ message }}</div>
      <div class="text-right">
        <button
          class="button is-small text-danger"
          (click)="dismiss($event)">
          {{ dismissText }}
        </button>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
    }
  `],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
    ]),
  ],
})
export class DismissibleToastComponent extends Toast {

  dismissText = 'Dismiss';

  dismiss(event: Event): boolean {
    event.stopPropagation();
    this.dismissText = 'Dismissing...';
    this.toastPackage.triggerAction(() => this.remove());
    return false;
  }
}

<div
  class="app-loading-root"
  *ngIf="authGuard.loading$ | async"
>
  <div class="loadingio-spinner-chunk-v5935yijfo">
    <div class="ldio-0m7t1gper3d">
      <div>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RewardRedeemStatus = 'InProgress' | 'Received' | 'Cancelled';

export const RewardRedeemStatus = {
  InProgress: 'InProgress' as RewardRedeemStatus,
  Received: 'Received' as RewardRedeemStatus,
  Cancelled: 'Cancelled' as RewardRedeemStatus,
};

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from '@lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  isOpenSidebarOnTouch = false;
  isOpenSidebarOnTouchAnimate = false;
  isMiniBar = true;

  private destroy$ = new Subject();

  constructor(
    private sidebarService: SidebarService,
  ) {
  }

  ngOnInit() {
    this.sidebarService.isOpenSidebarOnTouch$().pipe(
      takeUntil(this.destroy$),
    ).subscribe(isOpenSidebarOnTouch => this.isOpenSidebarOnTouch = isOpenSidebarOnTouch);

    this.sidebarService.isMiniBar$().pipe(
      takeUntil(this.destroy$),
    ).subscribe(isMiniBar => this.isMiniBar = isMiniBar);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebarOnTouch() {
    this.sidebarService.toggleSidebarOnTouch();
  }

  toggleSidebarDesktop() {
    this.sidebarService.toggleSidebarDesktop();
  }

  isOpenSidebarOnTouchChange() {
    if (this.isOpenSidebarOnTouch) {
      this.isOpenSidebarOnTouchAnimate = true;
    } else {
      window.setTimeout(() => {
        this.isOpenSidebarOnTouchAnimate = false;
      }, 200); // same as .ng-sidebar__content--animate transform 0.2s
    }
  }
}

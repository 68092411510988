declare var window: any;

export function EnvironmentLoader(appVersion?: string) {
  return new Promise<any>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const method = 'GET';
    let url;
    if (appVersion) {
      url = `./config/env.${appVersion}.json`;
    }
    else if (window.appVersion === '{BUILD_VERSION}') {
      url = `./config/env.json`;
    } else {
      url = `./config/env.${window.appVersion}.json`;
    }

    xhr.open(method, url, true);
    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 304) {
        window.appConfig = JSON.parse(xhr.responseText);
      }
      resolve(true);
    };
    xhr.send();
  });
}

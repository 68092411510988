import { Component, OnInit, Input, forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validators, NG_VALIDATORS, AbstractControl, ValidationErrors, NgModel } from '@angular/forms';
import { Utils } from '@utils';

import { DateTime } from 'luxon';

@Component({
  selector: 'lib-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimePickerComponent),
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateTimePickerComponent),
    multi: true,
  }],
})
export class DateTimePickerComponent implements OnInit, Validators, ControlValueAccessor {

  @HostBinding('class') hostClass = 'base-control';

  @Input() ngModel: any;
  @Input() minuteStep = 1;
  @Input() isStartDate = false;
  @Input() isEndDate = false;
  @Input() required: boolean;
  @Input() requiredTime: boolean;
  @Input() showTimeLabel: boolean;

  _minDate: Date | undefined;
  @Input()
  set min(date: Date | undefined) {
    if (date) {
      this._minDate = date;
    } else {
      this._minDate = undefined;
    }
    this.onChange(this.value);
  }

  _maxDate: Date | undefined;
  @Input()
  set max(date: Date | undefined) {
    if (date) {
      this._maxDate = date;
    } else {
      this._maxDate = undefined;
    }
    this.onChange(this.value);
  }

  value: Date | undefined;
  disabled: boolean;
  newTime: boolean;

  date: Date | undefined;
  hour: number | undefined;
  minute: number | undefined;

  hourOptions: number[];
  minuteOptions: number[];

  constructor() { }

  ngOnInit() {
    this.hourOptions = Utils.range(24);
    this.minuteOptions = Utils.range(0, 60, this.minuteStep);
    this.newTime = !this.ngModel;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // valid for empty value
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }

    if (this._minDate && control.value < this._minDate) {
      return { date: true };
    }

    if (this._maxDate && control.value > this._maxDate) {
      return { date: true };
    }

    return null;
  }

  dateChange() {
    if (this.date && DateTime.fromJSDate(this.date).isValid) {
      this.value = DateTime.fromJSDate(this.date)
        .set({ second: 0 })
        .toJSDate();

      if (this.hour) {
        this.value = DateTime.fromJSDate(this.value)
          .set({ hour: this.hour })
          .toJSDate();
      } else if (this.isStartDate) {
        // start of day always 0 hour
        this.value = DateTime.fromJSDate(this.value)
          .set({ hour: 0 })
          .toJSDate();
        this.hour = 0;
      } else if (this.isEndDate) {
        // end of day always 23 hour
        this.value = DateTime.fromJSDate(this.value)
          .set({ hour: 23 })
          .toJSDate();
        this.hour = 23;
      }

      if (this.minute) {
        this.value = DateTime.fromJSDate(this.value)
          .set({ minute: this.minute })
          .toJSDate();
      } else if (this.isStartDate) {
        // start of day always 0 minute
        this.value = DateTime.fromJSDate(this.value)
          .set({ minute: 0 })
          .toJSDate();
        this.minute = this.minuteOptions[0];
      } else if (this.isEndDate) {
        // end of day always 59 minute
        this.value = DateTime.fromJSDate(this.value)
          .set({ minute: 59 })
          .toJSDate();
        this.minute = this.minuteOptions[this.minuteOptions.length - 1];
      }
    } else {
      this.value = undefined;
      this.date = undefined;
      this.hour = undefined;
      this.minute = undefined;
    }

    this.onChange(this.value);
  }

  hourChange() {
    if (this.value) {
      this.value = DateTime.fromJSDate(this.value)
        .set({ hour: this.hour })
        .toJSDate();
    }
    this.onChange(this.value);
  }

  minuteChange() {
    if (this.value) {
      this.value = DateTime.fromJSDate(this.value)
        .set({ minute: this.minute })
        .toJSDate();
      this.onChange(this.value);
    }
  }

  bothChange() {
    if (this.value) {
      this.value = DateTime.fromJSDate(this.value)
        .set({ hour: this.hour, minute: this.minute })
        .toJSDate();
    }
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value && (!this.value || this.value && !DateTime.fromJSDate(value).equals(DateTime.fromJSDate(this.value)))) {
      this.value = value;

      if (this.value !== undefined) {

        if (!this.date && !this.newTime) {
          this.date = DateTime.fromJSDate(this.value).toJSDate();
        }

        if (this.hour && this.minute) {
          window.setTimeout(() => {
            this.bothChange();
          });
        } else {
          if (this.hour) {
            window.setTimeout(() => {
              this.hourChange();
            });
          } else if (!this.newTime) {
            this.hour = DateTime.fromJSDate(this.value).get('hour');
          }

          if (this.minute) {
            window.setTimeout(() => {
              this.minuteChange();
            });
          } else if (!this.newTime) {
            this.minute = DateTime.fromJSDate(this.value).get('minute');
          }
        }

      }

    }
  }

  onBlur(event: Event) {
    this.onTouched(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange = (_: any) => { };
  private onTouched = (_: any) => { };

}

import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild,
  AfterViewInit, ElementRef, forwardRef, OnDestroy, NgZone,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadService } from '@app/core/services/upload.service';
import { dynamicEnvironment } from '@env';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true,
  }],
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

  @ViewChild('editor', { static: true }) editor: ElementRef;
  @Input() isShortanswer: boolean;
  @Input() toolbarButtons: string[] = ['insertLink', 'insertImage'];
  @Input() height = 200;
  @Input() width = 'auto';

  private $element: any;

  constructor(
    private uploadService: UploadService,
    private zone: NgZone) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.$element = $(this.editor.nativeElement);

    this.zone.runOutsideAngular(() => {
      const froala = this.$element.froalaEditor({
        key: dynamicEnvironment.config.froalaKey,
        height: this.height,
        width: this.width,
        toolbarButtons: this.toolbarButtons,
        imageUploadURL: this.uploadService.getImageUploadApiUrl(),
        requestHeaders: this.uploadService.getAuthHeader(),
        imageDefaultAlign: 'left',
        placeholderText: $localize`:@@froalaEditorPlaceholder:Type something`,
      });
    });

    this.$element.on('froalaEditor.contentChanged', (e: Event, editor: any) => {
      this.zone.run(() => {
        this.onChange(editor.html.get());
      });
    });

  }

  ngOnDestroy() {
    if (this.$element) {
      this.$element.off('froalaEditor.contentChanged');
      this.$element.froalaEditor('destroy');
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };

  writeValue(value: string): void {
    $(this.editor.nativeElement).froalaEditor('html.set', value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CoursePrerequisiteDTO {
  id: string;
  name: string;
  desc: string;
  openTime: Date;
  closeTime: Date;
  isStarted: boolean;
  canStart: boolean;
  canContinue: boolean;
  isCompleted: boolean;
  isPassed: boolean;
  passedTime: Date;
  updated: Date;
  publishedTime: Date;
}

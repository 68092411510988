import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe as DecimalPipeBase } from '@angular/common';

@Pipe({ name: 'anAbs' })
export class AbsPipe extends DecimalPipeBase implements PipeTransform {
  constructor() {
    super('en-US');
  }
  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    if (!value) {
      return '';
    }
    let formatted: string | null = '';
    formatted = super.transform(value, '1.0-0');
    if (formatted && value < 0) {
      formatted = `(${formatted.substring(1)})`;
    }
    return formatted;
  }
}

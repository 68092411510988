import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from '@utils';

@Component({
  selector: 'app-attempt-selector',
  templateUrl: './attempt-selector.component.html',
  styleUrls: ['./attempt-selector.component.scss'],
})
export class AttemptSelectorComponent implements OnInit {

  @Input() attemptAt: number;
  @Input() numberOfAttempt: number;
  @Output() attemptAtChange = new EventEmitter();

  attemptRange: any[] = [];

  constructor() { }

  ngOnInit() {
    this.attemptRange = Utils.range(1, this.numberOfAttempt + 1);
    this.attemptAt = this.attemptAt || this.numberOfAttempt;
  }

  onAttemptChange() {
    this.attemptAtChange.emit(this.attemptAt);
  }

}

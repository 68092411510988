import { DateTime } from 'luxon';

export function dateRequestFormatter(date: Date): string {
  return DateTime.fromJSDate(date).toUTC().toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\'', { outputCalendar: 'gregory' });
}

export function dateResponseReviver(key: string, value: string): string | Date {
  let a;
  if (typeof value === 'string' && value.length === 20) {
    a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/.exec(value);
    if (a) {
      return DateTime.fromISO(value).toJSDate();
    }
  }
  return value;
}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'lib-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationIconComponent implements OnInit {

  @Input() notificationCount: number;

  @Output() openNotification = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onOpenNotification(): void {
    this.openNotification.emit();
  }

}

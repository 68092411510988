<ngx-glide
  [type]="'slider'"
  [perView]="perView"
  [gap]="16"
  [showBullets]="false"
  [rewind]="false"
  [arrowLeftTemplate]="arrowLeftTemplate"
  [arrowRightTemplate]="arrowRightTemplate"
  [breakpoints]="breakpoints"
  [listenToEvents]="true"
  (ran)="onRan($event)"
>
  <ng-content #children></ng-content>
</ngx-glide>

<ng-template #arrowLeftTemplate>
  <button
    *ngIf="dotActive"
    class="is-hidden-mobile button is-rounded _slider-previous-button"
  ></button>
</ng-template>

<ng-template #arrowRightTemplate>
  <button
    *ngIf="items && (dotActive !== items.length - 1)"
    class="is-hidden-mobile button is-rounded _slider-next-button"
  ></button>
</ng-template>

<ng-container *ngIf="items?.length">
  <div class="text-center">
    <span
      *ngFor="let item of items; index as i"
      class="_dot"
      [class._dot-active]="dotActive === i"
      (click)="go(i)"
    ></span>
  </div>
</ng-container>
import { Component, Input, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';
import { SoftTooltipDirective } from 'soft-ngx';

@Component({
  selector: 'lib-number-range-popover',
  styleUrls: ['./number-range-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './number-range-popover.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberRangePopoverComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberRangePopoverComponent extends BaseInput {

  @HostBinding('class') hostClass = 'base-control';

  @ViewChild('tooltip') tooltip: SoftTooltipDirective;

  @Input() decimal = 0;
  @Input() negative = true;

  value: number[];
  preValue: number[];
  displayValue: string;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  onClear() {
    this.preValue = [];
    this.displayValue = '';
    this.onValueChange([]);
  }

  writeValue(value: number[] | undefined): void {
    // decimal format
    if (value) {
      if (value[0]) {
        value[0] = +value[0].toFixed(this.decimal);
      }
      if (value[1]) {
        value[1] = +value[1].toFixed(this.decimal);
      }
    } else {
      value = [];
    }
    this.preValue = [...value];
    super.writeValue(value);
    this.setDisplayValue();
  }

  hide() {
    this.preValue = [...this.value];
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const value: number[] = [];
    if (this.preValue[0] !== undefined) {
      value[0] = this.preValue[0];
    }
    if (this.preValue[1] !== undefined) {
      value[1] = this.preValue[1];
    }
    this.tooltip.hide();
    this.onValueChange(value);
    this.setDisplayValue();
  }

  private setDisplayValue() {
    if (!this.value || (!this.value[0] && !this.value[1])) {
      this.displayValue = '';
      return;
    }

    if (this.value[0]) {
      this.displayValue = this.value[0].toLocaleString();
    } else {
      this.displayValue = '...';
    }

    this.displayValue += ' - ';

    if (this.value[1]) {
      this.displayValue += this.value[1].toLocaleString();
    } else {
      this.displayValue += '...';
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as DatePipeBase } from '@angular/common';
import { DateTime } from 'luxon';

@Pipe({ name: 'aDate' })
export class DatePipe extends DatePipeBase implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    if (value) {
      return DateTime.fromJSDate(value as Date).toFormat(format ?? 'MMM d, yyyy');
    }
    return '';
  }
}

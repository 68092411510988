/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LectureType = 'Video' | 'File' | 'Html' | 'Exam' | 'Pdf' | 'Url' | 'Assignment';

export const LectureType = {
  Video: 'Video' as LectureType,
  File: 'File' as LectureType,
  Html: 'Html' as LectureType,
  Exam: 'Exam' as LectureType,
  Pdf: 'Pdf' as LectureType,
  Url: 'Url' as LectureType,
  Assignment: 'Assignment' as LectureType,
};

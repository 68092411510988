/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Card {
  object: string;
  id: string;
  livemode: boolean;
  location: string;
  deleted: boolean;
  street1: string;
  street2: string;
  city: string;
  state: string;
  phoneNumber: string;
  postalCode: number;
  country: string;
  financing: string;
  bank: string;
  brand: string;
  fingerprint: string;
  firstDigits: string;
  lastDigits: number;
  name: string;
  expirationMonth: number;
  expirationYear: number;
  securityCodeCheck: boolean;
  createdAt: Date;
}

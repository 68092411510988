<div
  class="is-clickable badge-corner is-badge-primary"
  [attr.data-badge]="notificationCount > 99 ? '99+' : notificationCount"
>
  <span
    class="icon text-size-large text-primary"
    (click)="onOpenNotification()"
  >
    <i class="ic ic-alert"></i>
  </span>
</div>
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthBypassGuard } from './auth-bypass-guard';
import { AuthGuard } from './auth-guard';
import { RedirectGuard } from './redirect-guard';
import { RegisterGuard } from './register-guard';

@NgModule({
})
export class GuardsModule {
  static forRoot(): ModuleWithProviders<GuardsModule> {
    return {
      ngModule: GuardsModule,
      providers: [
        AuthGuard,
        AuthBypassGuard,
        RedirectGuard,
        RegisterGuard,
      ],
    };
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseResponsiveSkeleton } from './base-responsive-skeleton';

@Component({
  selector: 'app-course-box-skeleton',
  template: `
    <div class="columns is-multiline">
      <div class="column {{ itemClass }}" *ngFor="let num of numArray" >
        <div class="ph-item">
          <div class="ph-picture"></div>
          <div>
            <div class="ph-row">
              <div class="ph-col-6"></div>
              <div class="ph-col-6 empty"></div>
              <div class="ph-col-12 empty"></div>
              <div class="ph-col-12 empty"></div>
              <div class="ph-col-12 empty"></div>
              <div class="ph-col-2"></div>
              <div class="ph-col-8 empty"></div>
              <div class="ph-col-2"></div>
              <div class="ph-col-10"></div>
              <div class="ph-col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, styles: [`
    .ph-item {
      border: 1px solid #e6e6e6;
    }
    .ph-picture {
      margin-bottom: 0;
      width: 210px;
      flex: none;
    }
    .ph-row {
      margin-bottom: 0;
      padding: 12px 12px 0 12px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseBoxSkeletonComponent extends BaseResponsiveSkeleton {

}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseResponsiveSkeleton } from './base-responsive-skeleton';

@Component({
  selector: 'app-box-skeleton',
  template: `
    <div class="columns is-multiline">
      <div class="column {{ itemClass }}" *ngFor="let num of numArray" >
        <div class="ph-item">
            <div>
              <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-12"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  `, styles: [`
    .ph-item > * {
      padding-right: 10px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxSkeletonComponent extends BaseResponsiveSkeleton {

}

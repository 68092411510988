<div class="is-fullwidth push-right-1">
  <lib-date-picker
    [(ngModel)]="date"
    (ngModelChange)="dateChange()"
    [min]="_minDate"
    [max]="_maxDate"
    [disabled]="disabled"
    [required]="required"
    (closed)="onBlur($event)"
  >
  </lib-date-picker>
</div>

<label
  *ngIf="showTimeLabel"
  class="label"
>Time</label>

<div class="_time-control push-right-1">
  <div class="select is-fullwidth">
    <select
      [(ngModel)]="hour"
      [disabled]="disabled || !date"
      [required]="requiredTime"
      (change)="hourChange()"
      (blur)="onBlur($event)"
    >
      <option
        *ngFor="let num of hourOptions"
        [ngValue]="num"
      >{{ (num + '').padStart(2, '0') }}</option>
    </select>
  </div>
</div>

<div class="_time-control">
  <div class="select is-fullwidth">
    <select
      [(ngModel)]="minute"
      [disabled]="disabled || !date"
      [required]="requiredTime"
      (change)="minuteChange()"
      (blur)="onBlur($event)"
    >
      <option
        *ngFor="let num of minuteOptions"
        [ngValue]="num"
      >{{ (num + '').padStart(2, '0') }}</option>
    </select>
  </div>
</div>
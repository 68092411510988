import { Component, forwardRef, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';
import { Subscription } from 'rxjs';
import { SoftPopupService } from 'soft-ngx';

@Component({
  selector: 'lib-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileInputComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent extends BaseInput {

  @Input() accept: string;
  @Input() maxSize: number; // MB

  @Input() set uploadSub(uploadSub: Subscription) {
    this.loadingSub = uploadSub;
  }

  value: File;
  placeholder = 'Choose File';
  loadingSub: Subscription;

  constructor(
    protected cdr: ChangeDetectorRef,
    private popupService: SoftPopupService,
  ) {
    super(cdr);
  }

  fileChange(value: File, isValid: boolean | null) {
    this.onBlur({} as Event);
    if (isValid) {
      this.popupService.clear();
      this.onValueChange(value);
    } else {
      this.popupService.toast(`File must be less than ${this.maxSize} MB`, 'danger');
    }
  }
}

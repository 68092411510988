/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BillingAddressDTO {
  id: string;
  fullname: string;
  headOffice: string;
  addressDetail: string;
  district: string;
  province: string;
  country: string;
  postcode: string;
  phoneNumber: string;
  taxId: string;
}

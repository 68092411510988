import { Component, Input, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';

@Component({
  selector: 'lib-number-input',
  templateUrl: './number-input.component.html',
  styles: [`
    :host {
      display: block;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent extends BaseInput implements OnInit {

  @HostBinding('class') hostClass = 'base-control';

  @Input() decimal = 0;
  @Input() negative = true;

  value: string;

  private regex: RegExp;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    const regexDecimal = this.decimal ? `(\\.[0-9]{0,${this.decimal}})?` : '';
    const regexNegative = this.negative ? `\\-?` : '';
    this.regex = new RegExp(`^${regexNegative}[0-9]*${regexDecimal}$`);
  }

  writeValue(value: string): void {
    // decimal format
    if (value != null) {
      value = (+value).toFixed(this.decimal);
    }
    super.writeValue(value);
  }

  onBlur(event: Event) {
    // decimal format
    if (this.value != null && this.decimal) {
      this.value = (+this.value).toFixed(this.decimal);
    }
    super.onBlur(event);
  }

  onInput(value: string) {
    // empty
    if (value === '') {
      super.onValueChange(null);
      return;
    }

    // single (-) or (.)
    if ((this.negative && value === '-') || (this.decimal && value === '.')) {
      super.onValueChange(null);
      window.setTimeout(() => {
        this.input.nativeElement.value = value;
      });
      return;
    }

    // end with (.)
    if (this.decimal && value) {
      const regexNegative = this.negative ? `\\-?` : '';
      if (new RegExp(`^${regexNegative}[0-9]*\\.$`).test(value)) {
        super.onValueChange(+value);
        window.setTimeout(() => {
          this.input.nativeElement.value = value;
        });
        return;
      }
    }

    // 00 = 0
    if (value === '00') {
      this.input.nativeElement.value = 0;
      return;
    }

    // test regex
    if (value && !this.regex.test(value)) {
      if (this.value === undefined) {
        this.input.nativeElement.value = '';
      } else {
        this.input.nativeElement.value = this.value;
      }
      return;
    }

    super.onValueChange(+value);
  }

}

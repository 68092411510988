/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UserCourseActionLogType = 'View' | 'Learning' | 'Test' | 'Comment' | 'Post';

export const UserCourseActionLogType = {
  View: 'View' as UserCourseActionLogType,
  Learning: 'Learning' as UserCourseActionLogType,
  Test: 'Test' as UserCourseActionLogType,
  Comment: 'Comment' as UserCourseActionLogType,
  Post: 'Post' as UserCourseActionLogType,
};

<span
  i18n="@@attemptNumber"
  *ngIf="numberOfAttempt === 1"
>Attempt #{{ 1 }}</span>
<div
  class="select"
  *ngIf="numberOfAttempt > 1"
>
  <select
    [(ngModel)]="attemptAt"
    name="openTimeHour"
    (change)="onAttemptChange()"
  >
    <option
      i18n="@@attemptNumber"
      *ngFor="let attempt of attemptRange"
      [ngValue]="attempt"
    >Attempt #{{ attempt }}</option>
  </select>
</div>
import { Injectable } from '@angular/core';
import { Utils } from '@utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { SoftStorageService } from 'soft-ngx';

@Injectable()
export class SidebarService {

  private isOpenSidebarOnTouch = false; // mobile, tablet
  private isMiniBar = true; // desktop

  private openSidebarOnTouchSubject: BehaviorSubject<boolean>;
  private miniBarSubject: BehaviorSubject<boolean>;

  constructor(
    private storageService: SoftStorageService,
  ) {
    if (this.storageService.getItem('isMiniBar') === 'false') {
      this.isMiniBar = false;
    }
    this.openSidebarOnTouchSubject = new BehaviorSubject<boolean>(this.isOpenSidebarOnTouch);
    this.miniBarSubject = new BehaviorSubject<boolean>(this.isMiniBar);
    this.toggleBodyClassMiniBar();
  }

  toggleSidebarOnTouch(): void {
    this.isOpenSidebarOnTouch = !this.isOpenSidebarOnTouch;
    this.openSidebarOnTouchSubject.next(this.isOpenSidebarOnTouch);
  }

  toggleSidebarDesktop(): void {
    this.isMiniBar = !this.isMiniBar;
    this.storageService.setItem('isMiniBar', this.isMiniBar);
    this.toggleBodyClassMiniBar();

    this.miniBarSubject.next(this.isMiniBar);

    window.setTimeout(() => {
      Utils.dispatchEvent('resize'); // to trigger carousel
    }, 200); // same as .ng-sidebar__content--animate transform 0.2s
  }

  isOpenSidebarOnTouch$(): Observable<boolean> {
    return this.openSidebarOnTouchSubject.asObservable();
  }

  isMiniBar$(): Observable<boolean> {
    return this.miniBarSubject.asObservable();
  }

  private toggleBodyClassMiniBar() {
    if (this.isMiniBar) {
      document.body.classList.add('mini-bar');
    } else {
      document.body.classList.remove('mini-bar');
    }
  }

}

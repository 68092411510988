import { NgModule, ModuleWithProviders } from '@angular/core';

import { BankService } from './api/bank.service';
import { CourseCategoriesService } from './api/courseCategories.service';
import { CoursesService } from './api/courses.service';
import { ExamsService } from './api/exams.service';
import { FeaturesService } from './api/features.service';
import { FilesService } from './api/files.service';
import { HeartbeatService } from './api/heartbeat.service';
import { MobileAppsService } from './api/mobileApps.service';
import { NotificationsService } from './api/notifications.service';
import { PaymentService } from './api/payment.service';
import { PdfsService } from './api/pdfs.service';
import { ReportsService } from './api/reports.service';
import { RewardsService } from './api/rewards.service';
import { SemestersService } from './api/semesters.service';
import { SignalRService } from './api/signalR.service';
import { TokenService } from './api/token.service';
import { UsersService } from './api/users.service';
import { VideosService } from './api/videos.service';

@NgModule({
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        BankService,
CourseCategoriesService,
CoursesService,
ExamsService,
FeaturesService,
FilesService,
HeartbeatService,
MobileAppsService,
NotificationsService,
PaymentService,
PdfsService,
ReportsService,
RewardsService,
SemestersService,
SignalRService,
TokenService,
UsersService,
VideosService,      ]
    };
  }
}

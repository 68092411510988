import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datetime-text',
  templateUrl: './datetime-text.component.html',
  styleUrls: ['./datetime-text.component.scss'],
})
export class DatetimeTextComponent implements OnInit {

  @Input() datetime: Date;
  @Input() label?: string;
  @Input() showIcon = false;

  constructor() { }

  ngOnInit() {
  }

}

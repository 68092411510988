import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { SoftPopupService } from 'soft-ngx';
import { dynamicEnvironment } from '@env';

export interface OmiseTokenParameters {
  name: string;
  number: string;
  expiration_month: string;
  expiration_year: string;
  security_code: number;
}

export interface OmiseSuccessResponse {
  card: {
    bank: string
    brand: string
    city: null
    country: string
    created_at: string
    deleted: boolean
    expiration_month: number
    expiration_year: number
    financing: string
    fingerprint: string
    first_digits: null
    id: string
    last_digits: string
    livemode: boolean
    location: null
    name: string
    object: string
    phone_number: null
    postal_code: null
    security_code_check: boolean
    state: null
    street1: null
    street2: null,
  };
  charge_status: string;
  created_at: Date;
  id: string;
  livemode: boolean;
  location: string;
  object: string;
  used: boolean;
}
export interface OmiseFailResponse {
  code: string;
  location: string;
  message: string;
  object: string;
}

type OmiseResponse = OmiseSuccessResponse | OmiseFailResponse;

@Injectable()
export class OmiseService {
  private omise = (window as any).Omise;
  constructor(
    private popupService: SoftPopupService,
  ) {
    this.omise.setPublicKey(dynamicEnvironment.config.omiseKey);
  }

  createToken(tokenParameters: OmiseTokenParameters) {
    return new Observable((observer: Observer<OmiseSuccessResponse>) => {
      this.omise.createToken('card',
        tokenParameters,
        (statusCode: number, response: OmiseResponse) => {
          if (statusCode === 200) {
            observer.next(response as OmiseSuccessResponse);
            observer.complete();
          } else {
            observer.error(response as OmiseFailResponse);
          }
        });
    });
  }
}

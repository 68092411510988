/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PurchaseHistoryStatus = 'WaitPayment' | 'PurchaseOrder' | 'CancelledOrder';

export const PurchaseHistoryStatus = {
  WaitPayment: 'WaitPayment' as PurchaseHistoryStatus,
  PurchaseOrder: 'PurchaseOrder' as PurchaseHistoryStatus,
  CancelledOrder: 'CancelledOrder' as PurchaseHistoryStatus,
};

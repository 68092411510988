import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SoftNgxModule } from 'soft-ngx';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SidebarModule } from 'ng-sidebar';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateRangePopoverComponent } from './components/date-range-popover/date-range-popover.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { GridComponent } from './components/grid';
import { GridHeaderWrapperComponent } from './components/grid/grid-header-wrapper/grid-header-wrapper.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { NumberRangePopoverComponent } from './components/number-range-popover/number-range-popover.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { PrerequisiteComponent } from './components/prerequisite/prerequisite.component';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ShortLoadingDirective } from './directives/short-loading.directive';

import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { ExcludePipe } from './pipes/exclude.pipe';
import { ReadablePipe } from './pipes/readable.pipe';

import { AbsPipe } from './pipes/format/abs.pipe';
import { DatePipe } from './pipes/format/date.pipe';
import { DateTimePipe } from './pipes/format/dateTime.pipe';
import { DecimalPipe } from './pipes/format/decimal.pipe';
import { IntegerPipe } from './pipes/format/integer.pipe';
import { TimePipe } from './pipes/format/time.pipe';
import { TimeAgoPipe } from './pipes/format/time-ago.pipe';
import { DurationPipe } from './pipes/format/duration.pipe';

import { ErrorMessageBuilderDirective } from './validators/error-message-builder.directive';
import { ErrorMessageControlComponent } from './validators/error-message-control.component';
import { EmailValidatorDirective } from './validators/email.directive';
import { EqualToValidatorDirective } from './validators/equal-to.directive';
import { MinMaxValidatorDirective } from './validators/min-max-validator.directive';
import { StringValidatorDirective } from './validators/string-validator.directive';
import { UniqueValidatorDirective } from './validators/unique.directive';
import { DecamelizePipe } from './pipes/decamelize.pipe';
import { DebounceDirective } from './directives/debounce.directive';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { FileDownloadButtonComponent } from './components/file-download-button/file-download-button.component';
import { TabComponent } from './components/tab/tab.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationIconComponent } from './components/notifications/notification-icon/notification-icon.component';
import { NotificationWidgetComponent } from './components/notifications/notification-widget/notification-widget.component';

@NgModule({
  imports: [
    // angular
    CommonModule,
    FormsModule,
    RouterModule,

    // soft-ngx
    SoftNgxModule,

    // other
    BsDatepickerModule,
    NgxDatatableModule,
    TypeaheadModule,
    SidebarModule,
  ],
  declarations: [
    // components
    AutocompleteInputComponent,
    BreadcrumbComponent,
    ColorPickerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DateRangePopoverComponent,
    DateTimePickerComponent,
    FileDownloadButtonComponent,
    FileInputComponent,
    FileUploadButtonComponent,
    GridComponent,
    GridHeaderWrapperComponent,
    NumberInputComponent,
    NumberRangePopoverComponent,
    TabComponent,
    PrerequisiteComponent,
    NotificationsComponent,
    NotificationIconComponent,
    NotificationWidgetComponent,

    // directives
    ClickOutsideDirective,
    DebounceDirective,
    ShortLoadingDirective,

    // validators
    // ErrorMessageBuilderDirective,
    // ErrorMessageControlComponent,
    EmailValidatorDirective,
    EqualToValidatorDirective,
    MinMaxValidatorDirective,
    StringValidatorDirective,
    UniqueValidatorDirective,

    // pipes
    DecamelizePipe,
    DurationFormatPipe,
    ExcludePipe,
    ReadablePipe,

    // pipes format
    AbsPipe,
    DatePipe,
    DateTimePipe,
    DecimalPipe,
    IntegerPipe,
    TimePipe,
    TimeAgoPipe,
    DurationPipe,
  ],
  exports: [
    // angular
    CommonModule,
    FormsModule,
    RouterModule,

    // soft-ngx
    SoftNgxModule,

    // other
    SidebarModule,

    // components
    AutocompleteInputComponent,
    BreadcrumbComponent,
    ColorPickerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DateRangePopoverComponent,
    DateTimePickerComponent,
    FileDownloadButtonComponent,
    FileInputComponent,
    FileUploadButtonComponent,
    GridComponent,
    GridHeaderWrapperComponent,
    NumberInputComponent,
    NumberRangePopoverComponent,
    TabComponent,
    PrerequisiteComponent,
    NotificationsComponent,

    // directives
    ClickOutsideDirective,
    DebounceDirective,
    ShortLoadingDirective,

    // validators
    // ErrorMessageBuilderDirective,
    // ErrorMessageControlComponent,
    EmailValidatorDirective,
    EqualToValidatorDirective,
    MinMaxValidatorDirective,
    StringValidatorDirective,
    UniqueValidatorDirective,

    // pipes
    DecamelizePipe,
    DurationFormatPipe,
    ExcludePipe,
    ReadablePipe,

    // pipes format
    AbsPipe,
    DatePipe,
    DateTimePipe,
    DecimalPipe,
    IntegerPipe,
    TimePipe,
    TimeAgoPipe,
    DurationPipe,
  ],
})
export class LibSharedModule { }

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { AddSubjectiveScoreParam } from '../model/addSubjectiveScoreParam';
import { CourseRole } from '../model/courseRole';
import { DownloadReportDTO } from '../model/downloadReportDTO';
import { ExamContentDTO } from '../model/examContentDTO';
import { ExamContentProgressDTO } from '../model/examContentProgressDTO';
import { ExamResultListDTO } from '../model/examResultListDTO';
import { ExamTestQuestionMapDTO } from '../model/examTestQuestionMapDTO';
import { ExamTestResultDTO } from '../model/examTestResultDTO';
import { GradingStatus } from '../model/gradingStatus';
import { MatchingQuestionDTO } from '../model/matchingQuestionDTO';
import { MultipleChoiceQuestionDTO } from '../model/multipleChoiceQuestionDTO';
import { QuestionDTO } from '../model/questionDTO';
import { ShortAnswerQuestionDTO } from '../model/shortAnswerQuestionDTO';
import { SubjectiveQuestionDTO } from '../model/subjectiveQuestionDTO';
import { TrueFalseQuestionDTO } from '../model/trueFalseQuestionDTO';
import { UpdateExamProgressParam } from '../model/updateExamProgressParam';


@Injectable({
  providedIn: 'root',
})
export class ExamsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdGet$(id: string, learnerUserId?: string, role?: CourseRole, courseRegisAttempt?: number): Observable<ExamContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}`, { learnerUserId, role, courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionMapGet$(id: string, courseRegisAttempt?: number): Observable<ExamTestQuestionMapDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/QuestionMap`, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerMatchingPost$(id: string, questionId: string, body?: MatchingQuestionDTO, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMatching`, body, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerMultipleChoicePost$(id: string, questionId: string, body?: MultipleChoiceQuestionDTO, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerMultipleChoice`, body, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerShortAnswerPost$(id: string, questionId: string, body?: ShortAnswerQuestionDTO, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerShortAnswer`, body, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerSubjectivePost$(id: string, questionId: string, body?: SubjectiveQuestionDTO, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerSubjective`, body, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdAnswerTrueFalsePost$(id: string, questionId: string, body?: TrueFalseQuestionDTO, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/AnswerTrueFalse`, body, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdGet$(id: string, questionId: string, courseRegisAttempt?: number): Observable<QuestionDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}`, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdNextGet$(id: string, questionId: string, courseRegisAttempt?: number): Observable<QuestionDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/Next`, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdPreviousGet$(id: string, questionId: string, courseRegisAttempt?: number): Observable<QuestionDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/Previous`, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdQuestionsByQuestionIdUpdateProgressPut$(id: string, questionId: string, body?: UpdateExamProgressParam, resultId?: string, courseRegisAttempt?: number): Observable<ExamContentProgressDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Questions/${encodeURIComponent(String(questionId))}/UpdateProgress`, body, { resultId, courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultByResultIdGet$(id: string, resultId: string): Observable<ExamTestResultDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result/${encodeURIComponent(String(resultId))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultByResultIdPartsByPartIdQuestionByQuestionIdAddSubjectiveScorePost$(id: string, resultId: string, partId: string, questionId: string, body?: AddSubjectiveScoreParam): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result/${encodeURIComponent(String(resultId))}/Parts/${encodeURIComponent(String(partId))}/Question/${encodeURIComponent(String(questionId))}/AddSubjectiveScore`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultByResultIdPartsByPartIdQuestionsGet$(id: string, resultId: string, partId: string, page?: number, pageSize?: number, attempt?: number, headerResponse?: HeaderResponse): Observable<Array<QuestionDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result/${encodeURIComponent(String(resultId))}/Parts/${encodeURIComponent(String(partId))}/Questions`, { page, pageSize, attempt }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultExportGet$(id: string): Observable<DownloadReportDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result/Export`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultGet$(id: string, attempt?: number, courseRegisAttempt?: number): Observable<ExamTestResultDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result`, { attempt, courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdResultListGet$(id: string, ref1?: string, fullName?: string, gradingStatus?: GradingStatus, page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, isOnlyTodo?: boolean, headerResponse?: HeaderResponse): Observable<Array<ExamResultListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Result/List`, { ref1, fullName, gradingStatus, page, pageSize, sortBy, ascending, isOnlyTodo }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdStartPost$(id: string, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Start`, undefined, { courseRegisAttempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiExamsByIdSubmitPost$(id: string, courseRegisAttempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Exams/${encodeURIComponent(String(id))}/Submit`, undefined, { courseRegisAttempt });
  }
}

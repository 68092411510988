import { Pipe, PipeTransform } from '@angular/core';
// import { DatePipe as DatePipeBase } from '@angular/common';
import { DateTime } from 'luxon';

@Pipe({ name: 'aDuration' })
export class DurationPipe implements PipeTransform {
  transform(startDate: Date, endDate: Date): string {
    const duration =  DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).shiftTo('hours', 'minutes', 'seconds');
    if (duration.hours > 0) {
      return duration.toFormat('h\'h\' m\'m\' s\'s\'');
    }
    if (duration.minutes > 0) {
      return duration.toFormat('m\'m\' s\'s\'');
    }
    return duration.toFormat('s\'s\'');
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-new-version-notice',
  templateUrl: './new-version-notice.component.html',
  styleUrls: ['./new-version-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewVersionNoticeComponent {

  reload() {
    window.location.reload();
  }
}

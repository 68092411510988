<header
  id="header"
  role="header"
  class="navbar has-shadow"
>
  <div class="is-fullwidth">

    <div class="navbar-brand">

      <div class="navbar-start is-flex">
        <div
          *ngIf="!isShowSearchBarOnTouch"
          class="navbar-item text-primary is-hidden-desktop"
          [class._is-search-bar-on-touch-active]="isShowSearchBarOnTouch"
          (click)="onToggleSideNavOnTouch()"
        >
          <span
            class="icon"
            data-test="hamburger"
          >
            <i class="ic ic-hamburger"></i>
          </span>
        </div>

        <a
          class="navbar-item _brand-item"
          [class._brand-item-mini-bar]="isMiniBar"
          [class._is-search-bar-on-touch-active]="isShowSearchBarOnTouch"
          [routerLink]="['/']"
        >
          <div
            *ngIf="company"
            class="_brand"
          >
            <div class="_img-logo">
              <div class="_t-header-logo-1"></div>
              <img
                *ngIf="company.companyLogo"
                src="{{ company.companyLogo.url }}"
                srcset="{{ company.companyLogo.url }} 1x, {{ company.companyLogo2x.url }} 2x"
              >
            </div>
            <div class="_img-mini-logo">
              <img
                *ngIf="company.companyMini"
                src="{{ company.companyMini.url }}"
                srcset="{{ company.companyMini.url }} 1x, {{ company.companyMini2x.url }} 2x"
              >
            </div>
          </div>
        </a>

        <a
          class="navbar-item is-hidden-touch"
          (click)="onToggleSideNavDesktop()"
        >
          <div class="icon text-primary">
            <i class="ic ic-hamburger"></i>
          </div>
        </a>

        <div
          *ngIf="activeSemester"
          class="navbar-item"
        >
          <shared-semester-dropdown
            [(ngModel)]="activeSemester"
            (ngModelChange)="semesterChange()"
          ></shared-semester-dropdown>
        </div>


        <div class="navbar-item is-hidden-touch">
          <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
        </div>
      </div>


      <div
        class="navbar-end"
        [class._is-fullwidth-if-search-bar-on-touch-active]="isShowSearchBarOnTouch"
      >
        <a
          i18n="@@allCourses"
          class="navbar-item _main-menu-item is-hidden-touch"
          routerLinkActive="is-active"
          [routerLink]="['/all-courses']"
        >
          All Courses
        </a>

        <a
          i18n="@@myCourses"
          class="navbar-item _main-menu-item is-hidden-touch"
          routerLinkActive="is-active"
          [routerLink]="['/my-courses']"
        >
          My Learning Courses
        </a>

        <div
          class="navbar-item dropdown is-hoverable"
          [class._is-search-bar-on-touch-active]="isShowSearchBarOnTouch"
        >
          <div class="dropdown-trigger">
            <a
              class="is-spacing is-horizontal"
              [ngSwitch]="localeService.active"
            >
              <img
                *ngSwitchCase="LocaleEnum.en"
                src="static/image/icon/flag-en.svg"
              />
              <img
                *ngSwitchCase="LocaleEnum.th"
                src="static/image/icon/flag-th.svg"
              />
              <span>
                {{ LocaleTranslateEnum[localeService.active] }}
              </span>
            </a>
          </div>
          <div class="dropdown-menu">
            <div class="dropdown-content">
              <a
                class="dropdown-item is-spacing is-horizontal"
                (click)="selectLocale(LocaleEnum.th)"
              >
                <img src="static/image/icon/flag-th.svg" />
                <span>{{ LocaleTranslateEnum.th }}</span>
              </a>
              <a
                class="dropdown-item is-spacing is-horizontal"
                (click)="selectLocale(LocaleEnum.en)"
              >
                <img src="static/image/icon/flag-en.svg" />
                <span>{{ LocaleTranslateEnum.en }}</span>
              </a>
            </div>
          </div>
        </div>

        <div
          class="navbar-item"
          [class._is-search-bar-on-touch-active]="isShowSearchBarOnTouch"
        >
          <lib-notifications
            [isAdminWeb]="false"
            [notifications]="notifications"
            [loadNotificationsSub]="loadNotificationsSub"
            [showLoadMoreNotifications]="showLoadMoreNotifications"
            (loadNotifications)="loadNotifications($event)"
            (markAllAsRead)="markAllAsRead()"
          ></lib-notifications>
        </div>

        <div
          class="navbar-item is-clickable is-hidden-desktop"
          *ngIf="!isShowSearchBarOnTouch"
          (click)="isShowSearchBarOnTouch = true"
        >
          <span class="icon text-size-large text-primary">
            <i class="ic ic-search"></i>
          </span>
        </div>

        <div
          class="navbar-item has-dropdown is-hidden-touch"
          [class.is-hoverable]="enableMenuHoverable"
          data-test="profileNav"
        >
          <ng-container [ngTemplateOutlet]="userProfile"></ng-container>
        </div>

        <ng-container *ngIf="isShowSearchBarOnTouch">
          <div class="navbar-item is-flex-1 is-hidden-desktop">
            <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
          </div>
          <div
            class="navbar-item is-clickable is-hidden-desktop"
            (click)="isShowSearchBarOnTouch = false"
          >
            <span class="icon text-primary">
              <i class="ic ic-close-cross"></i>
            </span>

          </div>
        </ng-container>
      </div>
    </div>

  </div>

</header>


<ng-template #searchBar>
  <form
    novalidate
    class="is-fullwidth"
    (ngSubmit)="search()"
  >
    <p class="control has-icons-left _search-bar is-align-center">
      <lib-autocomplete-input
        i18n-placeholder="@@searchCourses"
        name="searchCourses"
        placeholder="Search for Courses"
        [controlClass]="'is-rounded'"
        [data$]="searchAutoCompleteData$"
        [optionField]="'name'"
        [minLength]="3"
        [(ngModel)]="searchQuery"
        (ngModelChange)="search()"
      ></lib-autocomplete-input>
      <span class="icon is-left text-primary _search-icon">
        <i class="ic ic-search"></i>
      </span>
    </p>
  </form>
</ng-template>


<ng-template #userProfile>
  <a
    class="_user-profile"
    title="{{ name }}"
  >
    <figure class="image">
      <img
        class="is-rounded"
        src="{{ profilePictureUrl }}"
      >
    </figure>
    <span
      *ngIf="level"
      i18n="@@levelTag"
      class="_g-user-profile-level push-right-1"
      [ngStyle]="{ 'background-color': level.color }"
    > Level {{ level.level }} </span>
    <span
      class="is-single-line _user-profile-name"
      data-test="displayNameDesktop"
    >{{ name }}</span>
  </a>
  <div class="navbar-dropdown _profile-dropdown">
    <a
      i18n="@@myProfile"
      class="navbar-item"
      (click)="menuHoverableClicked()"
      [routerLink]="['/profile']"
    >
      My Profile
    </a>
    <a
      i18n="@@manage"
      data-test="manage"
      class="navbar-item"
      *ngIf="canAccessAdminPanel"
      (click)="menuHoverableClicked()"
      href="{{ adminSiteUrl }}"
      data-test="menuManage"
    >
      Manage
    </a>
    <hr class="navbar-divider">
    <a
      i18n="@@logout"
      class="navbar-item"
      (click)="logout()"
    >
      Log out
    </a>
  </div>
</ng-template>
<div class="tabs {{ tabClass }}">
  <div class="container is-fullwidth">
    <ul>
      <li
        *ngFor="let tab of tabs"
        class="is-clickable"
        [class.is-active]="activeTabId === tab.id"
        [class.is-disabled]="tab.disabled"
        (click)="changeTab(tab)"
      >
        <a><span class="is-single-line">{{ tab.name }}</span></a>
      </li>
    </ul>
  </div>
</div>
/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { EmailConfirmDTO } from '../model/emailConfirmDTO';
import { ForgotPasswordDTO } from '../model/forgotPasswordDTO';
import { RankingDTO } from '../model/rankingDTO';
import { RegisterDeviceDTO } from '../model/registerDeviceDTO';
import { ResetPasswordDTO } from '../model/resetPasswordDTO';
import { UserCertificateDTO } from '../model/userCertificateDTO';
import { UserChangePasswordIDTO } from '../model/userChangePasswordIDTO';
import { UserProfileDTO } from '../model/userProfileDTO';
import { UserRegisterDTO } from '../model/userRegisterDTO';


@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiUsersChangePasswordPost$(body?: UserChangePasswordIDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/ChangePassword`, body, undefined);
  }
  /**
   */
  public apiUsersConfirmEmailGet$(token?: string, email?: string): Observable<any> {
    return this.api.get(`${apiBaseUrl()}/api/Users/ConfirmEmail`, { token, email }, true);
  }
  /**
   */
  public apiUsersForgotPasswordPost$(body?: ForgotPasswordDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/ForgotPassword`, body, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersMyCertificatesGet$(): Observable<Array<UserCertificateDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Users/MyCertificates`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersMyProfileGet$(): Observable<UserProfileDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Users/MyProfile`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersMyRankingGet$(): Observable<RankingDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Users/MyRanking`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersRegisterDevicePost$(body?: RegisterDeviceDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/RegisterDevice`, body, undefined);
  }
  /**
   */
  public apiUsersRegisterPost$(body?: UserRegisterDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/Register`, body, undefined, true);
  }
  /**
   */
  public apiUsersResetPasswordPost$(body?: ResetPasswordDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/ResetPassword`, body, undefined, true);
  }
  /**
   */
  public apiUsersSendConfirmEmailPost$(body?: EmailConfirmDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/SendConfirmEmail`, body, undefined, true);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersUnRegisterDevicePost$(body?: RegisterDeviceDTO): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Users/UnRegisterDevice`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiUsersUploadProfilePicturePost$(profilePicture?: any): Observable<UserProfileDTO> {
    return this.api.multipartPost(`${apiBaseUrl()}/api/Users/UploadProfilePicture`, { profilePicture }, undefined);
  }
}

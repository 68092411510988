import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Model, fileIcon } from '@api-main';
import { DownloadService } from '@app/core/services/download.service';

type AttachmentDTO = Model.VideoFileAttachmentDTO | Model.HtmlFileAttachmentDTO | Model.FileAttachmentDTO;

@Component({
  selector: 'v-content-attachment-list',
  templateUrl: './content-attachment-list.component.html',
  styleUrls: ['./content-attachment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentAttachmentListComponent implements OnInit {

  fileIcon = fileIcon;

  @Input() attachments: AttachmentDTO[];

  @Output() download = new EventEmitter<AttachmentDTO>();

  constructor(
    private downloadService: DownloadService,
  ) {
  }

  ngOnInit() {
  }

  onDownload(attachment: AttachmentDTO) {
    this.downloadService.downloadFileResult(attachment.attach);
    this.download.emit(attachment);
  }

}

/**
 * encourse Main App Service
 *
 * API version: v1.0
 */

// tslint:disable:no-unused-variable member-ordering
import { Injectable } from '@angular/core';
import { SoftApiClientService } from 'soft-ngx';
import { Observable } from 'rxjs';
import { Model } from '@api-main';
import { AuthService } from '@app/core/services/auth.service';
import { dynamicEnvironment } from '@env';

@Injectable()
export class UploadService {

  constructor(
    protected api: SoftApiClientService,
    private authService: AuthService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public uploadProfilePicture$(profilePicture: File): Observable<Model.UserProfileDTO> {
    return this.api.multipartPost(`${dynamicEnvironment.config.apiBaseUrl}/api/Users/UploadProfilePicture`, { profilePicture });
  }

  /**
   * Use for Froala Editor
   */
  public getImageUploadApiUrl() {
    return `${dynamicEnvironment.config.apiBaseUrl}/api/Files/UploadImage`;
  }

  public getAuthHeader() {
    let scheme = this.authService.getAuthenticationScheme() ?? '';
    if (scheme) {
      scheme += ' ';
    }
    return { Authorization: scheme + this.authService.getAccessToken() };
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdUploadAttachmentPost$(id: string, assignmentContentId: string, file: File): Observable<Model.AssignmentFileAttachmentDTO> {
    return this.api.multipartPost(`${dynamicEnvironment.config.apiBaseUrl}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/UploadAttachment`, { file });
  }
}

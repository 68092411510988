/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { MyRewardFilter } from '../model/myRewardFilter';
import { RewardDTO } from '../model/rewardDTO';
import { RewardFilter } from '../model/rewardFilter';
import { UserRewardDTO } from '../model/userRewardDTO';


@Injectable({
  providedIn: 'root',
})
export class RewardsService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiRewardsByIdRedeemPost$(id: string): Observable<RewardDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Rewards/${encodeURIComponent(String(id))}/Redeem`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiRewardsGet$(page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, filter?: RewardFilter, headerResponse?: HeaderResponse): Observable<Array<RewardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Rewards`, { page, pageSize, sortBy, ascending, filter }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiRewardsMyRewardPointsGet$(): Observable<UserRewardDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Rewards/MyRewardPoints`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiRewardsMyRewardsGet$(page?: number, pageSize?: number, filter?: MyRewardFilter, headerResponse?: HeaderResponse): Observable<Array<RewardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Rewards/MyRewards`, { page, pageSize, filter }, false, headerResponse);
  }
}

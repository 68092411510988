<nav
  class="pagination"
  *ngIf="pages.length > 1"
  role="navigation"
  aria-label="pagination"
>
  <ul class="pagination-list">
    <li
      *ngFor="let pageItem of pages"
      (click)="setPage(pageItem)"
    >
      <a
        *ngIf="pageItem.label !== '...'"
        class="pagination-link"
        [class.is-current]="page === pageItem.value"
      >{{ pageItem.label }}</a>
      <span
        *ngIf="pageItem.label === '...'"
        class="pagination-ellipsis"
      >&hellip;</span>
    </li>
  </ul>
</nav>
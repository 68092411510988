<div
  class="card has-shadow-hovered"
  data-test="courseCard"
  (click)="goTo(course.id)"
>
  <div class="card-image">
    <figure
      class="image _g-course-cover-image"
      [style.background-image]="course.coverImage && 'url(\'' + course.coverImage.url + '\')'"
    >
      <div
        class="_time-ago"
        *ngIf="showCreatedAgo"
      >
        {{ course.created | aTimeAgo }}
      </div>
    </figure>
  </div>
  <div class="card-content">
    <div class="content">
      <div class="_content-title is-double-line">
        {{ course.name }}
      </div>
      <div class="_content-body is-flex is-justify-between">
        <div class="_content-time">
          <div *ngIf="course.openTime; else keepSpace">
            <app-datetime-text
              i18n-label="@@datetimeOpen"
              [datetime]="course.openTime"
              label="Open"
            >
            </app-datetime-text>
          </div>
          <div *ngIf="course.closeTime; else keepSpace">
            <app-datetime-text
              i18n-label="@@datetimeClose"
              [datetime]="course.closeTime"
              label="Close"
            >
            </app-datetime-text>
          </div>
        </div>
        <div class="_progress">
          <div class="_tag-wrapper">
            <div
              class="_tag"
              [ngStyle]="{'background-color': course.courseTags[0].color}"
              *ngIf="course.courseTags[0]; else keepSpace"
            >
              {{course.courseTags[0].name}}
            </div>
          </div>
          <div class="icon-text _reward-point">
            <ng-container *ngIf="course.rewardPoint; else keepSpace">
              <img
                class="icon text-reward-point"
                src="static/image/icon/ic-point.png"
              />
              <span>{{ course.rewardPoint }}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="_content-body is-flex is-justify-between">
        <div
          class="_price is-flex is-align-center"
          *ngIf="course.isPricing"
        >
          <span
            class="_full-price"
            *ngIf="course.isDiscounting"
          >
            <app-price-text [price]="course.initailPrice"></app-price-text>
          </span>
          <span class="_current-price">
            <app-price-text [price]="course.netPrice"></app-price-text>
          </span>
        </div>
        <div
          class="_blank-pricing"
          *ngIf="!course.isPricing"
        ></div>

        <div class="_all-chapter is-flex is-align-center">
          <span
            i18n="@@completed"
            class="push-right-1"
            *ngIf="course.isPassed"
          >
            Completed
          </span>
          {{course.numberOfChapters}}&nbsp;
          <span
            i18n="@@chapterSingle"
            *ngIf="course.numberOfChapters <= 1"
          >Chapter</span>
          <span
            i18n="@@chapterPlural"
            *ngIf="course.numberOfChapters > 1"
          >Chapters</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #keepSpace>
  <div>
    &nbsp;
  </div>
</ng-template>
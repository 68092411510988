<div class="is-flex is-align-center _date-range">
  <div class="control has-icons-right">
    <input
      #tooltip="softTooltip"
      class="input"
      type="text"
      [softTooltip]="popContent"
      [trigger]="'click'"
      [hideOnClickOutside]="hideOnClickOutside"
      [theme]="'is-bordered'"
      (softTooltipHidden)="hide()"
      [ngModel]="displayValue"
      [placeholder]="placeholder"
      [disabled]="disabled"
      readonly
    >
    <span
      class="icon is-small is-right is-clickable text-size-xsmall"
      *ngIf="value && value.length && !disabled"
      (click)="onClear()"
    >
      <i class="delete is-small"></i>
    </span>
  </div>
</div>

<ng-template #popContent>
  <form
    #form="ngForm"
    class="_date-range-popover"
    [class.submitted]="form.submitted"
    (ngSubmit)="onSubmit(form)"
  >
    <div class="field is-grouped">
      <div class="control">
        <lib-date-picker
          name="fromDate"
          [max]="preValue[1]"
          [isStartOfDay]="true"
          [(ngModel)]="preValue[0]"
          (ngModelChange)="dateChange()"
          (opened)="datepickerOpened()"
          (closed)="datepickerClosed()"
        ></lib-date-picker>
      </div>
      <div class="control">
        <div class="is-control-height-centered">-</div>
      </div>
      <div class="control">
        <lib-date-picker
          name="toDate"
          [min]="preValue[0]"
          [isEndOfDay]="true"
          [(ngModel)]="preValue[1]"
          (ngModelChange)="dateChange()"
          (opened)="datepickerOpened()"
          (closed)="datepickerClosed()"
        ></lib-date-picker>
      </div>
      <div class="control">
        <button class="button">OK</button>
      </div>
    </div>
  </form>
</ng-template>
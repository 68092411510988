<div class="is-flex is-align-center _daterange-container">
  <div class="control has-icons-right is-fullwidth">
    <input
      #dateInput
      bsDaterangepicker
      type="text"
      class="input is-fullwidth"
      [bsConfig]="bsConfig"
      [minDate]="min"
      [maxDate]="max"
      [ngModel]="dateRange"
      (ngModelChange)="onValueChange($event)"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (onHidden)="onBlur($event)"
      (onShown)="onOpenCalendar($event)"
      readonly
    >
    <span
      class="icon is-small is-right is-clickable text-size-xsmall"
      *ngIf="dateRange && !disabled"
      (click)="onClear()"
    >
      <i class="delete is-small"></i>
    </span>
  </div>
</div>
<span
  class="icon push-right-1"
  *ngIf="showIcon"
>
  <i class="ic ic-calendar"></i>
</span>
<span
  *ngIf="label"
  class="_label"
>{{ label }}</span>
<span class="_detail">{{ datetime | aDateTime }}</span>
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '@utils';
import { SoftApiErrorHandlerService, SoftPopupService } from 'soft-ngx';
import { popupKeyStore, WelcomePopup } from './welcome-popup.service';

@Injectable()
export class ApiErrorHandlerService extends SoftApiErrorHandlerService {

  constructor(
    private popupService: SoftPopupService,
  ) {
    super();
  }

  handleError(err: HttpErrorResponse): void {
    let message = '';
    if (err.status === 400) {
      message = err.error;
      this.popupService.toast(err.error, 'danger');
    } else if (err.status === 409) {
      const popup: WelcomePopup = {
        message: 'The content has been changed, Please contact your administrator.',
        type: 'danger',
      };
      window.localStorage.setItem(popupKeyStore, JSON.stringify(popup));
      Utils.redirectToBasePath();
    } else {
      this.popupService.toast('Something wrong, please try again.', 'danger');
    }

  }
}

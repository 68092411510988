import { Component, OnInit, Input, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

  @HostBinding('class') hostClass = 'base-control';

  @Input() set format(format: string) {
    this.bsConfig.dateInputFormat = format;
  }

  @Input() controlClass: string;
  @Input() min?: Date;
  @Input() max?: Date;
  @Input() isStartOfDay = true; // 00:00:00
  @Input() isEndOfDay = false; // 23:59:59
  @Input() placeholder = '';
  @Input() monthOnly: boolean;
  @Input() set disabled(value) {
    if (value !== this._disabled) {
      this._disabled = value;
    }
  }
  get disabled() {
    return this._disabled;
  }
  private _disabled: boolean;

  @Output() closed = new EventEmitter<any>();
  @Output() opened = new EventEmitter<any>();

  date: Date | undefined;

  bsConfig: Partial<BsDatepickerConfig> = {};

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  onOpenCalendar(container: any) {
    if (this.monthOnly) {
      container.monthSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode('month');
    }
    this.opened.emit();
  }

  onValueChange(event: Date) {
    if (event) {
      const date = event;

      if (this.isStartOfDay) {
        date.setHours(0, 0, 0);
      }
      if (this.isEndOfDay) {
        date.setHours(23, 59, 59);
      }
      this.date = date;
      this.onChange(date);
    }
  }

  onClear() {
    this.date = undefined;
    this.onChange(undefined);
  }

  writeValue(value: any): void {
    this.date = value;
    this.cdr.markForCheck();
  }

  onBlur(event: Event) {
    this.onTouched(event);
    this.closed.emit(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  private onChange = (_: any) => { };
  private onTouched = (_: any) => { };

}

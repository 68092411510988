import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Model, SemestersService } from '@api-main';
import { BaseDropdown } from '@base/base-dropdown';

@Component({
  selector: 'shared-semester-dropdown',
  templateUrl: '../../../../projects/@base/src/base-dropdown/base-dropdown.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SharedSemesterDropdownComponent),
    multi: true,
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSemesterDropdownComponent extends BaseDropdown<Model.SemesterListDTO> {

  constructor(
    protected cdr: ChangeDetectorRef,
    private semestersService: SemestersService) {
    super(cdr);
  }

  displayOption(option: Model.SemesterListDTO) {
    return `${option.semesterNo}/${option.academicYear}`;
  }

  protected apiOptionListGet$() {
    return this.semestersService.apiSemestersDropdownListGet$();
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { BankAccountDTO } from '../model/bankAccountDTO';


@Injectable({
  providedIn: 'root',
})
export class BankService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   */
  public apiBankAccountsByCourseIdGet$(courseId: string): Observable<Array<BankAccountDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Bank/Accounts/${encodeURIComponent(String(courseId))}`, undefined, true);
  }
}

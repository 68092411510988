<div class="file">
  <label class="file-label">
    <input
      #fileInput="ngModel"
      class="file-input"
      type="file"
      accept="{{ accept }}"
      softFileModel
      [ngModel]="value"
      (ngModelChange)="fileChange($event, fileInput.valid)"
      [maxSize]="maxSize"
    >
    <div
      class="button {{ buttonClass }}"
      [softLoading]="loadingSub"
    >
      <ng-content></ng-content>
    </div>
  </label>
</div>
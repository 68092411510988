<input
  #input
  class="input {{ controlClass }}"
  type="text"
  [ngModel]="value"
  [typeahead]="data$"
  [typeaheadAsync]="true"
  [typeaheadMinLength]="minLength"
  [typeaheadScrollable]="true"
  [typeaheadWaitMs]="400"
  [typeaheadOptionField]="optionField"
  (typeaheadOnSelect)="onSelect($event)"
  (typeaheadLoading)="isLoading = $event"
  [optionsListTemplate]="optionsListTemplate"
  [container]="'body'"
  [placeholder]="placeholder"
  [disabled]="disabled"
  (input)="onInput(input.value)"
  (blur)="onBlur($event)"
>

<!-- latest ngx-bootstrap has list template -->
<ng-template
  #optionsListTemplate
  let-matches="matches"
  let-query="query"
  let-typeaheadTemplateMethods
>
  <div class="dropdown is-active">
    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          class="dropdown-item"
          [class.is-active]="typeaheadTemplateMethods.isActive(match)"
          *ngFor="let match of matches"
          (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
          (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
        >
          {{ match.item[optionField] }}
        </a>
      </div>
    </div>
  </div>
</ng-template>
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

/*
* format the duration time that not more than 24 hours
* | durationFormat
* | durationFormat: 'HH:mm:ss'
* | durationFormat: 'HH:', 'mm:ss'
* | durationFormat: 'H ชั่วโมง ' : 'm นาที ' : 's วินาที'
*/
@Pipe({ name: 'durationFormat' })
export class DurationFormatPipe implements PipeTransform {
  /*
  * @params seconds number of duration in seconds
  * @params ...format time format, remove 0 value if set multiple args
  */
  transform(seconds: number, ...format: string[]): string {
    if (seconds || seconds === 0) {
      const ms = seconds * 1000;
      if (format.length === 0) {
        // format = ['HH:', 'mm:ss']; // default
        format = ['HH:mm:ss']; // default
      }
      if (format.length === 1) {
        return DateTime.fromMillis(ms).toUTC().toFormat(format[0]);
      }
      let formatted = '';
      const isZero = /^00?[^\d]|[^\d]00?$|[^\d]00?[^\d]|^00?$/;
      for (const f of format) {
        const text = DateTime.fromMillis(ms).toUTC().toFormat(f);
        if (!isZero.test(text)) {
          formatted = `${formatted}${text}`;
        }
      }
      return formatted.trim();
    }
    return '';
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { CoursesService, FeaturesService, Model, SemestersService } from '@api-main';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class FeatureResolver implements Resolve<Observable<any>> {

  isPreloaded = false;

  constructor(
    private coursesService: CoursesService,
    private semestersService: SemestersService,
    private featuresService: FeaturesService,
    private authService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (this.isPreloaded) {
      return of(true);
    }
    return this.featuresService.apiFeaturesGet$().pipe(
      mergeMap(data => {
        this.authService.feature = data;
        this.isPreloaded = true;
        return this.preloadData(data);
      }),
    );
  }

  private preloadData(feature: Model.FeatureDTO): Observable<any[]> {
    const req: Observable<any>[] = [];

    // preload semester
    this.authService.setCourseState({ semesterId: undefined });
    if (feature.semester) {
      req.push(
        this.semestersService.apiSemestersDropdownListGet$().pipe(
          mergeMap(data => {
            let activeSemesterId = this.authService.getCourseState().semesterId;
            if (data && data.length > 0) {
              if (!data.some(semester => semester.id === activeSemesterId)) {
                const activeSemester = data.find(item => item.isCurrent) || data[0];
                activeSemesterId = activeSemester.id;
              }
              this.authService.setCourseState({ semesterId: activeSemesterId });
              // preload course role for this semester
              return this.coursesService.apiCoursesUserRolesGet$(activeSemesterId).pipe(
                map(data => {
                  this.authService.hasRoleOwner = data.some(role => role.role === Model.CourseRole.Owner);
                  return true;
                })
              )
            } else {
              this.authService.clearSemester();
            }
            return of(true);
          }),
        ),
      );
    } else {
      this.authService.setCourseState({ semesterId: undefined });
      req.push(of(true));
    }

    return forkJoin(req);
  }
}

import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { addTHSarabunNewBold } from '../../../static/THSarabunNew-Bold';
import { addTHSarabunNewBoldItalic } from '../../../static/THSarabunNew-BoldItalic';
import { addTHSarabunNewItalic } from '../../../static/THSarabunNew-Italic';
import { addTHSarabunNewNormal } from '../../../static/THSarabunNew-normal';
// declare var jsPDF: any;

@Injectable()
export class ExportPdfGridService {

  constructor() { }

  export(rows: any, columns: any[], fileName = 'report.pdf', fontSize = 14, columnStyles = {}) {
    const dataRows = [] as any[];
    let dataRow;
    let value;
    for (const row of rows) {
      dataRow = [] as any[];
      for (const col of columns) {
        if (col.prop.indexOf('.') !== -1) {
          value = this.deepValueGetter(row, col.prop);
        } else {
          value = this.shallowValueGetter(row, col.prop);
        }
        dataRow.push(value);
      }
      dataRows.push(dataRow);
    }

    const doc = new jsPDF('p', 'pt');
    addTHSarabunNewBold(doc);
    addTHSarabunNewBoldItalic(doc);
    addTHSarabunNewItalic(doc);
    addTHSarabunNewNormal(doc);
    autoTable(doc, {
      head: [columns.map(c => c.name)],
      body: dataRows,
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        font: 'THSarabunNew',
        fontSize,
      },
      headStyles: {
        overflow: 'ellipsize',
        fillColor: [0, 124, 192],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
      columnStyles,
    });
    doc.save(fileName);
  }

  private shallowValueGetter(obj: any, fieldName: string): any {
    if (obj == null) { return ''; }
    if (!obj || !fieldName) { return obj; }

    const value = obj[fieldName];
    if (value == null) { return ''; }
    return value;
  }

  private deepValueGetter(obj: any, path: string): any {
    if (obj == null) { return ''; }
    if (!obj || !path) { return obj; }

    // check if path matches a root-level field
    // { "a.b.c": 123 }
    let current = obj[path];
    if (current !== undefined) { return current; }

    current = obj;
    const nesteds = path.split('.');

    for (const nested of nesteds) {
      current = current[nested];

      // if found undefined, return empty string
      if (current === undefined || current === null) { return ''; }
    }

    return current;
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HeartbeatResultDTO {
  id: string;
  session: string;
  courseId: string;
  timeUsed: number;
  notiUnreadCount: number;
}

import { Component, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Model } from '@api-main';
import { BaseDropdown } from '@base/base-dropdown';
import { of } from 'rxjs';

@Component({
  selector: 'shared-grading-status-dropdown',
  templateUrl: '../../../../projects/@base/src/base-dropdown/base-dropdown.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedGradingStatusDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedGradingStatusDropdownComponent extends BaseDropdown<{ id: Model.GradingStatus, name: string }> {

  isPrimitive = true;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  protected apiOptionListGet$() {
    return of([
      { id: Model.GradingStatus.Waiting, name: 'Waiting' },
    ]);
  }
}

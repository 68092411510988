import { Component, OnInit } from '@angular/core';
import { AuthGuard } from './core/guards/auth-guard';
import { Router, NavigationEnd } from '@angular/router';
import { HeartBeatManagerService } from './core/services/heart-beat-manager.service';
import { VersionService, WelcomePopupService, SignalrService } from '@lib';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public authGuard: AuthGuard,
    private authService: AuthService,
    private versionService: VersionService,
    private welcomePopupService: WelcomePopupService,
    private signalrService: SignalrService,
    private heartBeatManager: HeartBeatManagerService,
    private router: Router,
  ) {
    this.versionService.observe();
    this.welcomePopupService.observe();
    this.heartBeatManager.heartbeatInterval();

    if (this.authService.isLoggedIn) {
      this.signalrService.start(() => this.authService.getAccessToken());
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Set heart beat course id
        const match = /^\/courses\/([^\/]+)/.exec(event.url);
        if (match) {
          this.heartBeatManager.courseId = match[1];
        } else {
          this.heartBeatManager.courseId = undefined;
        }
      }
    });
  }


}

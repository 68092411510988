import { OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Directive, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Directive()
export class BaseDropdown<T> implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {

  @HostBinding('class') hostClass = 'base-control';

  @Input() controlClass: string;
  @Input() placeholder: string;
  @Input() compareWith: (o1: any, o2: any) => boolean;
  @Input() isPrimitive = false;
  @Input() autoReset = false;

  @Input() set showAll(showAll: boolean | string) {
    this._showAll = !!showAll;
    if (this._showAll && typeof showAll === 'string') {
      this.showAllText = showAll as string;
    }
    this.showPlaceHolder = !this._showAll || (this._showAll && this.showAllValue !== undefined);
  }

  @Input() showAllValue: any = undefined;

  @Output() loaded = new EventEmitter<T[]>();

  optionList: T[];
  isOptionListPrimitive = false;

  idAttr = 'id';
  textAttr = 'name';
  value: T | undefined;
  disabled: boolean;
  loading: boolean;

  _showAll: boolean;
  showAllText = 'All';
  showPlaceHolder = true;

  inputChanges = [] as string[];

  private getOptionListSub: any;

  compareFn = (o1: any, o2: any): boolean => {
    return o1 && o2 ? o1[this.idAttr] === o2[this.idAttr] : o1 === o2;
  }

  compareFnBothPrimitive = (o1: any, o2: any): boolean => {
    return o1 === o2;
  }

  constructor(
    protected cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.inputChanges.some(input => changes[input] && !changes[input].firstChange)) {
      if (this.autoReset) {
        this.reset();
      }
      this.getOptionList();
    }
  }

  ngOnInit() {
    this.setDefaultCompareWith();
    this.getOptionList();
  }

  ngOnDestroy() {
    if (this.getOptionListSub) {
      this.getOptionListSub.unsubscribe();
    }
  }

  displayOption(option: T) {
    return this.isOptionListPrimitive ? option : option[this.textAttr];
  }

  onSelect() {
    if (this.isOptionListPrimitive === this.isPrimitive) {
      this.onChange(this.value);
    } else if (this.isPrimitive) {
      // convert to flat value
      this.onChange(this.value ? this.value[this.idAttr] : this.value);
    } else {
      // convert to object value
      this.onChange({ [this.idAttr]: this.value });
    }
  }

  onBlur(event: Event) {
    this.onTouched(event);
  }

  reset() {
    if (this._showAll) {
      this.value = this.showAllValue;
    } else {
      this.value = undefined;
    }
    window.setTimeout(() => {
      this.onSelect();
    });
  }

  writeValue(value: any): void {
    if (value === undefined || value === null) {
      this.value = value;
    } else if (this.isOptionListPrimitive === this.isPrimitive) {
      this.value = value;
    } else if (this.isPrimitive) {
      // convert to object value
      this.value = { [this.idAttr]: value } as any;
    } else {
      // convert to flat value
      this.value = value[this.idAttr] as any;
    }

    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.cdr) {
      this.cdr.markForCheck();
    }
  }

  private onChange = (_: any) => { };
  private onTouched = (_: any) => { };

  protected setDefaultCompareWith() {
    if (!this.compareWith) {
      if (this.isOptionListPrimitive && this.isPrimitive) {
        this.compareWith = this.compareFnBothPrimitive;
      } else {
        this.compareWith = this.compareFn;
      }
    }
  }

  protected apiOptionListGet$(): Observable<T[]> {
    throw new Error('Method not implemented.');
  }

  protected getOptionList() {
    this.loading = true;
    this.getOptionListSub = this.apiOptionListGet$().pipe(
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      }),
    ).subscribe(data => {
      this.optionList = data;
      this.loaded.emit(this.optionList);
    });
  }

}

import { Injectable } from '@angular/core';
import { Model } from '@api-main';
import { saveAs } from 'file-saver';

@Injectable()
export class DownloadService {

  downloadFileResult(file: Model.FileResult, name?: string) {
    saveAs(file.url, name || `${file.name}${file.ext}`);
  }

  downloadFromUrl(url: string, name?: string) {
    const filename = name || url.replace(/^.*[\\\/]/, '');
    saveAs(url, filename);
  }
}

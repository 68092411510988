import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Model } from '@api-main';

@Component({
  selector: 'lib-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationWidgetComponent implements OnInit {

  Actions = Model.NotificationWebAction;

  @Input() notifications: Model.NotificationDTO[];
  @Input() showLoadMore: boolean;
  @Input() loadNotificationsSub: any;

  @Output() goTo = new EventEmitter();
  @Output() loadMore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }

  onGoTo(notification: Model.NotificationDTO): void {
    this.goTo.emit(notification);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reload',
  template: '',
})
export class ReloadComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    const returnPath = this.route.snapshot.paramMap.get('returnPath')!;
    this.router.navigateByUrl(returnPath);
  }

}

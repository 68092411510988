import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  // Modules
  SoftNgxModule,

  // Config Interfaces
  SoftApiClientConfig,
  SoftAuthServiceConfig,
  SoftAuthInterceptorConfig,
  SoftAuthRequestKey,
  SoftAuthResponseKey,
  SoftPopupConfig,
  SoftStorageConfig,

  // Interceptor
  DateRequestInterceptor,
  NoCacheInterceptor,

  // Config Tokens
  userSoftApiClientConfigToken,
  userSoftAuthServiceConfigToken,
  userSoftAuthInterceptorConfigToken,
  userSoftAuthRequestKeyToken,
  userSoftAuthResponseKeyToken,
  userSoftPopupConfigToken,
  userSoftStorageConfigToken,
  SoftAuthInterceptor,
  SoftApiErrorHandlerService,
} from 'soft-ngx';

import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule, TypeaheadConfig } from 'ngx-bootstrap/typeahead';
import { SidebarModule } from 'ng-sidebar';

import { dynamicEnvironment } from '@env';
import { NewVersionNoticeComponent } from './components/new-version-notice/new-version-notice.component';
import { dateRequestFormatter, dateResponseReviver } from './interceptors/date-interceptor-function';
import { SidebarService } from './services/sidebar.service';
import { VersionService } from './services/version.service';
import { DatePipe } from './pipes/format/date.pipe';
import { DateTimePipe } from './pipes/format/dateTime.pipe';
import { WelcomePopupService } from './services/welcome-popup.service';
import { ApiErrorHandlerService } from './services/api-error-handler.service';
import { SignalrService } from './services/signalr.service';
import { DismissibleToastComponent } from './components/dismissible-toast.component';


export function initSoftApiClientConfig(): SoftApiClientConfig {
  return {
    pageHeaderResponseKeys: {
      pageCount: 'X-Paging-PageCount',
      totalCount: 'X-Paging-TotalRecordCount',
    },
    dateRequestFormatter,
    dateResponseReviver,
  };
}

export function initSoftAuthServiceConfig(): SoftAuthServiceConfig {
  return {
    tokenUrl: dynamicEnvironment.config.tokenUrl,
    isOAuth: false,
    isFormData: false,
    isJWT: true,
  };
}

export function initSoftAuthInterceptorConfigToken(): SoftAuthInterceptorConfig {
  return {
    autoRefreshToken: false,
    loginScreenUrl: dynamicEnvironment.config.loginScreenUrl,
  };
}

export function initSoftPopupConfig(): SoftPopupConfig {
  return {
    // general
    agreeText: $localize`:@@yes:Yes`,
    disagreeText: $localize`:@@no:No`,
    // alert
    alertAgreeText: $localize`:@@ok:OK`,
    // delete
    deleteTitleFunc: (itemName: string) => {
      return $localize`:@@confirmDeletion:Confirm Deletion`;
    },
    deleteMessageFunc: (itemName: string) => {
      return $localize`:@@deletionMessage:Are you sure you want to delete "${itemName}"?`;
    },
  };
}

export function initSoftStorageConfig(): SoftStorageConfig {
  return {
    storagePrefix: dynamicEnvironment.config.storagePrefix,
  };
}

const authRequestKey: SoftAuthRequestKey = {
  username: 'username',
  password: 'password',
};

const authResponseKey: SoftAuthResponseKey = {
  access_token: 'access_token',
};

const bsDatepickerConfig = Object.assign(new BsDatepickerConfig(), {
  adaptivePosition: true,
  containerClass: 'theme-default',
  dateInputFormat: 'MMM D, YYYY',
  showWeekNumbers: false,
});

const bsDaterangepickerConfig = Object.assign(new BsDaterangepickerConfig(), {
  adaptivePosition: true,
  containerClass: 'theme-default',
  rangeInputFormat: 'MMM D, YYYY',
  showWeekNumbers: false,
  displayMonths: 1,
});

const typeaheadConfig = Object.assign(new TypeaheadConfig(), {
  adaptivePosition: true,
  cancelRequestOnFocusLost: true,
});

@NgModule({
  imports: [
    // soft-ngx
    SoftNgxModule.forRoot(),

    // others
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    SidebarModule.forRoot(),
  ],
  declarations: [
    NewVersionNoticeComponent,
    DismissibleToastComponent,
  ],
})
export class LibCoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: LibCoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'LibCoreModule');
  }

  static forRoot(): ModuleWithProviders<LibCoreModule> {
    return {
      ngModule: LibCoreModule,
      providers: [
        { provide: BsDatepickerConfig, useValue: bsDatepickerConfig },
        { provide: BsDaterangepickerConfig, useValue: bsDaterangepickerConfig },
        { provide: TypeaheadConfig, useValue: typeaheadConfig },
        { provide: SoftApiErrorHandlerService, useClass: ApiErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: SoftAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
        { provide: userSoftApiClientConfigToken, useFactory: initSoftApiClientConfig },
        { provide: userSoftAuthServiceConfigToken, useFactory: initSoftAuthServiceConfig },
        { provide: userSoftAuthInterceptorConfigToken, useFactory: initSoftAuthInterceptorConfigToken },
        { provide: userSoftAuthRequestKeyToken, useValue: authRequestKey },
        { provide: userSoftAuthResponseKeyToken, useValue: authResponseKey },
        { provide: userSoftPopupConfigToken, useFactory: initSoftPopupConfig },
        { provide: userSoftStorageConfigToken, useFactory: initSoftStorageConfig },
        SidebarService,
        VersionService,
        WelcomePopupService,
        SignalrService,
        DatePipe,
        DateTimePipe,
      ],
    };
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`,
    );
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService, CoursesService, UsersService, Model, NotificationsService, ApiHeaderResponse } from '@api-main';
import { AuthService } from '@app/core/services/auth.service';
import { localeEnum, LocaleService } from '@app/core/services/locale.service';
import { dynamicEnvironment } from '@env';
import { SignalrService } from '@lib';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HeaderResponse } from 'soft-ngx';

enum localeTranslateEnum {
  th = 'ไทย',
  en = 'ENG',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  LocaleTranslateEnum = localeTranslateEnum;
  LocaleEnum = localeEnum;

  @Input() isMiniBar = false;
  @Output() toggleSideNavOnTouch = new EventEmitter();
  @Output() toggleSideNavDesktop = new EventEmitter();

  name: string | null;
  company: Model.CompanyDTO;
  profilePictureUrl: string;
  level: Model.UserLevelDTO;
  activeSemester: Model.SemesterListDTO;

  adminSiteUrl = dynamicEnvironment.config.adminSiteUrl;
  loginScreenUrl = dynamicEnvironment.config.loginScreenUrl;

  canAccessAdminPanel = false;
  isShowSearchBarOnTouch = false;
  enableMenuHoverable = true;

  searchQuery: string;
  searchAutoCompleteData$: Observable<Model.CourseDropdownItem[]>;

  notifications: Model.NotificationDTO[] = [];
  noticationPage = 1;
  noticationPerPage = 10;
  showLoadMoreNotifications = true;
  loadNotificationsSub: Subscription;

  constructor(
    public localeService: LocaleService,
    private usersService: UsersService,
    private authService: AuthService,
    private tokenService: TokenService,
    private courseService: CoursesService,
    private notificationsService: NotificationsService,
    private signalrService: SignalrService,
    private router: Router) {
  }

  ngOnInit(): void {
    const courseState = this.authService.getCourseState();
    if (courseState.semesterId) {
      this.activeSemester = { id: courseState.semesterId } as Model.SemesterListDTO;
    }

    this.tokenService.apiTokenCanAccessAdminPanelPost$().subscribe((data) => {
      this.canAccessAdminPanel = data.result;
    });

    this.name = this.authService.name;
    this.profilePictureUrl = this.authService.profilePictureUrl;
    this.usersService.apiUsersMyProfileGet$()
      .subscribe(data => {
        this.company = data.company;
        this.level = data.level;
      });

    this.searchAutoCompleteData$ = new Observable((observer: Observer<string>) => {
      observer.next(this.searchQuery);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.courseService.apiCoursesDropdownListGet$(undefined, query);
        }
        return of([]);
      }),
    );
  }

  menuHoverableClicked(): void {
    this.enableMenuHoverable = false;
    setTimeout(() => {
      this.enableMenuHoverable = true;
    }, 300);
  }

  onToggleSideNavOnTouch(): void {
    this.toggleSideNavOnTouch.emit();
  }

  onToggleSideNavDesktop(): void {
    this.toggleSideNavDesktop.emit();
  }

  search(): void {
    if (this.searchQuery && this.searchQuery.trim()) {
      this.router.navigate(['/all-courses', { q: this.searchQuery.trim() }]);
    } else {
      this.router.navigate(['/all-courses']);
    }
  }

  semesterChange(): void {
    this.authService.setCourseState({ semesterId: this.activeSemester.id });
  }

  logout(): void {
    this.authService.logout();
    this.signalrService.stop();

    window.location.href = this.loginScreenUrl;
  }

  selectLocale(lang: localeEnum): void {
    if (lang !== this.localeService.active) {
      this.localeService.switchLang(lang);
    }
  }

  loadNotifications(isMore: boolean): void {
    if (isMore) {
      this.noticationPage += 1;
    } else {
      this.notifications = [];
      this.noticationPage = 1;
    }
    this.getNotifications();
  }

  markAllAsRead(): void {
    this.notificationsService.apiNotificationsMarkAllAsReadPut$()
      .subscribe();
  }

  private getNotifications(): void {
    const headerResponse = {} as ApiHeaderResponse;
    this.loadNotificationsSub = this.notificationsService.apiNotificationsGet$(this.noticationPage, this.noticationPerPage, headerResponse)
      .subscribe(data => {
        this.notifications = [...this.notifications, ...data];
        this.showLoadMoreNotifications = this.noticationPage !== headerResponse.pageCount;
      });
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FeatureDTO {
  payment: boolean;
  certification: boolean;
  videoQuiz: boolean;
  htmlContent: boolean;
  pdfContent: boolean;
  liveVideo: boolean;
  onlineExam: boolean;
  experienceLevel: boolean;
  reward: boolean;
  semester: boolean;
  hrAdmin: boolean;
  learnerRegister: boolean;
  msTeamIntegration: boolean;
}

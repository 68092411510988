import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shared-datetime-range-text',
  templateUrl: './datetime-range-text.component.html',
  styleUrls: ['./datetime-range-text.component.scss'],
})
export class DatetimeRangeTextComponent implements OnInit {

  @Input() startDatetime: Date;
  @Input() endDatetime: Date;
  @Input() label?: string;
  @Input() showIcon = false;

  constructor() { }

  ngOnInit() {
  }

}

import { Directive, Input, HostListener } from '@angular/core';

@Directive()
export class BaseResponsiveSkeleton {
  numArray: number[];
  windowWidth: number;
  perView: number;
  defaultPerView: number;
  mobile: number;
  tablet: number;
  desktop: number;
  widescreen: number;
  fullhd: number;
  itemClass: string | undefined;

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.setBreakpoint();
    this.numArray = Array.from({ length: this.perView });
  }

  @Input() set context(context: { perView: number, tablet?: number, desktop?: number, widescreen?: number, fullhd?: number, itemClass?: string }) {
    const { perView, tablet, desktop, widescreen, fullhd, itemClass } = context;
    this.windowWidth = window.innerWidth;
    this.defaultPerView = perView;
    this.mobile = perView;
    this.tablet = tablet || this.mobile;
    this.desktop = desktop || this.tablet;
    this.widescreen = widescreen || this.desktop;
    this.fullhd = fullhd || this.widescreen;
    this.itemClass = itemClass;
    this.setBreakpoint();
    this.numArray = Array.from({ length: this.perView });
  }


  private setBreakpoint(): void {
    switch (true) {
      case this.windowWidth < 768:
        this.perView = this.mobile;
        break;
      case this.windowWidth < 1264:
        this.perView = this.tablet;
        break;
      case this.windowWidth < 1580:
        this.perView = this.desktop;
        break;
      case this.windowWidth < 1900:
        this.perView = this.widescreen;
        break;
      default:
        this.perView = this.fullhd;
        break;
    }
  }

}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type UserCourseActivityLogType = 'Online' | 'Virtual' | 'Test' | 'QA' | 'Download';

export const UserCourseActivityLogType = {
  Online: 'Online' as UserCourseActivityLogType,
  Virtual: 'Virtual' as UserCourseActivityLogType,
  Test: 'Test' as UserCourseActivityLogType,
  QA: 'QA' as UserCourseActivityLogType,
  Download: 'Download' as UserCourseActivityLogType,
};

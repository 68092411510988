import { ViewChild, OnInit, Directive } from '@angular/core';
import { BaseFeatureComponent } from './base-feature';
import { GridComponent, GridTableColumn } from '@lib';
import { HeaderResponse, SoftModalComponent, SoftPopupService } from 'soft-ngx';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseFeatureGridComponent<PropType, FilterType = any> extends BaseFeatureComponent implements OnInit {

  @ViewChild('grid', { static: true }) grid: GridComponent<PropType>;
  @ViewChild('modal') modal: SoftModalComponent;

  // table
  rows: PropType[];
  columns: GridTableColumn<PropType>[];
  page = {
    page: 1,
    perPage: 10,
    totalCount: 0,
  };
  // sortBy = listAdminSortByEnum;
  sort = {} as {
    prop?: string;
    sortBy: any;
    asc: boolean;
  };
  filter = {} as Partial<FilterType>;

  // data
  item: PropType;
  oItem?: PropType;
  isNew: boolean;

  // subscription;
  dataSub: Subscription;
  saveSub: Subscription;

  constructor(
    protected popupService?: SoftPopupService,
  ) {
    super();
  }

  ngOnInit() {
    this.setColumns();
  }

  pageChange() {
    this.getData();
  }

  sortChange() {
    this.page.page = 1;
    this.getData();
  }

  filterChange(event?: any) {
    if (event) {
      this.filter = event;
    }
    this.page.page = 1;
    this.getData();
  }

  add() {
    delete this.oItem;
    this.item = {} as PropType;
    this.isNew = true;
    this.modal.open();
  }

  edit(item: any) {
    this.oItem = item;
    this.item = { ...item } as PropType;
    this.isNew = false;
    this.modal.open();
  }

  saveData(item: PropType) {
    if (this.isNew) {
      this.saveSub = this.createData$(item)
        .subscribe(data => {
          this.getData();
          this.modal.close();
        });
    } else {
      this.saveSub = this.updateData$(item)
        .subscribe(data => {
          this.getData();
          this.modal.close();
        });
    }
  }

  deleteData(item: PropType) {
    if (!this.popupService) {
      throw new Error('Need inject popupService');
    }
    this.popupService.confirmDelete(this.deleteName(item))
      .subscribe(result => {
        if (result) {
          result.actionSub = this.deleteData$(item)
            .subscribe(() => {
              this.getData();
            });
        }
      });
  }

  protected getData() {
    const headerResponse = {} as { pageCount: number, totalCount: number };
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    this.dataSub = this.getData$(headerResponse).pipe(
      takeUntil(this.destroy$),
    ).subscribe(data => {
      this.rows = data;
      if (headerResponse.totalCount !== undefined) {
        this.page.totalCount = headerResponse.totalCount;
      }
    });
  }

  protected createData$(item: PropType): Observable<any> { return EMPTY; }
  protected updateData$(item: PropType): Observable<any> { return EMPTY; }
  protected deleteData$(item: PropType): Observable<any> { return EMPTY; }

  protected deleteName(item: PropType): string {
    throw new Error('Need implement deleteName()');
  }

  protected abstract setColumns(): any;
  protected abstract getData$(headerResponse?: HeaderResponse): Observable<PropType[]>;
}

import { Injectable } from '@angular/core';
import {
  Router, CanActivate, CanActivateChild,
  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Subject } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  loading$ = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const currentUrlPath = state.url;
    // this.loading$.next(true);

    if (this.authService.isLoggedIn) {
      // logged in and authorized
      if (!route.data.feature || (route.data.feature && this.authService.feature[route.data.feature])) {
        return true;
      }
      // logged in but not authorized, go to root, then RedirectGuard will handle
      return this.router.createUrlTree(['/']);
    } else {
      return this.router.createUrlTree(['/auth/login'], { queryParams: { returnUrl: currentUrlPath } });
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(route, state);
  }
}

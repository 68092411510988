/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MatchingSecondItemDTO {
  id: string;
  order: number;
  indicator: string;
  desc: string;
  totalScore: number;
  score: number;
  answerItemId: string;
  correctItemId: string;
  isCorrect: boolean;
}

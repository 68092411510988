import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Model } from '@api-main';

type Prerequisite = {
  name: string;
  isCompleted: boolean;
  isStarted: boolean;
  id: string;
  lectureType?: Model.LectureType;
};

@Component({
  selector: 'lib-prerequisite',
  templateUrl: './prerequisite.component.html',
  styleUrls: ['./prerequisite.component.scss'],
})
export class PrerequisiteComponent implements OnInit {
  Roles = Model.CourseRole;

  @Input() prerequisites: Prerequisite[];
  @Input() courseId?: string;
  @Input() role: Model.CourseRole;
  @Input() isCourse = false;
  @Input() disabledLink = false;
  @Input() isExpand = true; // default isExpand

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  toggleExpand() {
    this.isExpand = !this.isExpand;
  }

  goTo(prerequisite: Prerequisite) {
    if (this.disabledLink) { return; }
    if (this.isCourse) {
      this.router.navigate([`/courses/${prerequisite.id}`]);
    } else {
      const contentKey = `${(prerequisite.lectureType as string).toLowerCase()}Content`;
      if (this.role === this.Roles.Learner) {
        // Learner and exam
        if (prerequisite.lectureType === Model.LectureType.Exam) {
          this.router.navigate(['/courses', this.courseId, 'exams', prerequisite[contentKey].id]);
          return;
        }
      } else {
        // Admin, Owner
        if (prerequisite.lectureType === Model.LectureType.Exam) {
          this.router.navigate(['/course-owner/grading-exam', prerequisite[contentKey].id]);
          return;
        } else if (prerequisite.lectureType === Model.LectureType.Assignment) {
          this.router.navigate(['/course-owner', this.courseId, 'grading-assignment', prerequisite[contentKey].id]);
          return;
        }
      }
      // other lecture content, (Admin, Owner, Learner)
      this.router.navigate([`/courses/${this.courseId}/lecture/${prerequisite.id}/${prerequisite.lectureType}/${prerequisite[contentKey].id}`, { role: this.role }]);
    }
  }

}

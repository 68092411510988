/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VimeoVideoStatus = 'Waiting' | 'Completed';

export const VimeoVideoStatus = {
  Waiting: 'Waiting' as VimeoVideoStatus,
  Completed: 'Completed' as VimeoVideoStatus,
};

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CourseRole = 'Owner' | 'Admin' | 'Learner';

export const CourseRole = {
  Owner: 'Owner' as CourseRole,
  Admin: 'Admin' as CourseRole,
  Learner: 'Learner' as CourseRole,
};

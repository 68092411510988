/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PaymentStatus = 'WaitPayment' | 'WaitForConfirm' | 'Completed' | 'Cancelled';

export const PaymentStatus = {
  WaitPayment: 'WaitPayment' as PaymentStatus,
  WaitForConfirm: 'WaitForConfirm' as PaymentStatus,
  Completed: 'Completed' as PaymentStatus,
  Cancelled: 'Cancelled' as PaymentStatus,
};

import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { Model } from '@api-main';

@Component({
  selector: 'lib-file-download-button',
  templateUrl: './file-download-button.component.html',
  styleUrls: ['./file-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDownloadButtonComponent {

  @Input() buttonClass: string;
  @Input() file: Blob | File | string | Model.FileResult;
  @Input() filename: string;
  @Input() actionSub: Subscription;

  @Output() download = new EventEmitter();

  onDownload() {
    if (this.file) {
      if ((this.file as Model.FileResult).url) {
        const fileResult = this.file as Model.FileResult;
        saveAs(fileResult.url, this.filename || `${fileResult.name}${fileResult.ext}`);
      } else if (typeof this.file === 'string') {
        const filename = this.filename || this.file.replace(/^.*[\\\/]/, '');
        saveAs(this.file, filename);
      } else {
        saveAs(this.file as Blob, this.filename);
      }
    }
    this.download.emit();
  }

}

import { Component, Input } from '@angular/core';
import { CourseItemComponent } from '../course-item.component';
import { Model } from '@api-main';

@Component({
  selector: 'app-course-highlight',
  templateUrl: './course-highlight.component.html',
  styleUrls: ['./course-highlight.component.scss'],
})
export class CourseHighlightComponent extends CourseItemComponent {

  @Input() course: Model.VideoLiveCardDTO;

}

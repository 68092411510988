import { dynamicEnvironment } from '@env';
import { HeaderResponse } from 'soft-ngx';

export function apiBaseUrl() {
  return dynamicEnvironment.config.apiBaseUrl;
}
export interface ApiHeaderResponse extends HeaderResponse {
  pageCount: number;
  totalCount: number;
}

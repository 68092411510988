import { Injectable } from '@angular/core';
import { SoftAuthServiceBase } from 'soft-ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { Model } from '@api-main';

export enum AuthData {
  email = 'email',
  name = 'name',
  profilePicture = 'profilePicture',
  isTempPassword = 'is_temp_password',
}

export const courseRoleText = {
  [Model.CourseRole.Admin]: 'Admin',
  [Model.CourseRole.Learner]: 'Learner',
  [Model.CourseRole.Owner]: 'Lecturer',
}

export interface CourseState {
  role: Model.CourseRole;
  semesterId?: string;
  regisAttempt?: number;
}

@Injectable()
export class AuthService extends SoftAuthServiceBase {

  feature = {} as Model.FeatureDTO;
  hasRoleOwner = false;

  private courseStateSubject = new BehaviorSubject<CourseState | undefined>(undefined);

  getAdditionalAuthData(): any[] {
    return Object.keys(AuthData).map(key => AuthData[key]);
  }

  bypassLogin(accessToken: string, expiresIn?: number, email?: string, profilePicture?: string): void {
    const response = {
      access_token: accessToken,
      expires_in: expiresIn,
      refresh_token: undefined,
      scope: undefined,
      email,
      profilePicture,
    };
    if (this.config.isJWT) {
      const [claims, claimsJson] = this.decodeIdToken(response.access_token);
      this.storage.setItem('claims_obj', claimsJson);
      response.expires_in = response.expires_in || claims.exp;
    }
    this.storeAccessToken(response.access_token, response.refresh_token, response.expires_in, response.scope);
    this.setAdditionalAuthData(response);
  }

  setProfilePicture(url: string) {
    this.storage.setItem(AuthData.profilePicture, url);
  }

  getCourseState$(): Observable<CourseState | undefined> {
    return this.courseStateSubject.asObservable();
  }

  // use storage across webview
  getCourseState(): CourseState {
    const courseRole = this.storage.getItem('courseRole') as Model.CourseRole;
    const activeSemesterId = this.feature.semester && this.storage.getItem('activeSemesterId');
    let activeRegisAttempt = this.storage.getItem('activeRegisAttempt') as any;
    activeRegisAttempt = activeRegisAttempt ? parseInt(activeRegisAttempt, 10) : undefined;
    return {
      role: courseRole || Model.CourseRole.Learner,
      semesterId: activeSemesterId || undefined,
      regisAttempt: activeRegisAttempt,
    };
  }

  // use storage across webview
  setCourseState(data: Partial<CourseState>): void {
    if (data.role) {
      this.storage.setItem('courseRole', data.role);
    }
    if (data.semesterId) {
      this.storage.setItem('activeSemesterId', data.semesterId);
    }
    if (data.regisAttempt) {
      this.storage.setItem('activeRegisAttempt', data.regisAttempt);
    }
    this.courseStateSubject.next(this.getCourseState());
  }

  // for courseState subscriber waiting for new data
  clearCourseState(): void {
    this.courseStateSubject.next(undefined);
  }

  clearSemester(): void {
    this.storage.removeItem('activeSemesterId');
    this.courseStateSubject.next(this.getCourseState());
  }

  clearRegisAttempt(): void {
    this.storage.removeItem('activeRegisAttempt');
    this.courseStateSubject.next(this.getCourseState());
  }

  matchCourseRole(currentCourseRole: Model.CourseRole, roles: Model.CourseUserRoleDTO[]): Model.CourseRole {
    let matchedRole = currentCourseRole;
    if (roles.length === 1) {
      matchedRole = roles[0].role;
    } else {
      let isCurrentRoleExistInRoles = false;
      for (const r of roles) {
        if (r.role === currentCourseRole) {
          isCurrentRoleExistInRoles = true;
        }
      }
      if (!isCurrentRoleExistInRoles) {
        matchedRole = roles[0].role;
      }
    }
    return matchedRole;
  }

  get email(): string | null {
    return this.storage.getItem(AuthData.email);
  }

  get name(): string {
    return this.storage.getItem(AuthData.name) ?? '';
  }

  get profilePictureUrl(): string {
    return this.storage.getItem(AuthData.profilePicture) ?? 'static/image/profile.png';
  }

  get isTempPassword(): boolean {
    return this.storage.getBoolean(AuthData.isTempPassword);
  }
}

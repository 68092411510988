import { NgModule } from '@angular/core';
import { LibSharedModule } from '@lib';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxGlideModule } from 'ngx-glide';

import { CourseItemComponent } from './components/course-item/course-item.component';
import { CourseHighlightComponent } from './components/course-item/course-highlight/course-highlight.component';
import { CourseBoxVirtualComponent } from './components/course-item/course-box-virtual/course-box-virtual.component';
import { CourseCardComponent } from './components/course-item/course-card/course-card.component';
import { CourseBoxComponent } from './components/course-item/course-box/course-box.component';
import { DatetimeTextComponent } from './components/datetime-text/datetime-text.component';
import { DatetimeRangeTextComponent } from './components/datetime-range-text/datetime-range-text.component';
import { EditorComponent } from './components/editor/editor.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ContentAttachmentListComponent } from './components/content-attachment-list/content-attachment-list.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { PriceTextComponent } from './components/price-text/price-text.component';
import { AttemptSelectorComponent } from './components/attempt-selector/attempt-selector.component';

import { SharedSemesterDropdownComponent } from './dropdown/shared-semester-dropdown.component';
import { SharedGradingStatusDropdownComponent } from './dropdown/shared-grading-status-dropdown.component';

@NgModule({
  imports: [
    LibSharedModule,
    NgxGlideModule,
  ],
  declarations: [
    CourseItemComponent,
    CourseHighlightComponent,
    CourseBoxVirtualComponent,
    CourseCardComponent,
    CourseBoxComponent,
    DatetimeTextComponent,
    DatetimeRangeTextComponent,
    EditorComponent,
    PaginationComponent,
    ContentAttachmentListComponent,
    CarouselComponent,
    PriceTextComponent,
    AttemptSelectorComponent,

    SharedGradingStatusDropdownComponent,
    SharedSemesterDropdownComponent,
  ],
  exports: [
    LibSharedModule,
    PdfViewerModule,
    NgxMaskModule,

    CourseItemComponent,
    CourseHighlightComponent,
    CourseBoxVirtualComponent,
    CourseCardComponent,
    CourseBoxComponent,
    DatetimeTextComponent,
    DatetimeRangeTextComponent,
    EditorComponent,
    PaginationComponent,
    ContentAttachmentListComponent,
    CarouselComponent,
    PriceTextComponent,
    AttemptSelectorComponent,

    SharedGradingStatusDropdownComponent,
    SharedSemesterDropdownComponent,
  ],
})
export class SharedModule { }

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WowzaVideoDTO {
  wowzaVideoId: string;
  wowzaDashUrl: string;
  wowzaHlsUrl: string;
  wowzaHDSUrl: string;
  wowzaSmoothUrl: string;
  wowzaIOSUrl: string;
  wowzaAndroidOtherUrl: string;
}

import { Component, Input, forwardRef, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInput } from '@base/base-input';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutocompleteInputComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent extends BaseInput implements OnInit, OnChanges {

  @HostBinding('class') hostClass = 'base-control';
  @HostBinding('class.is-loading') isLoading: boolean;

  @Input() data$: Observable<any>;
  @Input() optionField: string;
  @Input() minLength = 1;

  value: string;
  isBlur: boolean;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlClass) {
      this.hostClass = `base-control ${this.controlClass}`;
    }
  }

  onBlur(event: Event) {
    super.onBlur(event);
    this.isBlur = true;
  }

  onSelect(item: TypeaheadMatch) {
    this.onInput(item.value);
  }

  onInput(value: string) {
    super.onValueChange(value);
    this.isBlur = false;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Model } from '@api-main';

@Component({
  selector: 'app-course-item',
  templateUrl: './course-item.component.html',
  styleUrls: ['./course-item.component.scss'],
})
export class CourseItemComponent implements OnInit {

  constructor(
    private router: Router) {
  }

  ngOnInit() {
  }

  goTo(courseId: string) {
    this.router.navigate([`/courses/${courseId}`]);
  }

  goToVideo(courseId: string, lectureId: string, videoContentId: string) {
    this.router.navigate([`/courses/${courseId}/lecture/${lectureId}/Video/${videoContentId}`, { role: 'Learner' }]);
  }

}

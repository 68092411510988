<div class="is-flex is-align-center _input-number-range">
  <div class="control has-icons-right">
    <input
      #tooltip="softTooltip"
      class="input"
      type="text"
      [softTooltip]="popContent"
      [trigger]="'click'"
      [theme]="'is-bordered'"
      (softTooltipHidden)="hide()"
      [ngModel]="displayValue"
      [placeholder]="placeholder"
      [disabled]="disabled"
      readonly
    >
    <span
      class="icon is-small is-right is-clickable text-size-xsmall"
      *ngIf="value && value.length && !disabled"
      (click)="onClear()"
    >
      <i class="delete is-small"></i>
    </span>
  </div>
</div>

<ng-template #popContent>
  <form
    #form="ngForm"
    class="_number-range-popover"
    [class.submitted]="form.submitted"
    (ngSubmit)="onSubmit(form)"
  >
    <div class="field is-grouped">
      <div class="control">
        <lib-number-input
          autofocus
          name="fromNumber"
          [(ngModel)]="preValue[0]"
          [decimal]="decimal"
          [negative]="negative"
          [vMax]="preValue[1]"
        ></lib-number-input>
      </div>
      <div class="control">
        <div class="is-control-height-centered">-</div>
      </div>
      <div class="control">
        <lib-number-input
          name="toNumber"
          [(ngModel)]="preValue[1]"
          [decimal]="decimal"
          [negative]="negative"
          [vMin]="preValue[0]"
        ></lib-number-input>
      </div>
      <div class="control">
        <button class="button">OK</button>
      </div>
    </div>
  </form>
</ng-template>
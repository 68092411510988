import { NG_VALIDATORS, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input, forwardRef, OnChanges } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[vUnique][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => UniqueValidatorDirective), multi: true }],
})
export class UniqueValidatorDirective implements OnChanges, Validators {

  @Input() vUnique: any[];

  ngOnChanges() {
    this.onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // valid for empty value
    if (control.value === undefined || control.value === null || control.value === '') {
      return null;
    }

    let isDuplicate = false;
    if (Array.isArray(this.vUnique)) {
      isDuplicate = this.vUnique.some(v => v === control.value);
    }

    if (!isDuplicate) {
      return null;
    }

    return { vUnique: true };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }

  private onChange = () => { };

}

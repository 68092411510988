/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { AllCourseSummaryDTO } from '../model/allCourseSummaryDTO';
import { AssignmentContentDTO } from '../model/assignmentContentDTO';
import { AssignmentContentResultDTO } from '../model/assignmentContentResultDTO';
import { AssignmentContentResultListDTO } from '../model/assignmentContentResultListDTO';
import { AssignmentFileAttachmentDTO } from '../model/assignmentFileAttachmentDTO';
import { AssignmentListDTO } from '../model/assignmentListDTO';
import { AttemptDropdownDTO } from '../model/attemptDropdownDTO';
import { AzureLiveDTO } from '../model/azureLiveDTO';
import { ClassroomVideoDTO } from '../model/classroomVideoDTO';
import { CourseCardDTO } from '../model/courseCardDTO';
import { CourseContentDTO } from '../model/courseContentDTO';
import { CourseDetailDTO } from '../model/courseDetailDTO';
import { CourseDropdownItem } from '../model/courseDropdownItem';
import { CoursePricingDTO } from '../model/coursePricingDTO';
import { CourseRole } from '../model/courseRole';
import { CourseRoleDTO } from '../model/courseRoleDTO';
import { CourseUserRoleDTO } from '../model/courseUserRoleDTO';
import { DiscussionReplyDTO } from '../model/discussionReplyDTO';
import { DiscussionTopicDTO } from '../model/discussionTopicDTO';
import { DiscussionTopicListDTO } from '../model/discussionTopicListDTO';
import { DownloadAttachmentParam } from '../model/downloadAttachmentParam';
import { ExamListDTO } from '../model/examListDTO';
import { FileContentListDTO } from '../model/fileContentListDTO';
import { FileMaterialDTO } from '../model/fileMaterialDTO';
import { GradingStatus } from '../model/gradingStatus';
import { HtmlContentDTO } from '../model/htmlContentDTO';
import { LectureDTO } from '../model/lectureDTO';
import { LectureListDTO } from '../model/lectureListDTO';
import { LectureNameListDTO } from '../model/lectureNameListDTO';
import { MaterialDownloadSelectedDTO } from '../model/materialDownloadSelectedDTO';
import { MyCourseCardDTO } from '../model/myCourseCardDTO';
import { MyCourseSummaryDTO } from '../model/myCourseSummaryDTO';
import { PdfContentDTO } from '../model/pdfContentDTO';
import { PdfContentProgressDTO } from '../model/pdfContentProgressDTO';
import { ReadPageParam } from '../model/readPageParam';
import { UrlContentDTO } from '../model/urlContentDTO';
import { VideoContentDTO } from '../model/videoContentDTO';
import { VideoLiveCardDTO } from '../model/videoLiveCardDTO';
import { VirtualClassroomCourseSummaryDTO } from '../model/virtualClassroomCourseSummaryDTO';


@Injectable({
  providedIn: 'root',
})
export class CoursesService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesAllCoursesSummaryGet$(isOnlyAvailable?: boolean, isRecommend?: boolean, categoryId?: string, semesterId?: string, searchQuery?: string): Observable<AllCourseSummaryDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/AllCoursesSummary`, { isOnlyAvailable, isRecommend, categoryId, semesterId, searchQuery });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdAnswerPost$(id: string, assignmentContentId: string, body?: AssignmentContentDTO, role?: CourseRole, attempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/Answer`, body, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdCheckAssignmentPost$(id: string, assignmentContentId: string, body?: AssignmentContentDTO, learnerUserId?: string, attempt?: number, courseRegisAttempt?: number, role?: CourseRole): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/CheckAssignment`, body, { learnerUserId, attempt, courseRegisAttempt, role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdGet$(id: string, assignmentContentId: string, learnerUserId?: string, role?: CourseRole, attempt?: number): Observable<AssignmentContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}`, { learnerUserId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdReSubmitPost$(id: string, assignmentContentId: string, role?: CourseRole, attempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/ReSubmit`, undefined, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdResultGet$(id: string, assignmentContentId: string, attempt?: number, courseRegisAttempt?: number, learnerUserId?: string): Observable<AssignmentContentResultDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/Result`, { attempt, courseRegisAttempt, learnerUserId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdResultListGet$(id: string, assignmentContentId: string, ref1?: string, fullName?: string, attempt?: number, gradingStatus?: GradingStatus, grade?: string, updatedFrom?: Date, updatedTo?: Date, page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, isOnlyTodo?: boolean, headerResponse?: HeaderResponse): Observable<Array<AssignmentContentResultListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/Result/List`, { ref1, fullName, attempt, gradingStatus, grade, updatedFrom, updatedTo, page, pageSize, sortBy, ascending, isOnlyTodo }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdSubmitPost$(id: string, assignmentContentId: string, role?: CourseRole, attempt?: number): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/Submit`, undefined, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentContentByAssignmentContentIdUploadAttachmentPost$(id: string, assignmentContentId: string, file?: any): Observable<AssignmentFileAttachmentDTO> {
    return this.api.multipartPost(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/AssignmentContent/${encodeURIComponent(String(assignmentContentId))}/UploadAttachment`, { file }, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAssignmentsGet$(id: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<AssignmentListDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Assignments`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdAttemptsDropdownListGet$(id: string, semester?: string): Observable<Array<AttemptDropdownDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Attempts/DropdownList`, { semester });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdChaptersByChapterIdLecturesByLectureIdDelete$(id: string, chapterId: string, lectureId: string, role?: CourseRole): Observable<any> {
    return this.api.delete(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Chapters/${encodeURIComponent(String(chapterId))}/Lectures/${encodeURIComponent(String(lectureId))}`, { role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdChaptersByChapterIdLecturesByLectureIdPut$(id: string, chapterId: string, lectureId: string, body?: LectureDTO, role?: CourseRole): Observable<LectureDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Chapters/${encodeURIComponent(String(chapterId))}/Lectures/${encodeURIComponent(String(lectureId))}`, body, { role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdChaptersByChapterIdLecturesPost$(id: string, chapterId: string, body?: LectureListDTO, role?: CourseRole): Observable<LectureListDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Chapters/${encodeURIComponent(String(chapterId))}/Lectures`, body, { role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdClassroomVideosGet$(id: string, attempt?: number, semesterId?: string, role?: CourseRole): Observable<ClassroomVideoDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/ClassroomVideos`, { attempt, semesterId, role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdContentsGet$(id: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<CourseContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Contents`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdDelete$(id: string, discussionId: string): Observable<DiscussionTopicDTO> {
    return this.api.delete(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdGet$(id: string, discussionId: string, semesterId?: string): Observable<DiscussionTopicDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}`, { semesterId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdPut$(id: string, discussionId: string, body?: DiscussionTopicDTO): Observable<DiscussionTopicDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdDelete$(id: string, discussionId: string, replyId: string): Observable<DiscussionReplyDTO> {
    return this.api.delete(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdMarkAsAnswerPut$(id: string, discussionId: string, replyId: string): Observable<DiscussionReplyDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}/MarkAsAnswer`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdPut$(id: string, discussionId: string, replyId: string, body?: DiscussionReplyDTO): Observable<DiscussionReplyDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdUnMarkAsAnswerPut$(id: string, discussionId: string, replyId: string): Observable<DiscussionReplyDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}/UnMarkAsAnswer`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdVoteDownPut$(id: string, discussionId: string, replyId: string): Observable<DiscussionReplyDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}/VoteDown`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesByReplyIdVoteUpPut$(id: string, discussionId: string, replyId: string): Observable<DiscussionReplyDTO> {
    return this.api.put(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies/${encodeURIComponent(String(replyId))}/VoteUp`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesGet$(id: string, discussionId: string, page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, headerResponse?: HeaderResponse): Observable<Array<DiscussionReplyDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies`, { page, pageSize, sortBy, ascending }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsByDiscussionIdRepliesPost$(id: string, discussionId: string, body?: DiscussionReplyDTO): Observable<DiscussionReplyDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions/${encodeURIComponent(String(discussionId))}/Replies`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsGet$(id: string, page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, lectureId?: string, semesterId?: string, headerResponse?: HeaderResponse): Observable<Array<DiscussionTopicListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions`, { page, pageSize, sortBy, ascending, lectureId, semesterId }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDiscussionsPost$(id: string, body?: DiscussionTopicDTO): Observable<DiscussionTopicDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Discussions`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdDownloadAttachmentPost$(id: string, body?: Array<DownloadAttachmentParam>): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/DownloadAttachment`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdExamsGet$(id: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<ExamListDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Exams`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdFileContentByFileContentIdGet$(id: string, fileContentId: string, role?: CourseRole, attempt?: number): Observable<FileContentListDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/FileContent/${encodeURIComponent(String(fileContentId))}`, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdGet$(id: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<CourseDetailDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdHtmlContentByHtmlContentIdGet$(id: string, htmlContentId: string, role?: CourseRole, attempt?: number): Observable<HtmlContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/HtmlContent/${encodeURIComponent(String(htmlContentId))}`, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdLecturesByLectureIdGet$(id: string, lectureId: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<LectureListDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Lectures/${encodeURIComponent(String(lectureId))}`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdLecturesDropdownListGet$(id: string, semesterId?: string): Observable<Array<LectureNameListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Lectures/DropdownList`, { semesterId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdMaterialsDownloadSelectedPost$(id: string, body?: Array<FileMaterialDTO>): Observable<MaterialDownloadSelectedDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Materials/DownloadSelected`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdMaterialsGet$(id: string, semesterId?: string, role?: CourseRole, attempt?: number): Observable<Array<FileMaterialDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Materials`, { semesterId, role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdMyCourseRoleGet$(id: string): Observable<CourseRoleDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/MyCourseRole`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdPdfContentByPdfContentIdGet$(id: string, pdfContentId: string, role?: CourseRole, attempt?: number): Observable<PdfContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/PdfContent/${encodeURIComponent(String(pdfContentId))}`, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdPdfContentByPdfContentIdReadPagePost$(id: string, pdfContentId: string, body?: ReadPageParam, attempt?: number): Observable<PdfContentProgressDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/PdfContent/${encodeURIComponent(String(pdfContentId))}/ReadPage`, body, { attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdPricingGet$(id: string): Observable<CoursePricingDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Pricing`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdStartPost$(id: string, semesterId?: string): Observable<CourseDetailDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/Start`, undefined, { semesterId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdUrlContentByUrlContentIdGet$(id: string, urlContentId: string, role?: CourseRole, attempt?: number): Observable<UrlContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/UrlContent/${encodeURIComponent(String(urlContentId))}`, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdUserRolesGet$(id: string, semesterId?: string): Observable<Array<CourseUserRoleDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/UserRoles`, { semesterId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdGet$(id: string, videoContentId: string, role?: CourseRole, attempt?: number): Observable<VideoContentDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}`, { role, attempt });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdStartLivePost$(id: string, videoContentId: string): Observable<AzureLiveDTO> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}/StartLive`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdStartVimeoLivePost$(id: string, videoContentId: string): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}/StartVimeoLive`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdStartWowzaLivePost$(id: string, videoContentId: string): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}/StartWowzaLive`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdStopLivePost$(id: string, videoContentId: string): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}/StopLive`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesByIdVideoContentByVideoContentIdStopWowzaLivePost$(id: string, videoContentId: string): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Courses/${encodeURIComponent(String(id))}/VideoContent/${encodeURIComponent(String(videoContentId))}/StopWowzaLive`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesDropdownListGet$(categoryId?: string, name?: string): Observable<Array<CourseDropdownItem>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/DropdownList`, { categoryId, name });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesMyCourseSummaryGet$(categoryId?: string, isInProgress?: boolean, isNotStarted?: boolean, isCompleted?: boolean, semesterId?: string, role?: CourseRole): Observable<MyCourseSummaryDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/MyCourseSummary`, { categoryId, isInProgress, isNotStarted, isCompleted, semesterId, role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesMyCoursesGet$(sortBy?: string, ascending?: boolean, page?: number, pageSize?: number, categoryId?: string, isInProgress?: boolean, isNotStarted?: boolean, isCompleted?: boolean, isRequiredCourse?: boolean, searchQuery?: string, semesterId?: string, role?: CourseRole, headerResponse?: HeaderResponse): Observable<Array<MyCourseCardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/MyCourses`, { sortBy, ascending, page, pageSize, categoryId, isInProgress, isNotStarted, isCompleted, isRequiredCourse, searchQuery, semesterId, role }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesMyCoursesOldGet$(sortBy?: string, ascending?: boolean, page?: number, pageSize?: number, categoryId?: string, isInProgress?: boolean, isNotStarted?: boolean, isCompleted?: boolean, isRequiredCourse?: boolean, searchQuery?: string, semesterId?: string, role?: CourseRole, headerResponse?: HeaderResponse): Observable<Array<MyCourseCardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/MyCourses_old`, { sortBy, ascending, page, pageSize, categoryId, isInProgress, isNotStarted, isCompleted, isRequiredCourse, searchQuery, semesterId, role }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesSearchGet$(page?: number, pageSize?: number, sortBy?: string, ascending?: boolean, isOnlyAvailable?: boolean, isRecommend?: boolean, categoryId?: string, semesterId?: string, searchQuery?: string, headerResponse?: HeaderResponse): Observable<Array<CourseCardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/Search`, { page, pageSize, sortBy, ascending, isOnlyAvailable, isRecommend, categoryId, semesterId, searchQuery }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesUserRolesGet$(semesterId?: string): Observable<Array<CourseUserRoleDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/UserRoles`, { semesterId });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesVirtualClassroomCourseSummaryGet$(role?: CourseRole): Observable<VirtualClassroomCourseSummaryDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/VirtualClassroomCourseSummary`, { role });
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiCoursesVirtualClassroomCoursesGet$(page?: number, pageSize?: number, isRequiredCourse?: boolean, isPublished?: boolean, role?: CourseRole, headerResponse?: HeaderResponse): Observable<Array<VideoLiveCardDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Courses/VirtualClassroomCourses`, { page, pageSize, isRequiredCourse, isPublished, role }, false, headerResponse);
  }
}

declare var window: any;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadTranslations } from '@angular/localize';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { EnvironmentLoader } from '@env';
import { LocaleLoader } from './locale/locale-loader';

if (environment.production) {
  enableProdMode();
}

let appVersion: string | undefined;
if (environment.e2e) {
  appVersion = 'e2e';
}

EnvironmentLoader(appVersion)
  .then(LocaleLoader)
  .then(data => {
    loadTranslations(data.translations);
  })
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });

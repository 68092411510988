import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { GridHeaderWrapperComponent } from './grid-header-wrapper/grid-header-wrapper.component';
import Sortable from 'sortablejs';
import { DatatableComponent, SelectionType, TableColumn, TableColumnProp } from '@swimlane/ngx-datatable';

export interface GridTableColumn<PropType> extends TableColumn {
  frozenLeft?: boolean;
  frozenRight?: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  sortable?: boolean;
  checkboxable?: boolean;
  headerCheckboxable?: boolean;
  prop: (keyof PropType | '_action') & TableColumnProp;
  name?: string;
  cellTemplate?: any;
  headerTemplate?: any;
  headerBooleanFilterTexts?: string[];
  canEdit?: boolean | ((row: any) => boolean);
  canDelete?: boolean | ((row: any) => boolean);
  cellClass?: string | ((data: any) => string | any);
  headerClass?: string | ((data: any) => string | any);
  summaryFunc?: (cells: any[]) => any;
  summaryTemplate?: any;
  placeholder?: string;
  sortBy?: string;
  groupHeader?: {
    name: string;
    align?: 'left' | 'center' | 'right';
    isLast?: boolean;
  };
  sharedHeaderText?: string;
  sharedHeaderAlign?: string;
}

export type FilterType = 'search' | 'equal' | ((row: any, prop: string) => boolean);

export interface FilterTypeObject {
  [prop: string]: FilterType;
}

export interface DataFilter {
  prop: string;
  data: any;
  type: FilterType;
}

export interface Page {
  page: number;
  perPage: number;
  totalCount: number;
}

export interface Sort {
  // column prop; send to ngx-datatable's sorts()
  prop?: string;
  // sortBy field; send to server
  sortBy: any;
  // sortBy direction; send to server
  asc: boolean;
}

export interface RowReOrder<T> {
  row: T;
  oldIndex: number;
  newIndex: number;
}

@Component({
  selector: 'lib-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent<PropType> implements OnInit, OnDestroy {

  @Input() gridClass = '';

  // Loading
  loading: boolean;
  loadingSub: Subscription;
  @Input()
  set dataSub(dataSub: Subscription) {
    this.loadingSub = dataSub;
    if (dataSub) {
      // delay show loading
      const timeout = window.setTimeout(() => {
        this.loading = true;
      }, 300);
      new Promise((resolve) => {
        dataSub.add(resolve);
      }).then(() => {
        window.clearTimeout(timeout);
        this.loading = false;
      }).catch(() => {
        window.clearTimeout(timeout);
        this.loading = false;
      });
    }
  }

  filteredRows: PropType[];
  originalRows: PropType[];
  @Input()
  set rows(rows: PropType[]) {
    if (rows) {
      if (this.groupRowsBy) {
        // clear rowExpansions
        this.dataTable.bodyComponent.rowExpansions = [];
      } else if (this.rowDetailTemplate && this.isExpandAllRows) {
        window.setTimeout(() => {
          this.dataTable.rowDetail.expandAllRows();
        });
      }

      this.filteredRows = rows;
      this.originalRows = [...rows];
      if (this.rowReOrdering) {
        this.setRowReOrderable();
      }
    }
  }

  enableSummary: boolean;
  gridTableColumns: GridTableColumn<any>[];
  @Input() set columns(columns: GridTableColumn<any>[]) {
    if (columns) {
      for (const column of columns) {
        if (column.resizeable === undefined) {
          column.resizeable = false;
        }

        if (this.rowReOrdering || !this.sort) {
          column.sortable = false;
        }
        // else if (column.sortable === undefined && this.externalSorting) {
        //   column.sortable = !!column.sortBy;
        // }

        if (column.headerTemplate) {
          if (column.headerTemplate === this.booleanHeaderWrapper.template && !column.headerBooleanFilterTexts) {
            column.headerBooleanFilterTexts = ['All', 'True', 'False'];
          }
        } else {
          if (this.filter) {
            column.headerTemplate = this.noFilterHeaderWrapper.template;
          } else {
            column.headerTemplate = this.headerWrapper.template;
          }
        }

        if (column.cellTemplate === this.actionTpl) {
          if (column.canEdit === undefined) {
            column.canEdit = (row) => true;
          } else if (typeof column.canEdit === 'boolean') {
            const canEdit: boolean = column.canEdit;
            column.canEdit = (row) => canEdit;
          }
          if (column.canDelete === undefined) {
            column.canDelete = (row) => true;
          } else if (typeof column.canDelete === 'boolean') {
            const canDelete: boolean = column.canDelete;
            column.canDelete = (row) => canDelete as boolean;
          }
        }

        if (column.prop === '_action') {
          if (column.sortable === undefined) {
            column.sortable = false;
          }
        }

        if (column.summaryFunc || column.summaryTemplate) {
          this.enableSummary = true;
        }

      }
      this.gridTableColumns = columns;
    }
  }



  @Input() scrollbarV = false;
  @Input() rowHeight: number | 'auto' = 'auto';
  @Input() headerHeight: number | 'auto' = 'auto';
  @Input() footerHeight: number | 'auto' = 'auto';
  @Input() summaryHeight: number;
  @Input() summaryPosition: 'top' | 'bottom' = 'bottom';

  @Input() externalPaging = false;
  @Input() page: Page;
  perPageOptions = [10, 25, 50, 100];

  @Input() filter: { [key: string]: any };

  @Input() externalSorting = false;
  @Input() sort: Sort;

  @Input() rowReOrdering = false;
  @Input() rowId: any;
  @Input() rowClass: any;
  @Input() rowDetailTemplate: TemplateRef<any>;
  @Input() isExpandAllRows = false;
  @Input() groupRowsBy: string;
  @Input() groupExpansionDefault: boolean;
  @Input() groupRowsTemplate: TemplateRef<any>;
  @Input() selectionType: SelectionType;
  @Input() selected: PropType[] = [];
  @Input() displayCheck: any;

  @Output() rowsChange = new EventEmitter<PropType[]>();
  @Output() pageChange = new EventEmitter<Page>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() filterChange = new EventEmitter<{ [key: string]: any }>();
  @Output() edit = new EventEmitter<PropType>();
  @Output() delete = new EventEmitter<PropType>();
  @Output() visible = new EventEmitter<PropType>();
  @Output() selectedChange = new EventEmitter<PropType[]>();
  @Output() rowReOrderChange = new EventEmitter<RowReOrder<PropType>>();

  @ViewChild('dataTable') dataTable: DatatableComponent;

  // header template
  @ViewChild('headerWrapper', { static: true }) headerWrapper: GridHeaderWrapperComponent;
  @ViewChild('noFilterHeaderWrapper', { static: true }) noFilterHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('searchableHeaderWrapper', { static: true }) searchableHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('startDatePickerHeaderWrapper', { static: true }) startDatePickerHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('endDatePickerHeaderWrapper', { static: true }) endDatePickerHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('dateRangePickerHeaderWrapper', { static: true }) dateRangePickerHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('monthPickerHeaderWrapper', { static: true }) monthPickerHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('booleanHeaderWrapper', { static: true }) booleanHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('inputNumberHeaderWrapper', { static: true }) inputNumberHeaderWrapper: GridHeaderWrapperComponent;
  @ViewChild('inputNumberRangeHeaderWrapper', { static: true }) inputNumberRangeHeaderWrapper: GridHeaderWrapperComponent;

  // cell template
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>;
  @ViewChild('dateTimeTpl', { static: true }) dateTimeTpl: TemplateRef<any>;
  @ViewChild('timeTpl', { static: true }) timeTpl: TemplateRef<any>;
  @ViewChild('integerTpl', { static: true }) integerTpl: TemplateRef<any>;
  @ViewChild('decimalTpl', { static: true }) decimalTpl: TemplateRef<any>;
  @ViewChild('reOrderTpl', { static: true }) reOrderTpl: TemplateRef<any>;
  @ViewChild('checkMarkTpl', { static: true }) checkMarkTpl: TemplateRef<any>;

  private searchTerm$ = new Subject<DataFilter>();
  private pageChange$ = new Subject<Page>();
  private sortChange$ = new Subject<Sort>();
  private destroy$ = new Subject<boolean>();
  private sortablejs: any;

  // workaround to work with sidebar at entering website state
  lazyInitReady = false;

  constructor() { }

  ngOnInit() {
    window.setTimeout(() => {
      this.lazyInitReady = true;
    });

    if (this.groupRowsBy && this.groupExpansionDefault === undefined) {
      this.groupExpansionDefault = true;
    }

    this.searchTerm$
      .pipe(
        map((dataFilter: DataFilter) => {
          if (dataFilter.data && dataFilter.data.trim()) {
            this.filter[dataFilter.prop] = dataFilter.data;
          } else {
            this.filter[dataFilter.prop] = undefined;
          }
          return this.filter;
        }),
        debounceTime(400),
        takeUntil(this.destroy$),
      )
      .subscribe((filter: { [key: string]: any }) => this.filterChange.emit(filter));

    this.pageChange$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$),
      )
      .subscribe((page: Page) => this.pageChange.emit(page));

    this.sortChange$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$),
      )
      .subscribe((sort: Sort) => this.sortChange.emit(sort));
  }

  count() {
    if (this.page) {
      return this.page.totalCount;
    }
    return 0;
  }

  offset() {
    if (this.page) {
      return this.page.page - 1;
    }
    return 0;
  }

  limit() {
    if (this.page) {
      return this.page.perPage;
    }
    return undefined;
  }

  setPage(event: any) {
    const page: Page = {
      page: event.offset + 1,
      perPage: event.limit,
      totalCount: event.count,
    };
    this.pageChange$.next(page);
  }

  setPerPage() {
    this.pageChange$.next(this.page);
  }

  sorts() {
    if (this.sort) {
      return [
        {
          prop: this.sort.prop,
          dir: this.sort.asc ? 'asc' : 'desc',
        },
      ];
    }
    return undefined;
  }

  onSort(event: any) {
    if (!event.sorts) {
      return;
    }
    const sort: Sort = {
      prop: event.sorts[0].prop, // keep prop for sorts() to determine the column's arrow direction
      sortBy: event.column.sortBy || event.sorts[0].prop, // use custom sortBy attr with fallback to prop
      asc: event.sorts[0].dir === 'asc',
    };
    this.sort = sort;
    this.sortChange$.next(sort);
  }

  onSearch(column: any, event: Event) {
    this.searchTerm$.next({
      prop: column.prop,
      data: (event.target as HTMLInputElement).value,
      type: 'search',
    });
  }

  onFilter(column: any) {
    this.filterChange.emit(this.filter);
  }

  onSelect({ selected }: { selected: any }) {
    if (selected) {
      this.selected.splice(0, this.selected.length);
      if (this.displayCheck) {
        this.selected.push(...selected.filter((row: any) => this.displayCheck(row)));
      } else {
        this.selected.push(...selected);
      }
      this.selectedChange.emit(this.selected);
    }
  }

  rowIdentity = (row: any) => {
    if (this.groupRowsBy) {
      // each group in groupedRows are stored as {key, value: [rows]},
      return row.key;
    }
    if (this.rowId) {
      return row[this.rowId];
    }
    return row;
    // tslint:disable-next-line:semicolon
  };

  selectCheck = (row: any, column: any, value: any) => {
    return true;
    // tslint:disable-next-line:semicolon
  };

  // TODO: better way for multi fields search
  // Example Usage:
  // filterChange() {
  //   this.grid.internalFilter(this.filter, {
  //     ref1: 'search',
  //     firstName: (row, prop) => {
  //       return row.firstName.toLowerCase().indexOf(this.filter[prop]) !== -1
  //         || row.lastName.toLowerCase().indexOf(this.filter[prop]) !== -1
  //         || !this.filter[prop]
  //     },
  //   });
  // }

  internalFilter(
    filter: { [prop: string]: any },
    filterType: FilterTypeObject,
  ) {
    let temp = this.originalRows;
    const props = Object.keys(filterType);
    for (const prop of props) {
      temp = temp.filter(row => {
        if (filterType[prop] === 'search') {
          if (!row[prop]) {
            return false;
          }
          return (
            row[prop].toLowerCase().indexOf(filter[prop]) !== -1 ||
            !filter[prop]
          );
        }
        if (filterType[prop] === 'equal') {
          return row[prop] === filter[prop] || !filter[prop];
        }
        if (typeof filterType[prop] === 'function') {
          return (filterType[prop] as (row: any, prop: string) => any)(row, prop);
        }
      });
    }
    this.filteredRows = temp;
  }

  setRowReOrderable() {
    window.setTimeout(() => {
      const el = document.getElementsByTagName('datatable-scroller')[0] as HTMLElement;

      if (!el) {
        return;
      }

      if (this.sortablejs) {
        this.sortablejs.destroy();
      }

      this.sortablejs = new Sortable(el, {
        draggable: '.datatable-row-wrapper',
        handle: '.reorder-handle',
        onEnd: (evt: any) => {
          // reorder by dom
          const itemDom = evt.item;
          if (evt.oldIndex > evt.newIndex) {
            itemDom.parentElement.insertBefore(itemDom, evt.target.children[evt.oldIndex].nextElementSibling);
          } else {
            itemDom.parentElement.insertBefore(itemDom, evt.target.children[evt.oldIndex]);
          }

          // reorder by data structure
          const itemObject = this.filteredRows.splice(evt.oldIndex, 1)[0];
          this.filteredRows.splice(evt.newIndex, 0, itemObject);
          this.filteredRows = [...this.filteredRows];

          // find target id
          // const rowClasses = item.children[0].className;
          // const generatedClassPrefix = getRowClass({ id: '' });
          // const id = rowClasses.substr(rowClasses.indexOf(generatedClassPrefix) + generatedClassPrefix.length);

          this.rowReOrderChange.emit({
            row: itemObject,
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex,
          });

          this.rowsChange.emit(this.filteredRows);
        },
      });
    });
  }

  reCalculate() {
    this.dataTable.recalculate();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { BillingAddressDTO } from '../model/billingAddressDTO';
import { CancelCourseOrderDTO } from '../model/cancelCourseOrderDTO';
import { CourseOrderDTO } from '../model/courseOrderDTO';
import { CoursePurchaseDTO } from '../model/coursePurchaseDTO';
import { OmisePaymentTransaction } from '../model/omisePaymentTransaction';
import { PurchaseHistory } from '../model/purchaseHistory';
import { PurchaseHistoryStatus } from '../model/purchaseHistoryStatus';


@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentBillingAddressGet$(): Observable<BillingAddressDTO> {
    return this.api.get(`${apiBaseUrl()}/api/Payment/BillingAddress`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentPurchaseByIdCancelPost$(id: string, body?: CancelCourseOrderDTO): Observable<Array<CourseOrderDTO>> {
    return this.api.post(`${apiBaseUrl()}/api/Payment/Purchase/${encodeURIComponent(String(id))}/cancel`, body, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentPurchaseByIdConfirmPost$(id: string): Observable<Array<CourseOrderDTO>> {
    return this.api.post(`${apiBaseUrl()}/api/Payment/Purchase/${encodeURIComponent(String(id))}/confirm`, undefined, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentPurchaseByIdGet$(id: string): Observable<Array<CourseOrderDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/Payment/Purchase/${encodeURIComponent(String(id))}`, undefined);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentPurchaseHistoryGet$(purchaseHistoryStatus?: PurchaseHistoryStatus, page?: number, pageSize?: number, headerResponse?: HeaderResponse): Observable<Array<PurchaseHistory>> {
    return this.api.get(`${apiBaseUrl()}/api/Payment/Purchase/History`, { purchaseHistoryStatus, page, pageSize }, false, headerResponse);
  }
  /**
   * [Authorize] type: apiKey
   */
  public apiPaymentPurchasePost$(body?: CoursePurchaseDTO): Observable<Array<CourseOrderDTO>> {
    return this.api.post(`${apiBaseUrl()}/api/Payment/Purchase`, body, undefined);
  }
  /**
   */
  public apiPaymentPushchaseChargesPost$(body?: OmisePaymentTransaction): Observable<any> {
    return this.api.post(`${apiBaseUrl()}/api/Payment/Pushchase/Charges`, body, undefined, true);
  }
}

import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

export interface Tab {
  id: string;
  icon?: string;
  name: string;
  disabled?: boolean;
  stat?: number;
  can?: boolean;
}

@Component({
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnChanges {

  @Input() tabs: Tab[];
  @Input() activeTabId: string;
  @Input() isInsideModal = false;
  @Input() replaceUrl = false;
  @Input() tabClass = '';
  @Output() activeTabIdChange = new EventEmitter<string>();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs) {
      this.tabs = this.tabs.filter(m => m.can || m.can === undefined);
      this.cdr.markForCheck();
    }
  }

  changeTab(tab: Tab) {
    this.activeTabId = tab.id;
    if (!this.isInsideModal) {
      this.router.navigate([], { replaceUrl: this.replaceUrl, fragment: tab.id });
    }
    this.activeTabIdChange.emit(tab.id);
  }

}

/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { SoftApiClientService, HeaderResponse } from 'soft-ngx';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../api-base-url';

import { CourseCategoryNameListDTO } from '../model/courseCategoryNameListDTO';


@Injectable({
  providedIn: 'root',
})
export class CourseCategoriesService {

  constructor(
    protected api: SoftApiClientService) {
  }

  /**
   * [Authorize] type: apiKey
   */
  public apiCourseCategoriesDropdownListGet$(): Observable<Array<CourseCategoryNameListDTO>> {
    return this.api.get(`${apiBaseUrl()}/api/CourseCategories/DropdownList`, undefined);
  }
}

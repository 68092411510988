import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[libShortLoading]',
})
export class ShortLoadingDirective {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  @HostListener('click', ['$event'])
  public onClick(e: Event) {
    this.renderer.addClass(this.elementRef.nativeElement, 'is-loading');
    window.setTimeout(() => {
      this.renderer.removeClass(this.elementRef.nativeElement, 'is-loading');
    }, 500);
  }
}

<!-- *softSkel="loadingSub; type: 'table'" -->
<ngx-datatable
  #dataTable
  *ngIf="lazyInitReady"
  [loadingIndicator]="loading"
  [ngClass]="['material striped', gridClass]"
  [columnMode]="'force'"
  [scrollbarV]="scrollbarV"
  [scrollbarH]="true"
  [rows]="filteredRows"
  [columns]="gridTableColumns"
  [headerHeight]=" $any(headerHeight)"
  [footerHeight]="$any(footerHeight)"
  [summaryRow]="enableSummary"
  [summaryHeight]="summaryHeight"
  [summaryPosition]="summaryPosition"
  [rowHeight]="rowHeight"
  [rowClass]="rowClass"
  [reorderable]="false"
  [rowIdentity]="rowIdentity"
  [groupRowsBy]="groupRowsBy"
  [groupExpansionDefault]="groupExpansionDefault"
  [selected]="selected"
  [selectionType]="selectionType"
  [selectCheck]="selectCheck"
  [displayCheck]="displayCheck"
  (select)="onSelect($event)"
  [externalPaging]="externalPaging"
  [count]="count()"
  [offset]="offset()"
  [limit]="limit()"
  (page)="setPage($event)"
  [externalSorting]="externalSorting"
  [sorts]="$any(sorts())"
  (sort)="onSort($event)"
>
  <ngx-datatable-group-header *ngIf="groupRowsBy">
    <ng-template
      let-group="group"
      let-expanded="expanded"
      ngx-datatable-group-header-template
    >
      <ng-container
        *ngIf="groupRowsTemplate else groupRowsDefaultTemplate"
        [ngTemplateOutlet]="groupRowsTemplate"
        [ngTemplateOutletContext]="{ group: group, expanded: expanded, groupHeader: dataTable.groupHeader }"
      >
      </ng-container>

      <ng-template #groupRowsDefaultTemplate>
        <div class="_group-rows-default-template">
          <b>{{ group.key }}</b>
        </div>
      </ng-template>
    </ng-template>

    <!-- Group Rows Custom Template Example -->
    <!--
      <ng-template
        #groupRowsTemplate
        let-group="group"
        let-expanded="expanded"
        let-groupHeader="groupHeader"
      >
        <div style="padding-left:5px;">
          <a
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Group"
            (click)="groupHeader.toggleExpandGroup(group)"
          >
            <b>Color: {{ group.value[0].color }}</b>
          </a>
        </div>
      </ng-template>
    -->
  </ngx-datatable-group-header>

  <!-- Row Detail Template -->
  <ngx-datatable-row-detail
    *ngIf="rowDetailTemplate"
    [rowHeight]="$any('auto')"
  >
    <ng-template
      let-row="row"
      let-expanded="expanded"
      ngx-datatable-row-detail-template
    >
      <ng-container
        [ngTemplateOutlet]="rowDetailTemplate"
        [ngTemplateOutletContext]="{ row: row, expanded: expanded }"
      >
      </ng-container>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <div class="page-count">
        <div class="is-inline-block push-right-2">
          {{ rowCount?.toLocaleString() }} total
        </div>

        <!-- <div
          class="select is-vertical-baseline"
          *ngIf="page"
        >
          <select 
            [(ngModel)]="page.perPage"
            (ngModelChange)="setPerPage()"
          >
            <option
              *ngFor="let perPageOption of perPageOptions"
              [value]="perPageOption"
            >
              {{ perPageOption }} per page
            </option>
          </select>
        </div> -->
      </div>

      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="!((rowCount / pageSize) > 1)"
        (change)="dataTable.onFooterPage($event)"
      >
      </datatable-pager>


    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<!-- header template -->
<lib-grid-header-wrapper #headerWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #noFilterHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #searchableHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <input
        type="text"
        class="input"
        placeholder="{{ column.placeholder }}"
        [ngModel]="filter[column.prop]"
        (input)="onSearch(column, $event)"
        (select)="$event.stopPropagation()"
      />
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #startDatePickerHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-date-picker
        [(ngModel)]="filter[column.prop]"
        (ngModelChange)="onFilter(column)"
      ></lib-date-picker>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #endDatePickerHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-date-picker
        [(ngModel)]="filter[column.prop]"
        [isEndOfDay]="true"
        (ngModelChange)="onFilter(column)"
      ></lib-date-picker>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #dateRangePickerHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-date-range-picker
        [(ngModel)]="filter[column.prop]"
        (ngModelChange)="onFilter(column)"
      ></lib-date-range-picker>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #monthPickerHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-date-picker
        [monthOnly]="true"
        [format]="'YYYY MMMM'"
        [(ngModel)]="filter[column.prop]"
        (ngModelChange)="onFilter(column)"
      ></lib-date-picker>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #inputNumberHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-number-input
        [(ngModel)]="filter[column.prop]"
        (ngModelChange)="onFilter(column)"
      ></lib-number-input>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #inputNumberRangeHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <lib-number-range-popover
        [(ngModel)]="filter[column.prop]"
        (ngModelChange)="onFilter(column)"
      ></lib-number-range-popover>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<lib-grid-header-wrapper #booleanHeaderWrapper>
  <ng-template
    #contentTemplate
    let-column
  >
    <div class="control">
      <div class="select is-fullwidth">
        <select
          [(ngModel)]="filter[column.prop]"
          (change)="onFilter(column)"
        >
          <option [ngValue]="undefined">{{ column.headerBooleanFilterTexts[0] }}</option>
          <option [ngValue]="true">{{ column.headerBooleanFilterTexts[1] }}</option>
          <option [ngValue]="false">{{ column.headerBooleanFilterTexts[2] }}</option>
        </select>
      </div>
    </div>
  </ng-template>
</lib-grid-header-wrapper>

<!-- cell template -->

<ng-template
  #dateTpl
  let-value="value"
>
  {{ value | aDate }}
</ng-template>

<ng-template
  #dateTimeTpl
  let-value="value"
>
  {{ value | aDateTime }}
</ng-template>

<ng-template
  #timeTpl
  let-value="value"
>
  {{ value | aTime }}
</ng-template>

<ng-template
  #integerTpl
  let-value="value"
>
  <div class="text-right">
    {{ value | anInteger }}
  </div>
</ng-template>

<ng-template
  #decimalTpl
  let-value="value"
>
  <div class="text-right">
    {{ value | aDecimal }}
  </div>
</ng-template>

<ng-template
  #reOrderTpl
  let-value="value"
>
  <div
    class="is-flex is-justify-center"
    style="line-height: 1.5"
  >
    <span class="reorder-handle text-strong">
      <span></span>
      <span></span>
      <span></span>
    </span>
  </div>
</ng-template>

<ng-template
  #checkMarkTpl
  let-value="value"
>
  <div class="text-center">
    {{ value ? '✓' : '' }}
  </div>
</ng-template>

<ng-template
  #actionTpl
  let-row="row"
  let-column="column"
>
  <div class="is-flex is-justify-around">
    <a
      *ngIf="column.canEdit(row)"
      class="icon is-inline-block"
      (click)="edit.emit(row)"
    ><i class="ic ic-edit-square"></i></a>
    <a
      *ngIf="column.canDelete(row)"
      class="icon is-inline-block"
      (click)="delete.emit(row)"
    ><i class="ic ic-trash"></i></a>
  </div>
</ng-template>
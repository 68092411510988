import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe as DecimalPipeBase } from '@angular/common';

@Pipe({ name: 'aDecimal' })
export class DecimalPipe extends DecimalPipeBase implements PipeTransform {
  constructor() {
    super('en-US');
  }

  transform(value: number | string, fromPlace?: string, toPlace?: string): string | null;
  transform(value: null | undefined, fromPlace?: string, toPlace?: string): null;
  transform(value: number | string | null | undefined, fromPlace = '2', toPlace?: string): string | null {
    toPlace = toPlace || fromPlace;
    return super.transform(value, `1.${fromPlace}-${toPlace}`);
  }
}

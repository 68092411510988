import { Injectable } from '@angular/core';
import {
  Router, CanActivate, CanActivateChild,
  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RegisterGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.authService.feature.learnerRegister) {
      return true;
    }
    return this.router.createUrlTree(['/']);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(route, state);
  }
}

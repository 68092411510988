<div
  class="select is-fullwidth {{ controlClass }}"
  [class.is-loading]="loading"
>
  <select
    [class.show-placeholder]="value === undefined && showPlaceHolder"
    [(ngModel)]="value"
    [compareWith]="compareWith"
    [disabled]="disabled"
    (change)="onSelect()"
    (blur)="onBlur($event)"
  >
    <option
      *ngIf="showPlaceHolder"
      [disabled]="true"
      [hidden]="true"
      [ngValue]="undefined"
    >
      {{ placeholder }}
    </option>
    <option
      *ngIf="_showAll"
      [ngValue]="showAllValue"
    >
      {{ showAllText }}
    </option>
    <option
      *ngFor="let option of optionList"
      [ngValue]="option"
    >{{ displayOption(option) }}</option>
  </select>
</div>
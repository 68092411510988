export interface MockDTO {
  id: string;
  fieldString: string;
  fieldString0: string;
  fieldString1: string;
  fieldString2: string;
  fieldString3: string;
  fieldString4: string;
  fieldString5: string;
  fieldString6: string;
  fieldString7: string;
  fieldString8: string;
  fieldString9: string;
  fieldString10: string;
  fieldString11: string;
  fieldString12: string;
  fieldString13: string;
  fieldString14: string;
  fieldString15: string;
  fieldString16: string;
  fieldString17: string;
  fieldString18: string;
  fieldString19: string;
  fieldString20: string;
  fieldNumber: number;
  fieldNumber0: number;
  fieldNumber1: number;
  fieldNumber2: number;
  fieldNumber3: number;
  fieldNumber4: number;
  fieldNumber5: number;
  fieldNumber6: number;
  fieldNumber7: number;
  fieldNumber8: number;
  fieldNumber9: number;
  fieldNumber10: number;
  fieldNumber11: number;
  fieldNumber12: number;
  fieldNumber13: number;
  fieldNumber14: number;
  fieldNumber15: number;
  fieldNumber16: number;
  fieldNumber17: number;
  fieldNumber18: number;
  fieldNumber19: number;
  fieldNumber20: number;
  fieldBoolean: boolean;
  fieldBoolean0: boolean;
  fieldBoolean1: boolean;
  fieldBoolean2: boolean;
  fieldBoolean3: boolean;
  fieldBoolean4: boolean;
  fieldBoolean5: boolean;
  fieldBoolean6: boolean;
  fieldBoolean7: boolean;
  fieldBoolean8: boolean;
  fieldBoolean9: boolean;
  fieldBoolean10: boolean;
  fieldBoolean11: boolean;
  fieldBoolean12: boolean;
  fieldBoolean13: boolean;
  fieldBoolean14: boolean;
  fieldBoolean15: boolean;
  fieldBoolean16: boolean;
  fieldBoolean17: boolean;
  fieldBoolean18: boolean;
  fieldBoolean19: boolean;
  fieldBoolean20: boolean;
  fieldDate: Date;
  fieldDate0: Date;
  fieldDate1: Date;
  fieldDate2: Date;
  fieldDate3: Date;
  fieldDate4: Date;
  fieldDate5: Date;
  fieldDate6: Date;
  fieldDate7: Date;
  fieldDate8: Date;
  fieldDate9: Date;
  fieldDate10: Date;
  fieldDate11: Date;
  fieldDate12: Date;
  fieldDate13: Date;
  fieldDate14: Date;
  fieldDate15: Date;
  fieldDate16: Date;
  fieldDate17: Date;
  fieldDate18: Date;
  fieldDate19: Date;
  fieldDate20: Date;
  fieldObject: MockDTO;
  fieldObject0: MockDTO;
  fieldObject1: MockDTO;
  fieldObject2: MockDTO;
  fieldObject3: MockDTO;
  fieldObject4: MockDTO;
  fieldObject5: MockDTO;
  fieldObject6: MockDTO;
  fieldObject7: MockDTO;
  fieldObject8: MockDTO;
  fieldObject9: MockDTO;
  fieldObject10: MockDTO;
  fieldObject11: MockDTO;
  fieldObject12: MockDTO;
  fieldObject13: MockDTO;
  fieldObject14: MockDTO;
  fieldObject15: MockDTO;
  fieldObject16: MockDTO;
  fieldObject17: MockDTO;
  fieldObject18: MockDTO;
  fieldObject19: MockDTO;
  fieldObject20: MockDTO;
  fieldArray: MockDTO[];
  fieldArray0: MockDTO[];
  fieldArray1: MockDTO[];
  fieldArray2: MockDTO[];
  fieldArray3: MockDTO[];
  fieldArray4: MockDTO[];
  fieldArray5: MockDTO[];
  fieldArray6: MockDTO[];
  fieldArray7: MockDTO[];
  fieldArray8: MockDTO[];
  fieldArray9: MockDTO[];
  fieldArray10: MockDTO[];
  fieldArray11: MockDTO[];
  fieldArray12: MockDTO[];
  fieldArray13: MockDTO[];
  fieldArray14: MockDTO[];
  fieldArray15: MockDTO[];
  fieldArray16: MockDTO[];
  fieldArray17: MockDTO[];
  fieldArray18: MockDTO[];
  fieldArray19: MockDTO[];
  fieldArray20: MockDTO[];
}

export let mockChild: MockDTO = {
  id: '199',
  fieldString: 'Lorem ipsum dolor sit amet 199',
  fieldString0: 'Lorem ipsum dolor sit amet 199',
  fieldString1: 'Lorem ipsum dolor sit amet 199',
  fieldString2: 'Lorem ipsum dolor sit amet 199',
  fieldString3: 'Lorem ipsum dolor sit amet 199',
  fieldString4: 'Lorem ipsum dolor sit amet 199',
  fieldString5: 'Lorem ipsum dolor sit amet 199',
  fieldString6: 'Lorem ipsum dolor sit amet 199',
  fieldString7: 'Lorem ipsum dolor sit amet 199',
  fieldString8: 'Lorem ipsum dolor sit amet 199',
  fieldString9: 'Lorem ipsum dolor sit amet 199',
  fieldString10: 'Lorem ipsum dolor sit amet 199',
  fieldString11: 'Lorem ipsum dolor sit amet 199',
  fieldString12: 'Lorem ipsum dolor sit amet 199',
  fieldString13: 'Lorem ipsum dolor sit amet 199',
  fieldString14: 'Lorem ipsum dolor sit amet 199',
  fieldString15: 'Lorem ipsum dolor sit amet 199',
  fieldString16: 'Lorem ipsum dolor sit amet 199',
  fieldString17: 'Lorem ipsum dolor sit amet 199',
  fieldString18: 'Lorem ipsum dolor sit amet 199',
  fieldString19: 'Lorem ipsum dolor sit amet 199',
  fieldString20: 'Lorem ipsum dolor sit amet 199',
  fieldNumber: 1999,
  fieldNumber0: 1999,
  fieldNumber1: 1999,
  fieldNumber2: 1999,
  fieldNumber3: 1999,
  fieldNumber4: 1999,
  fieldNumber5: 1999,
  fieldNumber6: 1999,
  fieldNumber7: 1999,
  fieldNumber8: 1999,
  fieldNumber9: 1999,
  fieldNumber10: 1999,
  fieldNumber11: 1999,
  fieldNumber12: 1999,
  fieldNumber13: 1999,
  fieldNumber14: 1999,
  fieldNumber15: 1999,
  fieldNumber16: 1999,
  fieldNumber17: 1999,
  fieldNumber18: 1999,
  fieldNumber19: 1999,
  fieldNumber20: 1999,
  fieldBoolean: true,
  fieldBoolean0: true,
  fieldBoolean1: true,
  fieldBoolean2: true,
  fieldBoolean3: true,
  fieldBoolean4: true,
  fieldBoolean5: true,
  fieldBoolean6: true,
  fieldBoolean7: true,
  fieldBoolean8: true,
  fieldBoolean9: true,
  fieldBoolean10: true,
  fieldBoolean11: true,
  fieldBoolean12: true,
  fieldBoolean13: true,
  fieldBoolean14: true,
  fieldBoolean15: true,
  fieldBoolean16: true,
  fieldBoolean17: true,
  fieldBoolean18: true,
  fieldBoolean19: true,
  fieldBoolean20: true,
  fieldDate: new Date(),
  fieldDate0: new Date(),
  fieldDate1: new Date(),
  fieldDate2: new Date(),
  fieldDate3: new Date(),
  fieldDate4: new Date(),
  fieldDate5: new Date(),
  fieldDate6: new Date(),
  fieldDate7: new Date(),
  fieldDate8: new Date(),
  fieldDate9: new Date(),
  fieldDate10: new Date(),
  fieldDate11: new Date(),
  fieldDate12: new Date(),
  fieldDate13: new Date(),
  fieldDate14: new Date(),
  fieldDate15: new Date(),
  fieldDate16: new Date(),
  fieldDate17: new Date(),
  fieldDate18: new Date(),
  fieldDate19: new Date(),
  fieldDate20: new Date(),
  fieldObject: {} as MockDTO,
  fieldObject0: {} as MockDTO,
  fieldObject1: {} as MockDTO,
  fieldObject2: {} as MockDTO,
  fieldObject3: {} as MockDTO,
  fieldObject4: {} as MockDTO,
  fieldObject5: {} as MockDTO,
  fieldObject6: {} as MockDTO,
  fieldObject7: {} as MockDTO,
  fieldObject8: {} as MockDTO,
  fieldObject9: {} as MockDTO,
  fieldObject10: {} as MockDTO,
  fieldObject11: {} as MockDTO,
  fieldObject12: {} as MockDTO,
  fieldObject13: {} as MockDTO,
  fieldObject14: {} as MockDTO,
  fieldObject15: {} as MockDTO,
  fieldObject16: {} as MockDTO,
  fieldObject17: {} as MockDTO,
  fieldObject18: {} as MockDTO,
  fieldObject19: {} as MockDTO,
  fieldObject20: {} as MockDTO,
  fieldArray: [{}, {}] as MockDTO[],
  fieldArray0: [{}, {}] as MockDTO[],
  fieldArray1: [{}, {}] as MockDTO[],
  fieldArray2: [{}, {}] as MockDTO[],
  fieldArray3: [{}, {}] as MockDTO[],
  fieldArray4: [{}, {}] as MockDTO[],
  fieldArray5: [{}, {}] as MockDTO[],
  fieldArray6: [{}, {}] as MockDTO[],
  fieldArray7: [{}, {}] as MockDTO[],
  fieldArray8: [{}, {}] as MockDTO[],
  fieldArray9: [{}, {}] as MockDTO[],
  fieldArray10: [{}, {}] as MockDTO[],
  fieldArray11: [{}, {}] as MockDTO[],
  fieldArray12: [{}, {}] as MockDTO[],
  fieldArray13: [{}, {}] as MockDTO[],
  fieldArray14: [{}, {}] as MockDTO[],
  fieldArray15: [{}, {}] as MockDTO[],
  fieldArray16: [{}, {}] as MockDTO[],
  fieldArray17: [{}, {}] as MockDTO[],
  fieldArray18: [{}, {}] as MockDTO[],
  fieldArray19: [{}, {}] as MockDTO[],
  fieldArray20: [{}, {}] as MockDTO[],
};

export let mockChild2: MockDTO = {
  id: '299',
  fieldString: 'Lorem ipsum dolor sit amet 299',
  fieldString0: 'Lorem ipsum dolor sit amet 299',
  fieldString1: 'Lorem ipsum dolor sit amet 299',
  fieldString2: 'Lorem ipsum dolor sit amet 299',
  fieldString3: 'Lorem ipsum dolor sit amet 299',
  fieldString4: 'Lorem ipsum dolor sit amet 299',
  fieldString5: 'Lorem ipsum dolor sit amet 299',
  fieldString6: 'Lorem ipsum dolor sit amet 299',
  fieldString7: 'Lorem ipsum dolor sit amet 299',
  fieldString8: 'Lorem ipsum dolor sit amet 299',
  fieldString9: 'Lorem ipsum dolor sit amet 299',
  fieldString10: 'Lorem ipsum dolor sit amet 299',
  fieldString11: 'Lorem ipsum dolor sit amet 299',
  fieldString12: 'Lorem ipsum dolor sit amet 299',
  fieldString13: 'Lorem ipsum dolor sit amet 299',
  fieldString14: 'Lorem ipsum dolor sit amet 299',
  fieldString15: 'Lorem ipsum dolor sit amet 299',
  fieldString16: 'Lorem ipsum dolor sit amet 299',
  fieldString17: 'Lorem ipsum dolor sit amet 299',
  fieldString18: 'Lorem ipsum dolor sit amet 299',
  fieldString19: 'Lorem ipsum dolor sit amet 299',
  fieldString20: 'Lorem ipsum dolor sit amet 299',
  fieldNumber: 2999,
  fieldNumber0: 2999,
  fieldNumber1: 2999,
  fieldNumber2: 2999,
  fieldNumber3: 2999,
  fieldNumber4: 2999,
  fieldNumber5: 2999,
  fieldNumber6: 2999,
  fieldNumber7: 2999,
  fieldNumber8: 2999,
  fieldNumber9: 2999,
  fieldNumber10: 2999,
  fieldNumber11: 2999,
  fieldNumber12: 2999,
  fieldNumber13: 2999,
  fieldNumber14: 2999,
  fieldNumber15: 2999,
  fieldNumber16: 2999,
  fieldNumber17: 2999,
  fieldNumber18: 2999,
  fieldNumber19: 2999,
  fieldNumber20: 2999,
  fieldBoolean: true,
  fieldBoolean0: true,
  fieldBoolean1: true,
  fieldBoolean2: true,
  fieldBoolean3: true,
  fieldBoolean4: true,
  fieldBoolean5: true,
  fieldBoolean6: true,
  fieldBoolean7: true,
  fieldBoolean8: true,
  fieldBoolean9: true,
  fieldBoolean10: true,
  fieldBoolean11: true,
  fieldBoolean12: true,
  fieldBoolean13: true,
  fieldBoolean14: true,
  fieldBoolean15: true,
  fieldBoolean16: true,
  fieldBoolean17: true,
  fieldBoolean18: true,
  fieldBoolean19: true,
  fieldBoolean20: true,
  fieldDate: new Date(),
  fieldDate0: new Date(),
  fieldDate1: new Date(),
  fieldDate2: new Date(),
  fieldDate3: new Date(),
  fieldDate4: new Date(),
  fieldDate5: new Date(),
  fieldDate6: new Date(),
  fieldDate7: new Date(),
  fieldDate8: new Date(),
  fieldDate9: new Date(),
  fieldDate10: new Date(),
  fieldDate11: new Date(),
  fieldDate12: new Date(),
  fieldDate13: new Date(),
  fieldDate14: new Date(),
  fieldDate15: new Date(),
  fieldDate16: new Date(),
  fieldDate17: new Date(),
  fieldDate18: new Date(),
  fieldDate19: new Date(),
  fieldDate20: new Date(),
  fieldObject: {} as MockDTO,
  fieldObject0: {} as MockDTO,
  fieldObject1: {} as MockDTO,
  fieldObject2: {} as MockDTO,
  fieldObject3: {} as MockDTO,
  fieldObject4: {} as MockDTO,
  fieldObject5: {} as MockDTO,
  fieldObject6: {} as MockDTO,
  fieldObject7: {} as MockDTO,
  fieldObject8: {} as MockDTO,
  fieldObject9: {} as MockDTO,
  fieldObject10: {} as MockDTO,
  fieldObject11: {} as MockDTO,
  fieldObject12: {} as MockDTO,
  fieldObject13: {} as MockDTO,
  fieldObject14: {} as MockDTO,
  fieldObject15: {} as MockDTO,
  fieldObject16: {} as MockDTO,
  fieldObject17: {} as MockDTO,
  fieldObject18: {} as MockDTO,
  fieldObject19: {} as MockDTO,
  fieldObject20: {} as MockDTO,
  fieldArray: [{}, {}] as MockDTO[],
  fieldArray0: [{}, {}] as MockDTO[],
  fieldArray1: [{}, {}] as MockDTO[],
  fieldArray2: [{}, {}] as MockDTO[],
  fieldArray3: [{}, {}] as MockDTO[],
  fieldArray4: [{}, {}] as MockDTO[],
  fieldArray5: [{}, {}] as MockDTO[],
  fieldArray6: [{}, {}] as MockDTO[],
  fieldArray7: [{}, {}] as MockDTO[],
  fieldArray8: [{}, {}] as MockDTO[],
  fieldArray9: [{}, {}] as MockDTO[],
  fieldArray10: [{}, {}] as MockDTO[],
  fieldArray11: [{}, {}] as MockDTO[],
  fieldArray12: [{}, {}] as MockDTO[],
  fieldArray13: [{}, {}] as MockDTO[],
  fieldArray14: [{}, {}] as MockDTO[],
  fieldArray15: [{}, {}] as MockDTO[],
  fieldArray16: [{}, {}] as MockDTO[],
  fieldArray17: [{}, {}] as MockDTO[],
  fieldArray18: [{}, {}] as MockDTO[],
  fieldArray19: [{}, {}] as MockDTO[],
  fieldArray20: [{}, {}] as MockDTO[],
};

export let mock: MockDTO = {
  id: '1',
  fieldString: 'Lorem ipsum dolor sit amet 1',
  fieldString0: 'Lorem ipsum dolor sit amet 1',
  fieldString1: 'Lorem ipsum dolor sit amet 1',
  fieldString2: 'Lorem ipsum dolor sit amet 1',
  fieldString3: 'Lorem ipsum dolor sit amet 1',
  fieldString4: 'Lorem ipsum dolor sit amet 1',
  fieldString5: 'Lorem ipsum dolor sit amet 1',
  fieldString6: 'Lorem ipsum dolor sit amet 1',
  fieldString7: 'Lorem ipsum dolor sit amet 1',
  fieldString8: 'Lorem ipsum dolor sit amet 1',
  fieldString9: 'Lorem ipsum dolor sit amet 1',
  fieldString10: 'Lorem ipsum dolor sit amet 1',
  fieldString11: 'Lorem ipsum dolor sit amet 1',
  fieldString12: 'Lorem ipsum dolor sit amet 1',
  fieldString13: 'Lorem ipsum dolor sit amet 1',
  fieldString14: 'Lorem ipsum dolor sit amet 1',
  fieldString15: 'Lorem ipsum dolor sit amet 1',
  fieldString16: 'Lorem ipsum dolor sit amet 1',
  fieldString17: 'Lorem ipsum dolor sit amet 1',
  fieldString18: 'Lorem ipsum dolor sit amet 1',
  fieldString19: 'Lorem ipsum dolor sit amet 1',
  fieldString20: 'Lorem ipsum dolor sit amet 1',
  fieldNumber: 1234,
  fieldNumber0: 1234,
  fieldNumber1: 1234,
  fieldNumber2: 1234,
  fieldNumber3: 1234,
  fieldNumber4: 1234,
  fieldNumber5: 1234,
  fieldNumber6: 1234,
  fieldNumber7: 1234,
  fieldNumber8: 1234,
  fieldNumber9: 1234,
  fieldNumber10: 1234,
  fieldNumber11: 1234,
  fieldNumber12: 1234,
  fieldNumber13: 1234,
  fieldNumber14: 1234,
  fieldNumber15: 1234,
  fieldNumber16: 1234,
  fieldNumber17: 1234,
  fieldNumber18: 1234,
  fieldNumber19: 1234,
  fieldNumber20: 1234,
  fieldBoolean: true,
  fieldBoolean0: true,
  fieldBoolean1: true,
  fieldBoolean2: true,
  fieldBoolean3: true,
  fieldBoolean4: true,
  fieldBoolean5: true,
  fieldBoolean6: true,
  fieldBoolean7: true,
  fieldBoolean8: true,
  fieldBoolean9: true,
  fieldBoolean10: true,
  fieldBoolean11: true,
  fieldBoolean12: true,
  fieldBoolean13: true,
  fieldBoolean14: true,
  fieldBoolean15: true,
  fieldBoolean16: true,
  fieldBoolean17: true,
  fieldBoolean18: true,
  fieldBoolean19: true,
  fieldBoolean20: true,
  fieldDate: new Date(),
  fieldDate0: new Date(),
  fieldDate1: new Date(),
  fieldDate2: new Date(),
  fieldDate3: new Date(),
  fieldDate4: new Date(),
  fieldDate5: new Date(),
  fieldDate6: new Date(),
  fieldDate7: new Date(),
  fieldDate8: new Date(),
  fieldDate9: new Date(),
  fieldDate10: new Date(),
  fieldDate11: new Date(),
  fieldDate12: new Date(),
  fieldDate13: new Date(),
  fieldDate14: new Date(),
  fieldDate15: new Date(),
  fieldDate16: new Date(),
  fieldDate17: new Date(),
  fieldDate18: new Date(),
  fieldDate19: new Date(),
  fieldDate20: new Date(),
  fieldObject: mockChild,
  fieldObject0: mockChild,
  fieldObject1: mockChild,
  fieldObject2: mockChild,
  fieldObject3: mockChild,
  fieldObject4: mockChild,
  fieldObject5: mockChild,
  fieldObject6: mockChild,
  fieldObject7: mockChild,
  fieldObject8: mockChild,
  fieldObject9: mockChild,
  fieldObject10: mockChild,
  fieldObject11: mockChild,
  fieldObject12: mockChild,
  fieldObject13: mockChild,
  fieldObject14: mockChild,
  fieldObject15: mockChild,
  fieldObject16: mockChild,
  fieldObject17: mockChild,
  fieldObject18: mockChild,
  fieldObject19: mockChild,
  fieldObject20: mockChild,
  fieldArray: [mockChild, mockChild2],
  fieldArray0: [mockChild, mockChild2],
  fieldArray1: [mockChild, mockChild2],
  fieldArray2: [mockChild, mockChild2],
  fieldArray3: [mockChild, mockChild2],
  fieldArray4: [mockChild, mockChild2],
  fieldArray5: [mockChild, mockChild2],
  fieldArray6: [mockChild, mockChild2],
  fieldArray7: [mockChild, mockChild2],
  fieldArray8: [mockChild, mockChild2],
  fieldArray9: [mockChild, mockChild2],
  fieldArray10: [mockChild, mockChild2],
  fieldArray11: [mockChild, mockChild2],
  fieldArray12: [mockChild, mockChild2],
  fieldArray13: [mockChild, mockChild2],
  fieldArray14: [mockChild, mockChild2],
  fieldArray15: [mockChild, mockChild2],
  fieldArray16: [mockChild, mockChild2],
  fieldArray17: [mockChild, mockChild2],
  fieldArray18: [mockChild, mockChild2],
  fieldArray19: [mockChild, mockChild2],
  fieldArray20: [mockChild, mockChild2],
};

export let mock2: MockDTO = {
  id: '2',
  fieldString: 'Lorem ipsum dolor sit amet 2',
  fieldString0: 'Lorem ipsum dolor sit amet 2',
  fieldString1: 'Lorem ipsum dolor sit amet 2',
  fieldString2: 'Lorem ipsum dolor sit amet 2',
  fieldString3: 'Lorem ipsum dolor sit amet 2',
  fieldString4: 'Lorem ipsum dolor sit amet 2',
  fieldString5: 'Lorem ipsum dolor sit amet 2',
  fieldString6: 'Lorem ipsum dolor sit amet 2',
  fieldString7: 'Lorem ipsum dolor sit amet 2',
  fieldString8: 'Lorem ipsum dolor sit amet 2',
  fieldString9: 'Lorem ipsum dolor sit amet 2',
  fieldString10: 'Lorem ipsum dolor sit amet 2',
  fieldString11: 'Lorem ipsum dolor sit amet 2',
  fieldString12: 'Lorem ipsum dolor sit amet 2',
  fieldString13: 'Lorem ipsum dolor sit amet 2',
  fieldString14: 'Lorem ipsum dolor sit amet 2',
  fieldString15: 'Lorem ipsum dolor sit amet 2',
  fieldString16: 'Lorem ipsum dolor sit amet 2',
  fieldString17: 'Lorem ipsum dolor sit amet 2',
  fieldString18: 'Lorem ipsum dolor sit amet 2',
  fieldString19: 'Lorem ipsum dolor sit amet 2',
  fieldString20: 'Lorem ipsum dolor sit amet 2',
  fieldNumber: 5678,
  fieldNumber0: 5678,
  fieldNumber1: 5678,
  fieldNumber2: 5678,
  fieldNumber3: 5678,
  fieldNumber4: 5678,
  fieldNumber5: 5678,
  fieldNumber6: 5678,
  fieldNumber7: 5678,
  fieldNumber8: 5678,
  fieldNumber9: 5678,
  fieldNumber10: 5678,
  fieldNumber11: 5678,
  fieldNumber12: 5678,
  fieldNumber13: 5678,
  fieldNumber14: 5678,
  fieldNumber15: 5678,
  fieldNumber16: 5678,
  fieldNumber17: 5678,
  fieldNumber18: 5678,
  fieldNumber19: 5678,
  fieldNumber20: 5678,
  fieldBoolean: false,
  fieldBoolean0: false,
  fieldBoolean1: false,
  fieldBoolean2: false,
  fieldBoolean3: false,
  fieldBoolean4: false,
  fieldBoolean5: false,
  fieldBoolean6: false,
  fieldBoolean7: false,
  fieldBoolean8: false,
  fieldBoolean9: false,
  fieldBoolean10: false,
  fieldBoolean11: false,
  fieldBoolean12: false,
  fieldBoolean13: false,
  fieldBoolean14: false,
  fieldBoolean15: false,
  fieldBoolean16: false,
  fieldBoolean17: false,
  fieldBoolean18: false,
  fieldBoolean19: false,
  fieldBoolean20: false,
  fieldDate: new Date(),
  fieldDate0: new Date(),
  fieldDate1: new Date(),
  fieldDate2: new Date(),
  fieldDate3: new Date(),
  fieldDate4: new Date(),
  fieldDate5: new Date(),
  fieldDate6: new Date(),
  fieldDate7: new Date(),
  fieldDate8: new Date(),
  fieldDate9: new Date(),
  fieldDate10: new Date(),
  fieldDate11: new Date(),
  fieldDate12: new Date(),
  fieldDate13: new Date(),
  fieldDate14: new Date(),
  fieldDate15: new Date(),
  fieldDate16: new Date(),
  fieldDate17: new Date(),
  fieldDate18: new Date(),
  fieldDate19: new Date(),
  fieldDate20: new Date(),
  fieldObject: mockChild,
  fieldObject0: mockChild,
  fieldObject1: mockChild,
  fieldObject2: mockChild,
  fieldObject3: mockChild,
  fieldObject4: mockChild,
  fieldObject5: mockChild,
  fieldObject6: mockChild,
  fieldObject7: mockChild,
  fieldObject8: mockChild,
  fieldObject9: mockChild,
  fieldObject10: mockChild,
  fieldObject11: mockChild,
  fieldObject12: mockChild,
  fieldObject13: mockChild,
  fieldObject14: mockChild,
  fieldObject15: mockChild,
  fieldObject16: mockChild,
  fieldObject17: mockChild,
  fieldObject18: mockChild,
  fieldObject19: mockChild,
  fieldObject20: mockChild,
  fieldArray: [mockChild, mockChild2],
  fieldArray0: [mockChild, mockChild2],
  fieldArray1: [mockChild, mockChild2],
  fieldArray2: [mockChild, mockChild2],
  fieldArray3: [mockChild, mockChild2],
  fieldArray4: [mockChild, mockChild2],
  fieldArray5: [mockChild, mockChild2],
  fieldArray6: [mockChild, mockChild2],
  fieldArray7: [mockChild, mockChild2],
  fieldArray8: [mockChild, mockChild2],
  fieldArray9: [mockChild, mockChild2],
  fieldArray10: [mockChild, mockChild2],
  fieldArray11: [mockChild, mockChild2],
  fieldArray12: [mockChild, mockChild2],
  fieldArray13: [mockChild, mockChild2],
  fieldArray14: [mockChild, mockChild2],
  fieldArray15: [mockChild, mockChild2],
  fieldArray16: [mockChild, mockChild2],
  fieldArray17: [mockChild, mockChild2],
  fieldArray18: [mockChild, mockChild2],
  fieldArray19: [mockChild, mockChild2],
  fieldArray20: [mockChild, mockChild2],
};

export let mocks = [
  mock,
  mock2,
];

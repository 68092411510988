import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Model } from '@api-main';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFeatureComponent } from '@base/base-feature';
import { SignalrService } from '../../services/signalr.service';
import { dynamicEnvironment } from '@env';

@Component({
  selector: 'lib-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent extends BaseFeatureComponent implements OnInit {

  @Input() isAdminWeb = false;
  @Input() notifications: Model.NotificationDTO[];
  @Input() loadNotificationsSub: Subscription;
  @Input() showLoadMoreNotifications = true;

  @Output() markAllAsRead = new EventEmitter();
  @Output() loadNotifications = new EventEmitter<boolean>(); // true = load more

  Action = Model.NotificationWebAction;

  isOpenNotification = false;
  isOpeningNotification = false;

  notificationCount: number;

  constructor(
    private signalrService: SignalrService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.signalrService.notificationCount$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(data => {
      this.notificationCount = data;
      this.cdr.markForCheck();
    });
  }

  openNotification(): void {
    if (!this.isOpenNotification) {
      this.isOpeningNotification = true;
      this.isOpenNotification = true;
      this.cdr.markForCheck();
      this.loadNotifications.emit();
      this.markAllAsRead.emit();
    }
  }

  closeNotification(): void {
    if (!this.isOpeningNotification) {
      this.isOpenNotification = false;
    }
    this.isOpeningNotification = false;
    this.cdr.markForCheck();
  }

  goTo(notification: Model.NotificationDTO): void {
    // tslint:disable-next-line: one-variable-per-declaration
    let courseId, discussionId, lectureId, examId, videoId;

    switch (notification.action) {
      // Params separate with comma
      case this.Action.OpenCourse:
        [courseId] = notification.params.split(',');
        if (this.isAdminWeb) {
          window.open(`${dynamicEnvironment.config.mainSiteUrl}/courses/${courseId}`, '_blank');
        } else {
          this.router.navigate(['/courses', courseId]);
          this.closeNotification();
        }
        break;

      case this.Action.OpenDiscussion:
        [courseId, discussionId] = notification.params.split(',');
        if (this.isAdminWeb) {
          window.open(`${dynamicEnvironment.config.mainSiteUrl}/courses/${courseId};discussionId=${discussionId}#qAndA`, '_blank');
        } else {
          this.router.navigate(['/courses', courseId, { discussionId }], { fragment: 'qAndA' });
          this.closeNotification();
        }
        break;

      case this.Action.OpenExam:
        [courseId, lectureId, examId] = notification.params.split(',');
        this.router.navigate([`/courses/${courseId}/exams`, examId]);
        this.closeNotification();
        break;

      case this.Action.OpenVideo:
        [courseId, lectureId, videoId] = notification.params.split(',');
        this.router.navigate([`/courses/${courseId}/lecture`, lectureId, 'Video', videoId, { role: 'Learner' }]);
        this.closeNotification();
        break;
    }
  }

  loadMoreNotifications(): void {
    this.loadNotifications.emit(true);
  }
}

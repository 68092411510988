import { OnInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseFeatureComponent } from './base-feature';

@Directive()
export class BaseFeatureTabComponent extends BaseFeatureComponent implements OnInit {

  activeTabId: string;
  defaultTabId: string;

  constructor(
    protected route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.activeTabId = fragment;
      } else {
        this.activeTabId = this.defaultTabId;
      }
    });
    super.ngOnInit();
  }
}


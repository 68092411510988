/**
 * Oliv Main API
 *
 * API version: v1.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LearningProgressActivity = 'Test' | 'Learning';

export const LearningProgressActivity = {
  Test: 'Test' as LearningProgressActivity,
  Learning: 'Learning' as LearningProgressActivity,
};

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class RedirectGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate() {
    this.router.navigate(['/']);
    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }
}

import { Component, OnInit, ViewChild, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, OnChanges {
  @ViewChild(NgxGlideComponent, { static: true }) ngxGlide: NgxGlideComponent;
  @ViewChild('children') children: HTMLElement;

  @Input() items: any[];

  @Input() perView: number;
  @Input() tablet: number;
  @Input() desktop: number;
  @Input() widescreen: number;
  @Input() fullhd: number;
  mobile: number;

  breakpoints: any;
  dotActive = 0;
  isInit = false;

  ngOnInit(): void {
    this.isInit = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.perView || changes.tablet || changes.desktop || changes.widescreen || changes.fullhd) {
      if (!this.perView) {
        throw new Error('[perView] is required');
      }

      // mobile first strategy, set default from smaller screen
      this.mobile = this.perView;
      this.tablet = this.tablet || this.mobile;
      this.desktop = this.desktop || this.tablet;
      this.widescreen = this.widescreen || this.desktop;
      this.fullhd = this.fullhd || this.widescreen;
      this.setBreakpoints();

      // workaround for glide does not update settings
      if (this.isInit) {
        window.setTimeout(() => {
          this.ngxGlide.ngOnDestroy();
          this.ngxGlide.ngAfterViewInit();
        });
      }
    }
  }

  onRan(event: Event): void {
    const glideIndex = this.ngxGlide.getIndex();
    this.dotActive = glideIndex;
  }

  go(params: string | number): void {
    const _params = typeof params === 'string' ? `${params}` : `=${params}`;
    this.ngxGlide.go(_params);
  }

  private setBreakpoints(): void {
    this.breakpoints = {
      767: { perView: this.mobile }, // 0-767
      1263: { perView: this.tablet }, // 768-1263
      1579: { perView: this.desktop }, // 1264-1579
      1899: { perView: this.widescreen }, // 1580-1899
    };
    this.perView = this.fullhd; // 1900+
  }

}

import { HubConnection, IStreamResult } from "@microsoft/signalr"

export class NotificationHub {
    constructor(private connection: HubConnection) {
    }

    createChannel(userId: string): Promise<void> {
        return this.connection.invoke('CreateChannel', userId);
    }

    unReadCount(toUserId: string, count: number): Promise<void> {
        return this.connection.invoke('UnReadCount', toUserId, count);
    }

    echo(message: string): Promise<void> {
        return this.connection.invoke('Echo', message);
    }

    notifyBackgroundTask(toUserId: string, data: BackgroundTaskDetailDTO): Promise<void> {
        return this.connection.invoke('NotifyBackgroundTask', toUserId, data);
    }

    notifyBackgroundTaskNotificationCount(toUserId: string, data: BackgroundTaskNotificationDTO): Promise<void> {
        return this.connection.invoke('NotifyBackgroundTaskNotificationCount', toUserId, data);
    }

    registerCallbacks(implementation: INotificationHubCallbacks) {
        this.connection.on('OnUnReadCountReceived', (unread) => implementation.onUnReadCountReceived(unread));
        this.connection.on('OnBackgroundTasKReceived', (data) => implementation.onBackgroundTasKReceived(data));
        this.connection.on('OnBackgroundTaskNotificationCount', (data) => implementation.onBackgroundTaskNotificationCount(data));
    }

    unregisterCallbacks(implementation: INotificationHubCallbacks) {
        this.connection.off('OnUnReadCountReceived', (unread) => implementation.onUnReadCountReceived(unread));
        this.connection.off('OnBackgroundTasKReceived', (data) => implementation.onBackgroundTasKReceived(data));
        this.connection.off('OnBackgroundTaskNotificationCount', (data) => implementation.onBackgroundTaskNotificationCount(data));
    }
}

export interface INotificationHubCallbacks {
    onUnReadCountReceived(unread: number): void;
    onBackgroundTasKReceived(data: BackgroundTaskDetailDTO): void;
    onBackgroundTaskNotificationCount(data: BackgroundTaskNotificationDTO): void;
}

export interface BackgroundTaskDetailDTO {
    jobId: string;
    message: string;
    status: BackgroundJobStatus;
    jopType: BackgroundJobType;
}

export enum BackgroundJobStatus {
    Waiting = "Waiting",
    Running = "Running",
    Completed = "Completed",
    Failed = "Failed",
}

export enum BackgroundJobType {
    ImportExamExcel = "ImportExamExcel",
    ImportLecture = "ImportLecture",
    ImportChapter = "ImportChapter",
    ImportCourse = "ImportCourse",
}

export interface BackgroundTaskNotificationDTO {
    jobQueuesCount: number | undefined;
    jobFailedNotDismiss: number | undefined;
}